import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import noImage from '../../../webroot/images/noimage.svg';
import GallaryCarousel from './GallaryCarousal';
import { toast } from 'react-toastify';
import Loader from '../../elements/Loader';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { gql, useApolloClient } from '@apollo/client';
import ReSubmissionDocument from './ReSubmissionDocument';
import { Dropdown } from 'react-bootstrap';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../../config/constants';
import Modal from '../../elements/Modal';

const ListGridView = ({
    docGallery,
    docCategoryList,
    documentList,
    categoryList,
    filterCategoryChildList,
    subCategoryList,
    setSubCategoryList,
    setDocGallery,
    seletedTabDocs,
    authUser,
    leadDetail,
    groupDocs,
    handleChangeImage,
    leadStatusHistory,
    getLoanDetail,
    dispatch,
    handleChangeTagOption,
    setDefaultImageCategory,
    defaultCategory,
    defaultSubCategory,
    isSubmitDisabled
}) => {
    // const dispatch = useDispatch();
    const client = useApolloClient();
    const [isDocLoading, setIsDocLoading] = useState(false);
    const [maxUploadCount, setMaxUploadCount] = useState(0);
    const [downloadList, setDownloadFiles] = useState([]);
    const [gridView, setGridView] = useState({
        gridView: false,
        ListView: true,
    });
    const [currentFile, setCurrentFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    // const [documentHistoryList, setDocumentHistoryList] = useState([]);
    // const [showDocumentHistoryModal, setShowDocumentHistoryModal] = useState(false);
    const [uploadMore, setUploadMore] = useState(false);
    const [showResubmisisonModal, setShowResubmissionModal] = useState(false);
    const [currentDocData, setCurrentDocData] = useState({ doc_id: 0, parent_id: 0 });
    const [docActionType, setDocActionType] = useState('');
    const [changeTagPopup, setChangeTagPopup] = useState(false);
    const [changeTagCategory, setChangeTagCategory] = useState(null);
    const [changeTagSubCategory, setChangeTagSubCategory] = useState(null);
    const [showDropDown, setShowDropDown] = useState(false);

    let accessCondition = !IS_USER_ACCESS_PAGE('doc_upload', 'doc_upload').is_edit_access;
    if (!accessCondition) {
        accessCondition = CHECK_ASSIGN_USER(leadDetail?.lead_assigned_to);
    }

    let isLeadAssigned = IS_USER_ACCESS_PAGE('doc_upload', 'doc_upload').is_super_admin
        ? false
        : !(leadDetail?.lead_assigned_to && leadDetail?.lead_assigned_to === authUser?.id);

    // useEffect(() => {
    //     if (leadDetail?.id) dispatch(getLoanDetail(leadDetail.id, client));
    // }, [gridView])

    useEffect(() => {
        setDownloadFiles([]);
        setDocGallery({ ...docGallery, filterFiles: [] });
    }, []);

    useEffect(() => {
        setDefaultImageCategory(defaultCategory, defaultSubCategory);
    }, [leadDetail?.lead_docs]);

    useEffect(() => {
        if (docGallery && docGallery.hasOwnProperty('filterFiles')) {
            if (docGallery['filterFiles']?.length) {
                setCurrentFile(...docGallery['filterFiles']);
            } else {
                setCurrentFile(null);
            }
        }
        setCurrentDocData((currentData) => ({
            ...currentData,
            doc_id: docGallery.selectedSubCategory.value,
            parent_id: docGallery.selectedCategory.value,
        }));
        if (!maxUploadCount) {
            let maxUploadCount =
                docCategoryList &&
                docCategoryList
                    .filter(
                        (data) =>
                            data.id === docGallery.selectedSubCategory.value ||
                            data.id === docGallery.selectedCategory.value,
                    )
                    .map((e) => e.max_upload)[0];
            setMaxUploadCount(maxUploadCount);
        }
    }, [docGallery]);

    const toggleswitchgrid = (type) => {
        if (type === 'list') setGridView({ ...gridView, gridView: true, ListView: false });
        else setGridView({ ...gridView, ListView: true, gridView: false });
    };

    const tagDoc = () => {
        if (['1', '3'].includes(currentFile.doc_status)) {
            let max_count = 0, doc_id = 0, parent_doc_id = 0;
            let doc_count = leadDetail?.lead_docs?.filter(doc => doc.doc_id === changeTagCategory && [changeTagSubCategory, 0].includes(doc.parent_doc_id))?.length;
            if (changeTagSubCategory) {
                max_count = docCategoryList?.filter(doc => doc.id === changeTagCategory)?.[0]?.child?.filter(cd => cd.id === changeTagSubCategory)?.[0]?.max_upload || 0;
                doc_id = changeTagSubCategory; parent_doc_id = changeTagCategory;
            } else {
                max_count = docCategoryList?.filter(doc => doc.id === changeTagCategory)?.[0]?.max_upload;
                doc_id = changeTagCategory; parent_doc_id = 0;
            }
            if (doc_count < max_count) {
                handleChangeTagOption(currentFile?.id, doc_id, parent_doc_id);
                handleCloseChangeTag();
            } else {
                toast.error("Can't Upload more Docs!");
            }
        } else {
            toast.error("No Document Selected!");
        }
    };

    const handleCloseChangeTag = () => {
        setChangeTagPopup(false);
        document.body.classList.remove('overflow-hidden');
        setChangeTagSubCategory(null);
        setChangeTagCategory(null);
    };

    const handleChange = (e, key, field_obj) => {
        e.stopPropagation();
        let docList = documentList,
            filtredFiles = [];
        setUploadMore(false);
        if (uploadMore) document.getElementById('uploadFormMore')?.reset();
        if (key === 'selectedCategory') {
            let subCategory =
                docCategoryList && docCategoryList.filter((f) => f.id === field_obj.id)?.map((v) => v.child)[0];
            // subCategory = subCategory && subCategory.length && subCategory.map((e) => ({ label: e.name, value: e.id }));
            let maxUploadCount =
                docCategoryList &&
                docCategoryList.filter((data) => data.id === field_obj.id).map((e) => e.max_upload)[0];
            setMaxUploadCount(maxUploadCount);
            /* If child category is not available then filter doc on parent category */
            // if (!subCategory?.length) {
            filtredFiles = docList.reduce((res, data) => {
                if (data.doc_id === field_obj.id) res.push(data);
                return res;
            }, []);
            // }
            setSubCategoryList(subCategory);
            setDocGallery({
                ...docGallery,
                selectedCategory: field_obj,
                selectedSubCategory: {},
                filterFiles: filtredFiles,
            });
        } else {
            filtredFiles = docList.reduce((res, data) => {
                if (data.doc_id === field_obj.id) res.push(data);
                return res;
            }, []);
            let selectedCategoryObj = docCategoryList?.filter((doc) => doc?.id === field_obj?.parent_id)?.[0];
            let selectedCategory = { label: selectedCategoryObj?.name, value: selectedCategoryObj?.id };
            setDocGallery({
                ...docGallery,
                selectedCategory: selectedCategory,
                selectedSubCategory: field_obj,
                filterFiles: filtredFiles,
            });
        }
        setShowDropDown(!showDropDown);
    };

    // markedChecked
    const markedChecked = (event) => {
        let inpvalue = event.target.value;
        let downloadListNew = [...downloadList];
        let itemIndex = downloadListNew.indexOf(inpvalue);
        if (event.target.checked && itemIndex === -1) {
            if (inpvalue === 'all') {
                downloadListNew = leadDetail?.lead_docs.filter(file => file.doc_id && ['1'].includes(file.doc_status?.toString()))
                    .map(file => file.id.toString());
            }
            downloadListNew.push(inpvalue);

        } else {
            if (itemIndex > -1) {
                if (inpvalue === 'all') {
                    downloadListNew = [];
                } else {
                    downloadListNew.splice(itemIndex, 1);
                    let allIndex = downloadListNew.indexOf('all');
                    if (allIndex > -1) downloadListNew.splice(allIndex, 1);
                }

            }
        }

        if (downloadListNew.length === groupDocs.length && !downloadListNew.includes('all')) downloadListNew.push('all');
        setDownloadFiles(downloadListNew);
    };

    const markAllAsApproved = async () => {
        let leadDocs = [];
        if (leadDetail?.lead_docs.length) {
            leadDocs = leadDetail?.lead_docs
                ?.filter((v) => downloadList.includes(v.id.toString()) && !['3', '2', '0'].includes(v.doc_status))
                .map((file) => ({
                    id: file.id,
                    doc_id: file.doc_id,
                    parent_doc_id: file.parent_doc_id,
                }));

            /** If selected docs already Verified */
            if (leadDocs.length < 1) {
                return toast.error('Already Verified');
            }
            let message = 'Doc Verified Successfully';
            let data = {
                lead_id: leadDetail.id,
                user_id: authUser.id,
                doc_status: '2',
                doc_source: 'web',
                update_action_type: 'mark_as_approved',
                remark: '',
                lead_docs: leadDocs,
            };
            manageDocAction(data, message);
        }
    };

    const markAsApproved = () => {
        let data = {
            lead_id: leadDetail.id,
            user_id: authUser.id,
            doc_status: '2',
            doc_source: 'web',
            remark: '',
            update_action_type: 'mark_as_approved',
            lead_docs: [
                {
                    id: currentFile.id,
                    doc_id: currentFile.doc_id,
                    parent_doc_id: currentFile.parent_doc_id,
                },
            ],
        };
        let message = 'Doc Verified Successfully';

        manageDocAction(data, message);
    };

    /** will handle for both resubmission request as well as approval */
    const manageDocAction = async (leadData, message = null) => {
        if (leadData && leadData.lead_docs && leadData.lead_docs.length) {
            setIsLoading(true);
            let variables = { approvedDocInput: leadData };
            const mutation = gql`
                mutation approved_document($approvedDocInput: ApprovedDocInput!) {
                    approved_document(approvedDocInput: $approvedDocInput) {
                        id
                        lead_id
                        doc_path
                        doc_id
                        parent_doc_id
                        doc_status
                    }
                }
            `;
            executeGraphQLMutation(mutation, variables, client)
                .then(async (result) => {
                    if (result && result.data) {
                        if (message) toast.success(message);
                        if (result.data) {
                            await dispatch(getLoanDetail(leadDetail.id, client));
                            let doc_status = leadData?.update_action_type === "mark_as_approved" ? '2' : leadData?.action_type === "resubmission" ? '0' : null;
                            if (doc_status) {
                                let filtredFiles = docGallery?.filterFiles?.map((data) => {
                                    if (data.doc_id === currentFile.doc_id || data.doc_id === currentFile.parent_doc_id) return { ...data, doc_status: doc_status };
                                    return data;
                                });
                                setDocGallery({ ...docGallery, filterFiles: filtredFiles });
                            }
                        }
                        setDownloadFiles([]);
                        setIsLoading(false);
                    } else {
                        setDownloadFiles([]);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    let message =
                        error.toString().replace('ApolloError:', '') ||
                        'Docs already Verified/requested. Please refresh the page and check';
                    toast.error(message);
                    setIsLoading(false);
                });
        } else {
            toast.error('No image found.');
        }
    };

    const deleteDocument = (docId, fileId, index) => {
        let UploadedFilesArr = docGallery.filterFiles;
        // let required_doc_ids = LeadHelper.getRequiredDocIds({ id: docId }, filter_doc_list, true);
        setIsLoading(true);
        let variables = {
            updateDocInput: {
                id: fileId,
                lead_id: leadDetail.id,
                status: '0',
                parent_doc_id: 0,
                doc_id: 0,
                doc_source: 'web',
                user_id: authUser && (authUser.id || 0),
                // tab_name: props.activeTab,
                // required_doc_ids: required_doc_ids || [],
            },
        };

        const mutation = gql`
            mutation update_doc($updateDocInput: UpdateDocInput!) {
                update_doc(updateDocInput: $updateDocInput) {
                    id
                    lead_id
                    doc_id
                }
            }
        `;

        executeGraphQLMutation(mutation, variables, client)
            .then((result) => {
                if (result && result.data) {
                    toast.success('Success');
                    if (result.data) {
                        dispatch(getLoanDetail(leadDetail.id, client));
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                toast.error(error.toString().replace('ApolloError:', ''));
                setIsLoading(false);
            });
        if (index !== -1) {
            UploadedFilesArr.splice(index, 1);
        }
        setDocGallery({ ...docGallery, filterFiles: [UploadedFilesArr] });
    };

    const showResubmission = (action_type) => {
        setShowResubmissionModal(true);
        setDocActionType(action_type);
        document.body.classList.add('overflow-hidden');
    };

    const hideResubmissionModal = () => {
        setShowResubmissionModal(false);
        document.body.classList.remove('overflow-hidden');
    };

    let doc_list_arr = docCategoryList.map((doc, index) => {
        let docData = seletedTabDocs?.filter((sel_doc) => sel_doc.doc_id === doc.id) || [];
        let childDocIds = doc?.child?.map((ch) => ch.id);
        let uploadedChildDoc = seletedTabDocs?.filter((ch_doc) => childDocIds.includes(ch_doc.doc_id));
        let docUploadStatus =
            (doc.child?.length === 0 && docData?.length >= doc.min_upload) ||
                (doc.child?.length > 0 && uploadedChildDoc?.length > 0)
                ? 'done'
                : '';

        return (

            <li key={doc.id}>
                {docUploadStatus === 'done' ? (
                    <span className="stepprogress">
                        <i className="ic-check"></i>
                    </span>
                ) : null}

                {
                    <div key={`${doc.id}${index}`} onClick={(e) => handleChange(e, 'selectedCategory', doc)}>
                        {(doc.is_required === 1 && doc.service_type_ids.includes(leadDetail?.service_type_id)) || doc.id === 13 ? "*" : ""}{`${doc.name} `}
                        <span className="doc_count">
                            {docData?.length} /{doc.max_upload}
                        </span>
                        <div className='doc-sub-list'>
                            {doc.child?.length
                                ? doc.child.map((child_doc, index) => {
                                    return (
                                        <div className=''
                                            key={`child_${child_doc.id}${index}`}
                                            onClick={(ele) =>
                                                handleChange(ele, 'selectedSubCategory', child_doc)
                                            }
                                        >
                                            <span className='sublist-cate-heading'> {`${child_doc.name}`}{' '}</span>
                                            <span className="doc_count">
                                                {
                                                    seletedTabDocs?.filter(
                                                        (ch_doc) => ch_doc.doc_id === child_doc.id,
                                                    )?.length
                                                }{' '}
                                                /{child_doc.max_upload}
                                            </span>
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>
                }

            </li>

        );
    });

    return (
        <div className="doument-view-list-gallery" key={gridView}>
            {isLoading ? <Loader /> : null}
            {gridView.ListView ? (
                <div className="file-submit-sec list-view">
                    <div className="file-submit-selet-list d-flex justify-content-between">
                        <div className="doc-count">
                            Documents{' '}
                            <span className="">
                                {' '}
                                {docGallery.filterFiles?.length}/{maxUploadCount}
                            </span>
                        </div>
                        <div className="tag-image-bx" key={`${docCategoryList?.length}`}>
                            <div className={'image-tagning-drop-down tag-category-sub-category tag-category-outer'}>
                                <Dropdown show={showDropDown} onToggle={() => setShowDropDown(!showDropDown)}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <div className="select-category">
                                            <span className="tag-main-category">
                                                {docGallery?.selectedCategory?.label ||
                                                    docGallery?.selectedCategory?.name ||
                                                    ''}
                                            </span>
                                            {docGallery?.selectedSubCategory?.label ||
                                                docGallery?.selectedSubCategory?.name ||
                                                ''}
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="taging-list-drop-down">
                                            <ul>
                                                {doc_list_arr?.length ? doc_list_arr : null}
                                            </ul>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {['1', '3'].includes(currentFile?.doc_status) ?
                                    <span className="remove-tag-txt" onClick={() => setChangeTagPopup(true)}>Change Tag</span>
                                    : null
                                }
                            </div>
                        </div>

                        <div className="grid-icn" onClick={toggleswitchgrid.bind(this, 'list')}>
                            <i className="ic-gridview">
                                <i className="path1"></i>
                                <i className="path2"></i>
                                <i className="path3"></i>
                                <i className="path4"></i>
                                <i className="path5"></i>
                                <i className="path6"></i>
                                <i className="path7"></i>
                                <i className="path8"></i>
                            </i>
                        </div>
                    </div>

                    {docGallery.filterFiles && docGallery.filterFiles?.length ? (
                        <div className="verfication-img">
                            <GallaryCarousel
                                isLoading={isDocLoading}
                                slides={docGallery.filterFiles}
                                document_list={docCategoryList}
                                upload_more={uploadMore}
                                setUploadMore={setUploadMore}
                                docGallery={docGallery}
                                handleChangeImage={handleChangeImage}
                                setCurrentFile={setCurrentFile}
                            />
                        </div>
                    ) : (
                        <div className="verfication-img-no-image">
                            <img alt="No Document attached" src={noImage} className="" />
                            <span className="no-img-txt">No Document Attached</span>
                        </div>
                    )}
                    <div className="image-opt doc-image-opt">
                        {/* Checking for non Pre-disbursal doc */}
                        {
                            <div className="btn-submission-Verified">
                                {currentFile && (
                                    <>
                                        {/* <button className="btn-line" onClick={showDocumentHistory}>
                                            View History
                                        </button> */}
                                        {
                                            currentFile && +currentFile.doc_status === 2 ? (
                                                <>
                                                    <button className="btn-line btn-approve">
                                                        <i className="ic-check"></i>Verified
                                                    </button>
                                                </>
                                            ) : currentFile && +currentFile.doc_status == 3 ? (
                                                <>
                                                    <button className="btn-line btn-approve">
                                                        <i className="ic-check"></i>Additional
                                                    </button>
                                                </>
                                            ) : currentFile && +currentFile.doc_status === 0 ? (
                                                <>
                                                    <button className="btn-fill">
                                                        {' '}
                                                        <i className="ic-check"></i>Re-submission Requested
                                                    </button>
                                                    <button
                                                        className="btn-line"
                                                        disabled={accessCondition || isLeadAssigned || isSubmitDisabled}
                                                        onClick={(e) => setUploadMore(true)}
                                                    >
                                                        Upload Photo
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    {[2].includes(leadDetail?.status_id) &&
                                                        !leadStatusHistory?.find((history) => history.status_id === 4) ? (
                                                        <button
                                                            disabled={accessCondition || isLeadAssigned || isSubmitDisabled}
                                                            className="btn-line"
                                                            onClick={() => showResubmission('resubmission')}
                                                        >
                                                            Request Re-submission
                                                        </button>
                                                    ) : null}

                                                    <button
                                                        className="btn-line"
                                                        disabled={accessCondition || isLeadAssigned || isSubmitDisabled}
                                                        onClick={markAsApproved}
                                                    >
                                                        Mark as Verified
                                                    </button>
                                                </>
                                            )
                                            // (
                                            //     <button className="btn-line" onClick={markAsApproved}>
                                            //         Mark as Verified
                                            //     </button>
                                            // )
                                        }
                                    </>
                                )}
                            </div>
                        }
                    </div>
                </div>
            ) : null}

            {/* <--------- GRID view -------> */}
            {gridView.gridView ? (
                <div className="file-submit-sec grid-view">
                    <div className="gird-view-select-opt">
                        <div className="grid-doc-count">
                            <h2>Documents</h2>
                            {/* <span className="">
                                {' '}
                                {docGallery.filterFiles?.length}/{maxUploadCount}
                            </span> */}
                        </div>
                        <div className='d-flex'>
                            {
                                !leadStatusHistory?.filter(history => history.status_id === 5)?.length ?
                                    <div class="custom-control custom-checkbox check-box-small select-check-bx m-md-r">
                                        <input id="Individual" type="radio" class="custom-control-input " name="Buyer Type" checked={downloadList.includes('all') ? true : false} onClick={markedChecked} value="all" />
                                        <label class="custom-control-label">Select All </label>
                                    </div> : null
                            }
                            <div className="list-icn" onClick={toggleswitchgrid.bind(this, 'grid')}>
                                <i className="ic-listview">
                                    <i className="path1"></i>
                                    <i className="path2"></i>
                                    <i className="path3"></i>
                                    <i className="path4"></i>
                                    <i className="path5"></i>
                                    <i className="path6"></i>
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="grid-item-list">
                        {groupDocs?.length ? (
                            groupDocs?.map(doc => (
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className="doc-type-name">
                                            {(doc.is_required === 1 && doc.service_type_ids.includes(leadDetail?.service_type_id)) || doc.id === 13 ? "*" : ""}
                                            {
                                                doc.name || ""
                                            }
                                            {
                                                " - " + leadDetail?.lead_docs?.filter(leadDoc => leadDoc.doc_id === doc.id)?.length + "/" + doc.max_upload
                                            }
                                        </div>
                                    </div>


                                    {doc['docs']?.length
                                        ? doc['docs']?.map((file, index) => (
                                            <>
                                                <div className="col-md-4" key={index}>
                                                    <div className="img-bx-select-opt image-grid-list overflow-h">
                                                        <div className="image-bx">
                                                            {['pdf', 'PDF'].includes(
                                                                file.doc_path?.split('?')[0]?.split('.')?.pop(),
                                                            ) ? (
                                                                <a
                                                                    href={file.doc_path}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <i className="ic-pdf-file icons-normal"></i>
                                                                </a>
                                                            ) : (
                                                                <img
                                                                    src={file.doc_path}
                                                                    alt={
                                                                        file.parent_doc_id
                                                                            ? docCategoryList
                                                                                ?.filter(
                                                                                    (v) =>
                                                                                        v.id ===
                                                                                        file.parent_doc_id,
                                                                                )
                                                                                .map((v) => v.name) +
                                                                            '|' +
                                                                            filterCategoryChildList
                                                                                ?.filter(
                                                                                    (v) => v.id == +file.doc_id,
                                                                                )
                                                                                .map((v) => v.name)
                                                                            : docCategoryList
                                                                                ?.filter(
                                                                                    (v) =>
                                                                                        v.id === +file.doc_id,
                                                                                )
                                                                                .map((v) => v.name)
                                                                    }
                                                                    className=""
                                                                />
                                                            )}
                                                            {file && file.doc_id && file.doc_status === '2' ? (
                                                                <span className="image-status">Verified</span>
                                                            ) : file && file.doc_id && file.doc_status === '3' ? (
                                                                <span className="image-status-additional">
                                                                    Additional
                                                                </span>
                                                            ) : file && file.doc_id && file.doc_status === '0' ? (
                                                                <span className="image-requested">Requested</span>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {['0', '2', '3'].includes(file.doc_status) ||
                                                                accessCondition || isSubmitDisabled ||
                                                                isLeadAssigned ? null : (
                                                                <div
                                                                    className="close-icn"
                                                                    onClick={() => {
                                                                        if (
                                                                            window.confirm(
                                                                                'Confirm, do you want to delete?',
                                                                            )
                                                                        )
                                                                            deleteDocument(
                                                                                file.doc_id,
                                                                                file.id,
                                                                                index,
                                                                            );
                                                                    }}
                                                                >
                                                                    <i className="ic-clearclose"></i>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="custom-control custom-checkbox check-box-small">
                                                            {
                                                                !leadStatusHistory?.filter(history => history.status_id === 6)?.length ?
                                                                    <>
                                                                        <input id={`Individual${file.id}`} type="checkbox" class="custom-control-input " disabled={['0', '3', '2'].includes(file?.doc_status?.toString())} name="selectFile" checked={downloadList.includes(file.id.toString()) ? true : false} onClick={markedChecked} value={file.id.toString()} />
                                                                        <label className="custom-control-label" For={`Individual${file.id}`}>
                                                                            {file.parent_doc_id
                                                                                ? docCategoryList
                                                                                    ?.filter(
                                                                                        (v) => v.id === file.parent_doc_id,
                                                                                    )
                                                                                    .map((v) => v.name) +
                                                                                ' | ' +
                                                                                filterCategoryChildList
                                                                                    ?.filter((v) => v.id === +file.doc_id)
                                                                                    .map((v) => v.name)
                                                                                : docCategoryList
                                                                                    ?.filter((v) => v.id === +file.doc_id)
                                                                                    .map((v) => v.name)}
                                                                        </label></> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {index === doc['docs']?.length - 1 ? (
                                                    <div className="col-md-4" key={index}>
                                                        <div className="img-bx-select-opt upload-tag-photo">
                                                            <div className="image-bx more-file-upload">
                                                                <i className="ic-add"></i>
                                                                <form
                                                                    id="uploadForm"
                                                                    method="post"
                                                                    encType="multipart/form-data"
                                                                >
                                                                    <input
                                                                        aria-label="upload form multiple"
                                                                        type="file"
                                                                        name="fileName[]"
                                                                        multiple
                                                                        id="fileupload"
                                                                        disabled={
                                                                            accessCondition || isLeadAssigned || isSubmitDisabled || ([18, 15].includes(doc.id) && !leadStatusHistory?.filter(history => history.status_id === 4)?.length)
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleChangeImage(
                                                                                e,
                                                                                file.doc_id,
                                                                                file.parent_doc_id,
                                                                            )
                                                                        }
                                                                    />
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div class="custom-control custom-checkbox text-center">
                                                            <label class="">Add Photo</label>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </>
                                        ))
                                        : <>
                                            {
                                                <div className="col-md-4" key={'add_more' + `${doc.id}`}>
                                                    <div className="img-bx-select-opt upload-tag-photo">
                                                        <div className="image-bx more-file-upload">
                                                            <i className="ic-add"></i>
                                                            <form
                                                                id="uploadForm"
                                                                method="post"
                                                                encType="multipart/form-data"
                                                            >
                                                                <input
                                                                    aria-label="upload form multiple"
                                                                    type="file"
                                                                    name="fileName[]"
                                                                    multiple
                                                                    id="fileupload"
                                                                    disabled={
                                                                        accessCondition || isSubmitDisabled || isLeadAssigned || ([18, 15].includes(doc.id) && !leadStatusHistory?.filter(history => history.status_id === 4)?.length)
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChangeImage(
                                                                            e,
                                                                            doc.id,
                                                                            doc.parent_id,
                                                                        )
                                                                    }
                                                                />
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div class="custom-control custom-checkbox text-center">
                                                        <label class="">Add Photo</label>
                                                    </div>
                                                </div>
                                            }</>}
                                </div>

                            ))
                        ) : (
                            <div className="col-md-12">
                                <div className="verfication-img-no-image">
                                    <img alt="No Document attached" src={noImage} className="" />
                                    <span className="no-img-txt">No Document Attached</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {downloadList && downloadList.length ? (
                        <div className="btn-line-img">
                            {
                                <button className="btn-line" onClick={markAllAsApproved} disabled={accessCondition || isLeadAssigned || isSubmitDisabled}>
                                    Mark as Verified
                                </button>
                            }
                        </div>
                    ) : null}
                </div>
            ) : null}
            <div className="view-timeline-popup image-timeline-popup doc-history-timeline">
                {/* <DocumentHistory
                    documentHistoryList={documentHistoryList}
                    documentHistoryModelShowFlag={showDocumentHistoryModal}
                    hideModalDocumentHistory={hideModalDocumentHistory}
                /> */}
            </div>
            <div className="view-timeline-popup image-timeline-popup doc-history-timeline">
                <Modal show={changeTagPopup} handleClose={handleCloseChangeTag}>
                    <div className='modal-header'>
                        <h2>Change Tag</h2>
                    </div>
                    <div className='modal-body h-300'>
                        <div className="tag-image-bx row" key={`${docCategoryList?.length}change-tag`}>
                            <div className='single-select col-md-12 p-lg-b'>
                                <div className="material">
                                    <Select
                                        options={leadStatusHistory?.filter(history => history.status_id === 4)?.length ? docCategoryList : docCategoryList?.filter(docs => ![18, 15].includes(docs.id))}
                                        placeholder={'Select Doc Category'}
                                        name={'selected_doc_category'}
                                        optionLabel="label"
                                        optionValue="value"
                                        value={docCategoryList.filter((data) => data.value === changeTagCategory)}
                                        onChange={(e) => {
                                            setChangeTagCategory(e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                docCategoryList.filter((data) => data.value === changeTagCategory)?.[0]?.child?.length ?
                                    <div className='single-select col-md-12 p-lg-b'>
                                        <div className="material">
                                            <Select
                                                options={docCategoryList.filter((data) => data.value === changeTagCategory)?.[0]?.child}
                                                placeholder={'Select Doc Sub Category'}
                                                name={'selected_doc_sub_category'}
                                                optionLabel="label"
                                                optionValue="value"
                                                value={docCategoryList.filter((data) => data.value === changeTagCategory)?.[0]?.child.filter((data) => data.value === changeTagSubCategory)}
                                                onChange={(e) => {
                                                    setChangeTagSubCategory(e.value);
                                                }}
                                            />
                                        </div>
                                    </div> : null
                            }
                            <div className='col-md-12'>
                                <button
                                    className="btn-line"
                                    disabled={accessCondition || isSubmitDisabled || isLeadAssigned || !changeTagCategory || (docCategoryList.filter((data) => data.id === changeTagCategory)?.[0]?.child?.length && !changeTagSubCategory)}
                                    onClick={tagDoc}
                                >
                                    Change Tag
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            <ReSubmissionDocument
                leadId={leadDetail?.id}
                documentId={currentFile && currentFile.doc_id}
                filterData={currentDocData}
                documentParentId={currentFile && currentFile.parent_doc_id}
                actionType={docActionType}
                showResubmissionModal={showResubmisisonModal}
                hideModalReSubmission={hideResubmissionModal}
                requestResubmission={manageDocAction}
                user_id={authUser?.id}
            />
        </div>
    );
};

export default ListGridView;
