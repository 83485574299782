import React, { useEffect, useMemo, useState } from 'react';
import Formsy, { addValidationRule } from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import { SEND_TO_AGENT as FORM_FIELDS } from '../formFields/sendToAgentFormFields.json';
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getLoanDetail } from '../../../store/action/allAction';
import {
    AGENT_ACTION,
    CHECK_ASSIGN_USER,
    DATE_FORMAT,
    IS_USER_ACCESS_PAGE,
    LEAD_STATUS,
    STATUS_CODES,
} from '../../../config/constants';
import { LTO_AGENT_SERVICE_CHARGE, SEND_TO_AGENT } from '../../../services/leads.gql';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import * as moment from 'moment';
import { encode } from '../../../config/crypto';
import DocumentGallery from '../DocumentGallery';
import { GET_AGENT_LIST } from '../../../services/masterdata.gql';

// const FORM_FIELDS = FormFields.SEND_TO_AGENT;
const SendToAgentForm = (props) => {
    addValidationRule('isNegativeValue', function (values, value) {
        if (value < 0) {
            return "Negative values are not allowed";
        }
        return true;
    });

    const [isReadOnly, setIsReadOnly] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform, setShowremarkform] = useState(false);
    const [sendToAgentInput, setSendToAgentInput] = useState({});
    const [sendToAgentUrl, setSendToAgentUrl] = useState('');
    const [statusTrackingUrl, setStatusTrackingUrl] = useState('');
    const [agentList, setAgentList] = useState([]);
    const { masterdata, lead, remarksHistory, lead_status_history } = useSelector(({ masterdata, lead }) => ({
        masterdata,
        lead,
        remarksHistory: lead.remarksHistory || [],
        leadFiles: lead && lead.leadDetail && (lead.leadDetail.leaddocs || []),
        lead_status_history: lead?.leadDetail?.lead_status_history || [],
    }));

    const client = useApolloClient();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { lead_id } = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE('loanDetail', 'send-to-agent')?.is_edit_access;

    if (!accessCondition) {
        accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.lead_assigned_to);
    }

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = UserInfo?.id || 0;
    let role_id = UserInfo?.role_id?.[0] || 0;

    let isLeadAssigned = IS_USER_ACCESS_PAGE('loanDetail', 'send-to-agent').is_super_admin
        ? false
        : !(lead?.leadDetail?.lead_assigned_to && lead?.leadDetail?.lead_assigned_to === user_id);
    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || [];

    useEffect(() => {
        const fetchData = async () => {
            await getSentToAgentData();
        };
        fetchData();
    }, [lead?.leadDetail]);

    const getSentToAgentData = async () => {
        await ltoAgentList(lead?.leadDetail?.lead_detail?.outbound_lt_office_id, lead.leadDetail?.service_type_id);
        if (lead?.leadDetail && (!sendToAgentInput['service_type_id'] || !sendToAgentInput['agent_id'])) {
            setSendToAgentInput((currentValue) => ({
                ...currentValue,
                service_type_id: lead.leadDetail?.service_type_id || 0,
                agent_id: lead.leadDetail?.agent_id || 0,
            }));
        }
        if (lead?.leadDetail?.lead_detail) {
            setSendToAgentInput((currentValue) => ({
                ...currentValue,
                ...(lead?.leadDetail?.lead_detail?.inbound_lt_office_id && { inbound_lt_office_id: `${lead?.leadDetail?.lead_detail?.inbound_lt_office_id}` } || {}),
                ...(lead?.leadDetail?.lead_detail?.outbound_lt_office_id && { outbound_lt_office_id: `${lead?.leadDetail?.lead_detail?.outbound_lt_office_id}` } || {}),
                ...(lead?.leadDetail?.lead_detail?.sent_to_agent_date && { sent_to_agent_date: `${lead?.leadDetail?.lead_detail?.sent_to_agent_date}` } || {}),
            }));
        }
        if (lead?.state_id === STATUS_CODES.SENT_TO_AGENT) {
            setIsReadOnly(true);
        }
    };

    let ALL_OPTIONS = useMemo(() => {
        let options = masterdata ? { ...masterdata.data } : {};
        // options['agent_list'] =
        //     masterdata?.agentList?.map((agent) => {
        //         return { id: agent?.id, label: agent?.agent_name, value: agent?.id };
        //     }) || [];
        return options;
    }, [masterdata]);

    useEffect(() => {
        if (
            sendToAgentInput?.outbound_lt_office_id &&
            sendToAgentInput?.agent_id &&
            sendToAgentInput?.service_type_id
        ) {
            ltoAgentServiceCharge();
        } else {
            setSendToAgentUrl(null);
            setStatusTrackingUrl(null);
            setSendToAgentInput((currentValue) => ({
                ...currentValue,
                agent_fee: null,
                standard_ttp: null,
            }));
        }
        if (sendToAgentInput?.agent_id) {
            const agent_id = encode(sendToAgentInput?.agent_id);
            setSendToAgentUrl(`${window.location.host}/title-transfer-request/${lead_id}/${agent_id}`);
            setStatusTrackingUrl(`${window.location.host}/status-tracking/${lead_id}`);
            ltoAgentServiceCharge();
        }
    }, [sendToAgentInput?.outbound_lt_office_id, sendToAgentInput?.agent_id, sendToAgentInput?.service_type_id]);

    const ltoAgentList = (outbound_lt_office_id, service_type_id) => {
        executeGraphQLQuery(GET_AGENT_LIST(+outbound_lt_office_id, +service_type_id), client)
            .then((resp) => {
                if (resp?.data?.agent_list) {
                    let agent_list = resp?.data?.agent_list?.map(agent => {
                        return { id: agent?.id, label: agent?.agent_name, value: agent?.id };
                    });
                    setAgentList(agent_list);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleCopy = (url) => {
        if (!url) return;
        navigator.clipboard
            .writeText(url)
            .then(() => {
                toast.success('Copied!');
            })
            .catch((err) => {
                toast.error('Failed to copy!');
            });
    };

    const ltoAgentServiceCharge = () => {
        const variables = {
            outbound_lt_office_id: +sendToAgentInput?.outbound_lt_office_id,
            agent_id: +sendToAgentInput?.agent_id,
            service_type_id: +sendToAgentInput?.service_type_id,
        };
        executeGraphQLQuery(LTO_AGENT_SERVICE_CHARGE, client, { lto_agent_service: variables })
            .then((resp) => {
                const charges = resp?.data?.lto_agent_service_charge || {};
                setSendToAgentInput((currentValue) => ({
                    ...currentValue,
                    proposed_agent_fee: charges?.agent_fee || 0,
                    agent_fee: Number(lead?.leadDetail?.lead_detail?.agent_fee) || charges?.agent_fee || 0,
                    proposed_standard_ttp: charges?.service_details?.standard_price || 0,
                    standard_ttp: Number(lead?.leadDetail?.lead_detail?.standard_ttp) || charges?.service_details?.standard_price || 0,
                }));
            })
            .catch((err) => {
                toast.error(err);
            });
    };

    const handleChange = async (data, field_name) => {
        const newValues = {};
        if (data.value && field_name != "sent_to_agent_date" && field_name) {
            newValues[field_name] = data.value;
        }

        if (data?.target?.id) {
            newValues[data?.target?.id] = data?.target?.value;
        }

        if (field_name === 'sent_to_agent_date' && data) {
            newValues[field_name] = data;
        }
        setSendToAgentInput((currentValue) => ({
            ...currentValue,
            ...newValues,
        }));
        if (field_name === "outbound_lt_office_id") {
            setSendToAgentInput((currentValue) => ({
                ...currentValue,
                agent_id: null
            }));
            await ltoAgentList(parseInt(newValues['outbound_lt_office_id']), sendToAgentInput?.service_type_id);
        }
    };

    const handleSubmit = async () => {
        let lead_id = lead?.leadDetail?.id || 0;
        let send_to_agent = {
            lead_id: lead_id,
            inbound_lt_office_id: +sendToAgentInput?.inbound_lt_office_id,
            outbound_lt_office_id: +sendToAgentInput?.outbound_lt_office_id,
            agent_id: sendToAgentInput?.agent_id,
            sent_to_agent_date: sendToAgentInput?.sent_to_agent_date,
            agent_fee: +sendToAgentInput?.agent_fee,
            default_agent_fee: +sendToAgentInput?.proposed_agent_fee,
            default_standard_ttp: +sendToAgentInput?.proposed_standard_ttp,
            standard_ttp: +sendToAgentInput?.standard_ttp,
            remarks: sendToAgentInput?.remarks || '',
        },
            variables = { user_id, role_id, send_to_agent };

        executeGraphQLMutation(SEND_TO_AGENT, variables, client)
            .then(async (resp) => {
                if (resp?.data?.send_to_agent) {
                    toast.success(resp?.data?.send_to_agent?.message);
                } else {
                    toast.error('Something went wrong');
                }
                dispatch(getLoanDetail(lead_id, client));
                await getContext.getLeadHistory(lead_id);
                // navigateToNext();
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const navigateToNext = () => {
        navigate(`/lead-detail/status-details/${lead_id}`);
    };

    let SendToAgentForm = useMemo(() => {
        return FORM_FIELDS.map((field) => {
            return field;
        });
    }, []);

    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
        return lead_status_history?.filter((v) => [LEAD_STATUS.SENT_TO_AGENT].includes(v.status_id))?.length &&
            (lead?.leadDetail?.lead_agent_status === AGENT_ACTION.accepted || !lead?.leadDetail?.lead_agent_status)
            ? true
            : false;
    }, [lead_status_history]);

    // Edit final ammount after send to agent button enable/disable
    const isFinalFieldsDisabled = useMemo(() => {
        return lead?.leadDetail?.lead_agent_status === AGENT_ACTION.accepted
    }, [lead]);

    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove('overflow-hidden');
    };

    const sectionInfo = { section: 'Sent to Agent', sub_section: 'Sent to Agent' };
    return (
        <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
            <div className="image-tab-panel">
                <DocumentGallery documentCategoryList={documentCategoryList} />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading">
                        <h2>Send To Agent</h2>
                    </div>
                    <Formsy
                        className="lead-form-filed"
                        autoComplete="off"
                        onValid={() => setAllowSubmit(true)}
                        onInvalid={() => setAllowSubmit(false)}
                        onValidSubmit={handleSubmit}
                        aria-label="lead form field basic"
                    >
                        <div className="row">
                            {SendToAgentForm.map((field) =>
                                ['text', 'pattern-format'].includes(field.type) ? (
                                    <fieldset class={`form-filed ${(field?.is_full_row && 'col-md-12') || 'col-md-6'}`}>
                                        <FormsyInputField
                                            id={field.id}
                                            name={field.name}
                                            type={field.type}
                                            value={sendToAgentInput && sendToAgentInput?.[field?.name] || ""}
                                            placeholder=" "
                                            label={field.label}
                                            onChange={handleChange}
                                            format={field.ApplyNumberformat ? field.format : ''}
                                            validations={field.validations}
                                            validationError={sendToAgentInput[field.name] ? field.validationError : ''}
                                            readOnly={isReadOnly || isSubmitDisabled}
                                            required={field?.required}
                                            showAsterisk={field.showAsterisk}
                                        />
                                    </fieldset>
                                ) : field.type === 'dropdown' ? (
                                    <fieldset
                                        class={`single-select ${(field?.is_full_row && 'col-md-12') || 'col-md-6'}`}
                                    >
                                        <FormsySelect
                                            name={field.name}
                                            id={field.id}
                                            inputProps={{
                                                options: field.name === 'agent_id' ? agentList : ALL_OPTIONS && ALL_OPTIONS?.[field?.optionsKey] || [],
                                                placeholder: field.label,
                                                className: 'react-select',
                                                classNamePrefix: 'react-select',
                                                value: field.name === 'agent_id' ? agentList?.filter(agent => agent.value === sendToAgentInput[field.name])
                                                    : ALL_OPTIONS &&
                                                    ALL_OPTIONS[field.optionsKey] &&
                                                    ALL_OPTIONS[field.optionsKey].filter(
                                                        ({ value }) => value === sendToAgentInput[field.name],
                                                    ) || "",
                                                isDisabled: isReadOnly || isSubmitDisabled,
                                            }}
                                            required={field?.required}
                                            showAsterisk={field?.showAsterisk}
                                            value={sendToAgentInput && sendToAgentInput?.[field?.name] || ""}
                                            onChange={(data) => handleChange(data, field.name)}
                                        />
                                    </fieldset>
                                ) : ['date-picker'].includes(field?.type) ? (
                                    <fieldset class={`form-filed ${(field?.is_full_row && 'col-md-12') || 'col-md-6'}`}>
                                        <div className="material">
                                            <div className="datepicker send-to-agent-date-picker">
                                                <FormsyDatePicker
                                                    onDateSelect={(data) => handleChange(data, field?.name)}
                                                    placeholder={field?.label}
                                                    name={field.name}
                                                    selectedDate={sendToAgentInput[field?.name] && new Date(sendToAgentInput[field?.name]) || ""}
                                                    value={sendToAgentInput[field?.name] && new Date(sendToAgentInput[field?.name]) || ""}
                                                    minDate={new Date().setMonth(new Date().getMonth() - 2)}
                                                    maxDate={new Date().setMonth(new Date().getMonth() + 2)}
                                                    dateFormat="dd MMM, yyyy h:mm aa"
                                                    showTimeSelect={true}
                                                    validations={field?.validations}
                                                    validationError={sendToAgentInput[field?.name] ? field?.validationError : ''}
                                                    required={field?.required}
                                                    hasError={''}
                                                    clearDate={false}
                                                    readOnly={isReadOnly || isSubmitDisabled}
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                ) : null,
                            )}
                        </div>

                        <div className="agent-charge-box">
                            <div className="agent_charges">
                                <span className="label">Standard Charges</span>
                                <label className="value">₱ {sendToAgentInput?.proposed_standard_ttp} </label>
                            </div>
                            <div className="agent_charges final_charges_input">
                                <span className="label">Final Charges</span>
                                <FormsyInputField
                                    id={"standard_ttp"}
                                    name={"standard_ttp"}
                                    type={"number-format"}
                                    value={sendToAgentInput?.standard_ttp || ""}
                                    onChange={handleChange}
                                    label={""}
                                    readOnly={!sendToAgentInput?.proposed_standard_ttp || isFinalFieldsDisabled}
                                    validations={'isNegativeValue'}
                                />
                            </div>
                            <div className="agent_charges">
                                <span className="label">Agent Charges</span>
                                <label className="value">₱ {sendToAgentInput?.proposed_agent_fee} </label>
                            </div>
                            <div className="agent_charges final_charges_input">
                                <span className="label">Final Charges</span>
                                <FormsyInputField
                                    id={"agent_fee"}
                                    name={"agent_fee"}
                                    type={"number-format"}
                                    value={sendToAgentInput?.agent_fee || ""}
                                    onChange={handleChange}
                                    label={""}
                                    readOnly={!sendToAgentInput?.proposed_agent_fee || isFinalFieldsDisabled}
                                    validations={'isNegativeValue'}
                                />
                            </div>
                        </div>

                        <div className="acceptance-link">
                            <div className="link-name-url">
                                <span className="link-name">Acceptance Link</span>
                                <span class="link-url"> {sendToAgentUrl} </span>
                            </div>
                            <div className="url-btn">
                                <span className="button" onClick={_ => handleCopy(sendToAgentUrl)} disabled={!sendToAgentInput}>
                                    Copy URL
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-link">
                            <div className="link-name-url">
                                <span className="link-name">End User Link</span>
                                <span class="link-url"> {statusTrackingUrl} </span>
                            </div>
                            <div className="url-btn">
                                <span className="button" onClick={_ => handleCopy(statusTrackingUrl)} disabled={!sendToAgentInput}>
                                    Copy URL
                                </span>
                            </div>
                        </div>

                        < div className="btn-save-remarks p-sm-t" >
                            {(isSubmitDisabled && isFinalFieldsDisabled) ||
                                !allowSubmit ||
                                getContext.markAsFreezed ||
                                accessCondition ||
                                isLeadAssigned ? (
                                <span className="span-disabled">Save & Next</span>
                            ) : (
                                <button aria-label="save naxt" type="submit" className="btn-primary">
                                    Save & Next
                                </button>
                            )}
                            <button
                                aria-label="remarks history"
                                type="button"
                                className="btn-line"
                                onClick={showModalViewTimeLine}
                            >
                                Remarks
                                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                            </button>
                        </div>
                    </Formsy>
                </div>
            </div >
            <div className="view-timeline-popup">
                <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                    <div className="modal-header">
                        <h2>Remark History</h2>
                    </div>
                    <LeadRemarkHistory
                        showremarkform={showremarkform}
                        markAsFreezed={getContext.markAsFreezed}
                        accessCondition={accessCondition || isLeadAssigned}
                        sectionInfo={sectionInfo}
                    />
                </Modal>
            </div>
        </div >
    );
};
export default SendToAgentForm;
