import DocIcon from "../leads/docs-upload/DocIcon";
import { useEffect, useState } from "react";
import { getFileExtensionFromURL } from "../../config/constants";

const DocsPreview = ({ agentId, agents }) => {

    const [activeSlide, setActiveSlide] = useState(0);
    const [slides, setSlides] = useState([]);

    useEffect(() => {
        const agentDocs = agents?.filter((agent) => agent?.id === agentId)?.[0]?.agent_docs;
        setSlides([...agentDocs]);
    }, [agentId]);

    const goToPrevSlide = () => {
        setActiveSlide((activeSlide - 1 + slides.length) % slides.length);
    };

    const goToNextSlide = () => {
        setActiveSlide((activeSlide + 1) % slides.length);
    };

    return <>
        <span><b>{slides?.[activeSlide]?.doc_name}</b></span>
        <br></br>
        <div className="carousel-slideimg pfd-img-height">
            {['pdf', 'PDF'].includes(getFileExtensionFromURL(slides?.[activeSlide]?.signed_doc_path)) ? (
                <a className="pdf-img" href={slides?.[activeSlide]?.signed_doc_path} target="_blank" rel="noopener noreferrer">
                    <i className="ic-pdf-file pdf-icons-normal-gallery"></i>
                </a>
            ) : (
                <img src={slides?.[activeSlide]?.signed_doc_path}></img>
            )}
        </div>
        {slides && slides.length > 1 ? (
            <div className="image-opt slider-arrow-view-doc">
                <DocIcon goToPrevSlide={goToPrevSlide} goToNextSlide={goToNextSlide} />
            </div>
        ) : (
            ''
        )}
    </>;
};

export default DocsPreview;