import React, { useEffect } from 'react';
import GeneralService from '../services/generalService';
import { toast } from 'react-toastify';
import { useState } from 'react';

const ImportFile = ({ name, accept = '*', cancelUpload, field_text, button_text, icon, lead_id, onChooseFile }) => {
    let [file, setFile] = useState('');

    useEffect(() => {
        setFile(name);
    }, [name]);
    const handleAddImage = (e) => {
        e.preventDefault();
        onChooseFile(e);
    };
    let dragOver,
        errorNoficication = '';
    // Match drag over css to hover css
    let dragOverClass = dragOver ? `display-box drag-over` : `display-box`;
    // If file is set, change upload box text to file name
    let uploadText = file ? (
        <>
            <div className="upload-file-name">
                <span className="suceess-icn">
                    {' '}
                    <i className="ic-check"></i>{' '}
                </span>
                <h4>{file}</h4>
                {/* <i className="ic-clearclose cancel-upload-button btn btn-warning"></i> */}
            </div>
            <h3>Or</h3>
            <h7>Choose another file</h7>
        </>
    ) : (
        <div className="upload-txt">
            <span>{field_text || 'Drop or Choose File'}</span>
            <div className="or-txt"> or </div>
            <button className="btn-primary file-btn"> {button_text || 'Browse File'} </button>
        </div>
    );
    // Show Error message if file type is not an image
    const errorNotification = errorNoficication ? (
        <div className="error-notification">
            <p>{errorNoficication}</p>
        </div>
    ) : null;
    return (
        <div style={{ textAlign: 'center' }}>
            <div>
                <div className="image-uploader-wrapper">
                    <div className={dragOverClass}>
                        <div className="icon-text-box">
                            <div className="upload-icon">
                                {' '}
                                <i className="ic-file-icon" aria-hidden="true" />{' '}
                            </div>
                            <div className="upload-text">{uploadText}</div>
                            {errorNotification}
                        </div>
                        <div>
                            <form id="uploadForm" method="post" encType="multipart/form-data">
                                <input
                                    type="file"
                                    id="upload-image-input"
                                    className="upload-image-input"
                                    accept={accept}
                                    onChange={handleAddImage}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ImportFile;
