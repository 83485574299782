const leadReducer = (state = '', action) => {
    switch (action.type) {
        case 'GET_LEAD_DETAIL':
            return {
                ...state,
                leadDetail: action.payload,
            };
        case 'LEADS_REMARK_QUERY':
            return {
                ...state,
                remarksHistory: action.payload,
            };
        case 'LEAD_SORTING_INFO':
            return {
                ...state,
                leadSorting: action.payload,
            };
        case 'GET_LEAD_HISTORY':
            return {
                ...state,
                leadHistory: action.payload,
            };

        default:
            return state;
    }
};

export default leadReducer;
