import React, { Component } from 'react';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {

        return (

            <footer>
                <div className="container-fluid">
                    <div className="footer-txt">
                        <span className="copyright-txt">© Title Box. All Rights Reserved</span>
                        {/* <ul className="footer-link">
                            <li className=""><NavLink to="">Terms & Conditions</NavLink> </li>
                            <li className=""><NavLink to="">Privacy Policy</NavLink> </li>
                        </ul> */}
                    </div>
                </div>
            </footer>
        )
    }

}

export default Footer;