import { useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import { Nav, Tab } from "react-bootstrap";

const RegionWiseServices = ({
    agentDetails,
    region,
    ALL_OPTIONS,
    handleServicePriceChange
}) => {
    const [activeKey, setActiveKey] = useState("first");
    return (
        <div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                    <div className="tab-list d-flex p-md-t">
                        <Nav.Item>
                            <Nav.Link eventKey="first">LTO Office Listing</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Group Service Type & Price</Nav.Link>
                        </Nav.Item>
                    </div>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        <Accordion allowZeroExpanded>
                            {agentDetails?.region_details?.[region]?.lt_office_id?.map(officeCode => (
                                <AccordionItem uuid={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`} key={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`}>
                                    <AccordionItemHeading id={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`} aria-controls={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`}>
                                        <AccordionItemButton id={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`} aria-controls={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`}>
                                            <h2 id={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`} aria-controls={`office-${agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label?.split(" ").join("_")}`}>
                                                {agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode]?.label}
                                            </h2></AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className='add-agent-list-heading-lto-office'>
                                            <span className='heading-left'>Select Service Type</span>
                                            <span className='heading-center'>Enter Agent Fee</span>
                                            <span className='heading-right'>Region Fee</span>
                                        </div>
                                        <ul className="select-service-list-lto-office p-md-t">
                                            {ALL_OPTIONS["service_type"]?.map((data, index) => (
                                                <li>

                                                    <div className="custom-control custom-checkbox select-service-heading">
                                                        <input
                                                            id="pending"
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                        />
                                                        <label className="custom-control-label">{data?.label}</label>
                                                    </div>
                                                    <fieldset className="enter-agent-fee">
                                                        <div class="material">
                                                            <input
                                                                className="form-input"
                                                                type="number"
                                                                id={data?.label?.split(' ').join('-').toLowerCase()}
                                                                name={data?.label?.split(' ').join('-').toLowerCase()}
                                                                value={agentDetails?.region_details?.[region]?.lt_office_details?.[officeCode][data?.id]}
                                                                onChange={(e) => handleServicePriceChange(region, officeCode, data?.id, e)}
                                                                placeholder="Enter Price"
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <fieldset className="group-fee">
                                                        <div class="material">
                                                            <label>{agentDetails?.region_details?.[region]?.lt_office_details?.region_group?.[data?.id]}</label>
                                                        </div>
                                                    </fieldset>
                                                </li>
                                            ))}
                                        </ul>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>

                    </Tab.Pane >
                    <Tab.Pane eventKey="second">
                        <div className='add-agent-list-heading p-lg-t'>
                            <span className='heading-left'>Select Service Type</span>
                            <span className='heading-right'>Enter Agent Fee</span>
                        </div>
                        <ul className="select-service-list p-md-t">
                            {ALL_OPTIONS?.["service_type"]?.map((data, index) => (
                                <li>

                                    <div className=" custom-control custom-checkbox select-service-heading">
                                        <input
                                            id="pending"
                                            type="checkbox"
                                            className="custom-control-input"
                                        />
                                        <label className="custom-control-label">{data?.label}</label>
                                    </div>
                                    <fieldset className="enter-agent-fee">
                                        <div class="material">
                                            <input
                                                className="form-input"
                                                type="number"
                                                id={data?.label?.split(' ').join('-').toLowerCase()}
                                                name={data?.label?.split(' ').join('-').toLowerCase()}
                                                value={agentDetails?.region_details?.[region]?.lt_office_details?.region_group?.[data?.id]}
                                                onChange={(e) => handleServicePriceChange(region, "region_group", data?.id, e)}
                                                placeholder="Enter Price"
                                            />
                                        </div>
                                    </fieldset>
                                </li>
                            ))}
                        </ul>
                    </Tab.Pane>
                </Tab.Content >
            </Tab.Container >
        </div>
    );
};
export default RegionWiseServices;