import { useState, useEffect } from 'react';
import React from 'react';
import dateFormat from 'dateformat';
import { useSelector, useDispatch } from 'react-redux';
import { getLeadRemarks, saveLeadRemarks } from '../../store/action/allAction';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import Loader from '../elements/Loader';
import DOMPurify from 'dompurify';

const LeadRemarkHistory = ({ showremarkform, isLoading, markAsFreezed, accessCondition, sectionInfo }) => {
    const [formData, setFormData] = useState({});
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const client = useApolloClient();
    const dispatch = useDispatch();

    const { remarksHistory, leadDetail, userInfo } = useSelector(({ lead, user }) => {
        return {
            remarksHistory: lead.remarksHistory || [],
            leadDetail: lead.leadDetail,
            userInfo: user.user_information,
        };
    });
    let userDetail = userInfo && JSON.parse(userInfo);
    let user_id = userDetail && (userDetail.id || 0);

    useEffect(() => {
        setFormData({});
    }, []);
    const changeHandler = (key, e) => {
        setFormData({ ...formData, [key]: e.target.value });
        setError({ ...error, [key]: '' });
    };
    const saveRemarks = async () => {
        if (!formData.remarks || /^\s*$/.test(formData.remarks)) {
            setError({ remarks: 'This is a Required Field' });
            return false;
        }
        setLoading(true);
        const LeadData = {
            lead_id: leadDetail.id,
            remark_type: 1,
            remark: formData.remarks,
            user_id: String(user_id),
            section: sectionInfo?.section,
            sub_section: sectionInfo?.sub_section,
        };

        let { status } = await dispatch(saveLeadRemarks({ LeadData }, client));
        if (status) {
            setFormData((formData) => ({
                ...formData,
                remarks: '',
            }));
            setLoading(false);
            toast.success('Success');
            setFormData({});
            dispatch(getLeadRemarks({ lead_id: leadDetail.id, source: 'WEB' }, client));
        } else {
            toast.error('Error');
            setLoading(false);
        }
    };
    const blurHandler = (key, event) => {
        const { value } = event.target;
        event.target.value = DOMPurify.sanitize(value);
        setFormData({ ...formData, [key]: event.target.value });
    };
    return (
        <div className="comment-wrap">
            <div class="modal-body">
                <div class="timeline-history-tabel">
                    <table>
                        <tbody>
                            {remarksHistory &&
                                remarksHistory.map((remark) => (
                                    <tr>
                                        <td>
                                            <span class="date">{dateFormat(remark.created_date, 'd mmm')}</span>
                                            <span class="time">{dateFormat(remark.created_date, 'hh:MM TT')}</span>
                                        </td>
                                        <td>
                                            <div class="remarkdiv">
                                                <span class="heading" title={remark.remark}>
                                                    {remark.remark}
                                                </span>
                                                <span class="Subheading">
                                                    By{' '}
                                                    {remark && remark.user_details && remark.user_details.added_by_name}
                                                </span>
                                            </div>
                                            <div class="sectiondiv">
                                                <span class="heading" title={remark.section}>
                                                    {remark.section}
                                                </span>
                                                <span class="Subheading">
                                                    {remark && remark.sub_section ? '(' + remark.sub_section + ')' : ''}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            {remarksHistory && remarksHistory.length === 0 ? (
                                <h4>
                                    <center className="t-danger">{!isLoading && 'No remark found'}</center>
                                </h4>
                            ) : (
                                ''
                            )}
                        </tbody>
                    </table>
                </div>
                {loading ? <Loader /> : null}
                {showremarkform ? (
                    <div>
                        <fieldset class="form-filed m-md-b">
                            <span class="remark-heading-txt">Add Remark</span>
                            <div class="material">
                                <textarea
                                    name="remarks"
                                    id="remarks"
                                    placeholder=" "
                                    class="form-input"
                                    rows="3"
                                    onChange={(e) => changeHandler('remarks', e)}
                                    onBlur={(e) => blurHandler('remarks', e)}
                                    value={formData.remarks || ''}
                                    aria-label="remarks"
                                ></textarea>
                                <label data-label="Add Remark" class="form-label"></label>
                                <span class="error-msg"></span>
                            </div>
                            <span className="error-msg">{error.remarks ? error.remarks : ''}</span>
                        </fieldset>
                        {markAsFreezed || accessCondition ? (
                            <span className="span-disabled ">Submit</span>
                        ) : (
                            <button
                                aria-label="save remarks"
                                class="btn-primary"
                                onClick={saveRemarks}
                                disabled={markAsFreezed || accessCondition}
                            >
                                Submit
                            </button>
                        )}
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default LeadRemarkHistory;
