import React from "react";
import { withFormsy } from "formsy-react";
import Select, { components } from 'react-select';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    let labeltxtClass = props.selectProps && props.selectProps.placeholder && props.selectProps.placeholder.length >= 25 ? '' : ''; 
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused} className={labeltxtClass}>
                {props.selectProps.placeholder}
                {props.selectProps.isRequired ? <span> *</span> : null}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const FormsySelect = (props) => {
    const changeHandler = (data) => {
        if (typeof props.onChange === "function") {
            props.onChange(data);
        }
        props.setValue(data);
    }
    let label = props.inputProps.placeholder || "";
    if (props.required || props.showAsterisk) label += "*"
    return (
        <div className="material" title={label}>
          <Select
            {...props.inputProps}
            placeholder={label}
            components={{ ValueContainer: CustomValueContainer }}
            onChange={changeHandler}
            aria-label={label}
          />
          {props.errorMessage ? (
            <div className="error-msg">{props.errorMessage}</div>
          ) : (
            ""
          )}
        </div>
    );
}

export default withFormsy(FormsySelect);