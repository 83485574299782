import React, { useState } from "react";
import RoleList from "./RoleList";
import UserList from "./UserList";
import { IS_USER_ACCESS_PAGE } from "../../config/constants";

const ManageRoleTab = () => {
	const [showTab, setShowTab] = useState(1);

	const handleTabChange = async (event) => {
		let activeTab = event.currentTarget.dataset.key;
		setShowTab(activeTab)
	};

	return (
		<div className="loanbox-revmap-main">
			<div className="conatiner-fluid">
				<div className="role-managemtn-main m-lg-t">
					<h1>Role Management</h1>
					<div className="managment-tabs tab-chips">

						<div className="tabs">
							<div className="tab-buttons">
								{/* {tabs.map((e, index) => ( */}
								<button className={(Number(showTab) === 1
									? "active"
									: "")} data-key={1} onClick={handleTabChange} >
									Role Management
								</button>
								{IS_USER_ACCESS_PAGE('user_management', 'user_management').is_access_page && <button className={(Number(showTab) === 2
									? "active"
									: "")} data-key={2} onClick={handleTabChange} >
									User Management
								</button>}
								{/* ))} */}

							</div>
							<div className="tab-content">
								{Number(showTab) === 1 ?
									<RoleList />
									:
									<UserList />
								}
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	);

}

export default ManageRoleTab;
