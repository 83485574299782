import React, { useEffect, useState } from 'react';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import DocIcon from './DocIcon';
import Loader from '../../elements/Loader';
import UploadMoreICn from '../../../webroot/images/upload_icn.svg';
// Data for carousel
function CarouselSlide({ index, activeIndex, slide, document_list, filter_doc_child_list }) {
    let docname = '';
    if (slide.doc_id && document_list.length && filter_doc_child_list && filter_doc_child_list.length)
        docname = slide.parent_doc_id
            ? document_list.filter((v) => v.id === slide.parent_doc_id).map((v) => v.name)[0] +
            ' | ' +
            filter_doc_child_list.filter((v) => v.id === +slide.doc_id).map((v) => v.name)[0]
            : document_list.filter((v) => v.id === +slide.doc_id).map((v) => v.name)[0];
    let file_ext = slide?.doc_path?.split('?')[0]?.split('.')?.pop();
    return (
        <li className={index === activeIndex ? 'carousel__slide carousel__slide--active' : 'carousel__slide'}>
            <div className="carousel-slideimg pfd-img-height">
                {/* <span>{docname}</span> */}
                {file_ext === 'pdf' || file_ext === 'PDF' ? (
                    <a className="pdf-img" href={slide.doc_path} target="_blank" rel="noopener noreferrer">
                        <i className="ic-pdf-file pdf-icons-normal-gallery"></i>
                    </a>
                ) : (
                    <ReactPanZoom image={slide.doc_path} alt={docname} title={docname} />
                )}
            </div>
        </li>
    );
}

// Carousel wrapper component
function GallaryCarousel({
    isLoading,
    slides,
    document_list,
    getFileId,
    filter_doc_child_list,
    upload_more,
    docGallery,
    handleChangeImage,
    setUploadMore,
    setCurrentFile,
}) {
    const [slider, setSlides] = useState({
        activeSlide: 0,
        filterFiles: [],
    });

    useEffect(() => {
        setCurrentFile(slides[0]);
    }, []);

    const goToPrevSlide = () => {
        let slidesLength = slides.length;
        if (slider.activeSlide < 1) slider.activeSlide = slidesLength;
        --slider.activeSlide;
        setSlides({ ...slider, activeSlide: slider.activeSlide });
        let slideInfo = slides[slider.activeSlide];
        if (slideInfo && slideInfo.id && typeof getFileId === 'function') {
            getFileId(slideInfo.id, slideInfo.doc_id, slideInfo.parent_doc_id);
        }
        setCurrentFile(slideInfo);
    };

    const goToNextSlide = () => {
        let slidesLength = slides.length - 1;
        if (slider.activeSlide === slidesLength) slider.activeSlide = -1;
        let activeSlide = +slider.activeSlide + 1;
        setSlides({ ...slider, activeSlide: activeSlide });
        let slideInfo = slides[activeSlide];
        if (slideInfo && slideInfo.id && typeof getFileId === 'function') {
            getFileId(slideInfo.id, slideInfo.doc_id, slideInfo.parent_doc_id);
        }
        setCurrentFile(slideInfo);
    };

    const saveResubmissionImage = (e) => {
        let doc_id = 0,
            parent_doc_id = 0;
        if (!Object.keys(docGallery?.selectedSubCategory)?.length)
            doc_id = docGallery?.selectedCategory?.value || docGallery?.selectedCategory?.id;
        else {
            doc_id = docGallery?.selectedSubCategory?.value || docGallery?.selectedSubCategory?.id;
            parent_doc_id = docGallery?.selectedCategory?.value || docGallery?.selectedCategory?.id;
        }
        handleChangeImage(e, doc_id, parent_doc_id || 0, true);
        setUploadMore(false);
    };
    return (
        <div className="carousel image-varification-slider">
            {isLoading ? <Loader /> : null}
            {!upload_more ? (
                <>
                    <ul className="carousel__slides">
                        {slides &&
                            slides.map((slide, index) => (
                                <CarouselSlide
                                    index={index}
                                    slide={slide}
                                    key={index}
                                    activeIndex={slider.activeSlide}
                                    document_list={document_list}
                                    filter_doc_child_list={filter_doc_child_list}
                                />
                            ))}
                    </ul>
                    {slides && slides.length > 1 ? (
                        <div className="image-opt">
                            <DocIcon goToPrevSlide={goToPrevSlide} goToNextSlide={goToNextSlide} />
                        </div>
                    ) : (
                        ''
                    )}
                </>
            ) : (
                <div className="img-bx-select-opt doc-upload-tag-file">
                    <div className="image-bx more-file-upload">
                        <img alt="Upload more icon" src={UploadMoreICn} className="upload-icn" />
                        <form id="uploadFormMore" method="post" encType="multipart/form-data">
                            <input
                                aria-label="upload form multiple"
                                type="file"
                                name="fileName[]"
                                multiple
                                id="fileupload"
                                onChange={(e) => saveResubmissionImage(e)}
                            />
                        </form>
                        <p>Add More</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default GallaryCarousel;
