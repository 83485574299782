import DateFormate from 'dateformat';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, PAYMENT_TYPE } from '../../config/constants';
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import Modal from '../elements/Modal';
import AddStatusPaymentDetails from './addPaymentStatus';
import { STATUS_PAYMENT_DATA, UPDATE_LEAD_STATUS_DATA } from '../../services/leads.gql';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { getLoanDetail } from '../../store/action/allAction';
import { toast } from 'react-toastify';
import DocumentGallery from './DocumentGallery';
import crypto from '../../config/crypto';
import StatusDetails from './StatusDetails';
import { Nav, Tab } from 'react-bootstrap';

const PaymentDetails = ({ paymentStatusDetails }) => {
    const { paid, received } = paymentStatusDetails?.reduce((result, data) => {
        if (data?.payment_type === PAYMENT_TYPE.paid) {
            result.paid.push(data);
        }
        if (data?.payment_type === PAYMENT_TYPE.received) {
            result.received.push(data);
        }
        return result;
    }, { paid: [], received: [] });

    return (

        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey="paid">

                <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                    <div className="tab-list">
                        <Nav.Item>
                            <Nav.Link eventKey="paid">Payment Paid</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="received">Payment Received</Nav.Link>
                        </Nav.Item>

                    </div>

                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="paid">
                        <table className="">
                            {(paid?.length && (
                                <tbody>
                                    {paid &&
                                        paid.map((timelinedata, index) => (
                                            <tr className="p-relative" key={index}>
                                                <td>
                                                    <span className="status-list-heading">
                                                        {'Payment done for ₱ ' + timelinedata?.payment_amount}
                                                    </span>
                                                    {timelinedata?.followup_date ? (
                                                        <>
                                                            <span>
                                                                {timelinedata?.followup_date
                                                                    ? 'Next Follow-up : ' +
                                                                    DateFormate(
                                                                        timelinedata.followup_date,
                                                                        'dd mmm, yyyy',
                                                                    )
                                                                    : null}
                                                            </span>
                                                        </>
                                                    ) : null}
                                                    {timelinedata?.payment_request_date ? (
                                                        <>
                                                            <span>
                                                                {timelinedata?.payment_request_date
                                                                    ? 'Requested Date : ' +
                                                                    DateFormate(
                                                                        timelinedata.payment_request_date,
                                                                        'dd mmm, yyyy',
                                                                    )
                                                                    : null}
                                                            </span>
                                                        </>
                                                    ) : null}
                                                    {timelinedata?.payment_paid_date ? (
                                                        <>
                                                            <br></br>
                                                            <span>
                                                                {timelinedata?.payment_paid_date
                                                                    ? 'Paid Date : ' +
                                                                    DateFormate(
                                                                        timelinedata.payment_paid_date,
                                                                        'dd mmm, yyyy',
                                                                    )
                                                                    : null}
                                                            </span>
                                                        </>
                                                    ) : null}
                                                    {timelinedata?.remark ? (
                                                        <>
                                                            <br></br>
                                                            <span>
                                                                {timelinedata?.remark
                                                                    ? 'Comments : ' + timelinedata.remark
                                                                    : null}
                                                            </span>
                                                        </>
                                                    ) : null}
                                                    {timelinedata?.doc_path ? (
                                                        <>
                                                            <br></br>
                                                            <span>
                                                                <a href={timelinedata?.doc_path} target="_blank">
                                                                    {'View Payment Proof'}
                                                                </a>
                                                            </span>
                                                        </>
                                                    ) : null}
                                                </td>
                                                <td>{DateFormate(timelinedata.created_date, 'dd mmm, yyyy')}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            )) || <span>No Record Found</span>}
                        </table>

                    </Tab.Pane>
                    <Tab.Pane eventKey="received">
                        <table className="">
                            {(received?.length && (
                                <tbody>
                                    {received &&
                                        received.map((timelinedata, index) => (
                                            <tr className="p-relative" key={index}>
                                                <td>
                                                    <span className="status-list-heading">
                                                        {'Payment received: ₱ ' + timelinedata?.payment_amount}
                                                    </span>
                                                    {timelinedata?.payment_received_date ? (
                                                        <>
                                                            <span>
                                                                {timelinedata?.payment_received_date
                                                                    ? 'Received Date : ' +
                                                                    DateFormate(
                                                                        timelinedata.payment_received_date,
                                                                        'dd mmm, yyyy',
                                                                    )
                                                                    : null}
                                                            </span>
                                                        </>
                                                    ) : null}
                                                    {timelinedata?.remark ? (
                                                        <>
                                                            <br></br>
                                                            <span>
                                                                {timelinedata?.remark
                                                                    ? 'Comments : ' + timelinedata.remark
                                                                    : null}
                                                            </span>
                                                        </>
                                                    ) : null}
                                                    {timelinedata?.doc_path ? (
                                                        <>
                                                            <br></br>
                                                            <span>
                                                                <a href={timelinedata?.doc_path} target="_blank">
                                                                    {'View Payment Proof'}
                                                                </a>
                                                            </span>
                                                        </>
                                                    ) : null}
                                                </td>
                                                <td>{DateFormate(timelinedata.created_date, 'dd mmm, yyyy')}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            )) || <span>No Record Found</span>}
                        </table>

                    </Tab.Pane>

                </Tab.Content>

            </Tab.Container>
        </>
    );
};

export default PaymentDetails;
