import { useState } from "react";
import RegionWiseServices from "./RegionWiseServices";

const SelectLtoOffices = ({
    agentDetails,
    ALL_OPTIONS,
    region,
    handleLtoChange,
    options,
    ltoIds,
    handleServicePriceChange
}) => {
    const [searchText, setSearchText] = useState("");
    const [serviceAdded, setServiceAdded] = useState(false);
    const [optionsReplica, setOptionsReplica] = useState(options || []);
    const filterOffices = (event) => {
        setSearchText(event?.target?.value);
        setOptionsReplica(options?.filter(lto => lto?.label?.toLowerCase().includes(event?.target?.value?.toLowerCase())));
    };

    const selectLto = (event, lto_id) => {
        if (event?.target?.checked) {
            handleLtoChange("lt_office_id", options?.filter(lto => [...ltoIds, lto_id]?.includes(lto?.id)));
        }
        else {
            handleLtoChange("lt_office_id", options?.filter(lto => [...ltoIds?.filter(el => el !== lto_id)]?.includes(lto?.id)));
        }
    };
    return serviceAdded ?
        <div className="region-panel-bg">
            <RegionWiseServices agentDetails={agentDetails} region={region} ALL_OPTIONS={ALL_OPTIONS} handleServicePriceChange={handleServicePriceChange} />
        </div> :
        <>
            <div className='filter-filed-form filter-offices'>
                <fieldset className="search-bx">
                    <div className="material">
                        <input
                            id=""
                            type="text"
                            aria-label="search text"
                            name="search_text"
                            value={searchText || ''}
                            onChange={(e) => filterOffices(e)}
                            className="form-input"
                            title={'SEARCH_TEXT'}
                            placeholder="Search By Dealer or Customer"
                        />
                        <i className="ic-search"></i>
                    </div>
                </fieldset>
                <div className="btn-submit-reset top-btn-none m-sm-l">
                    <button onClick={() => setServiceAdded(true)} className="btn-primary">
                        Add
                    </button>
                </div>
            </div>
            <ul className="lto-listing">
                {optionsReplica?.map(lto => (
                    <li className="custom-control custom-checkbox select-service-heading">
                        <input
                            id="pending"
                            type="checkbox"
                            className="custom-control-input"
                            onChange={(e) => selectLto(e, lto?.id)}
                            checked={ltoIds?.includes(lto?.id)}
                        />
                        <label className="custom-control-label">{lto?.label}</label>
                    </li>
                ))}
            </ul>
        </>;
};
export default SelectLtoOffices;