import DateFormate from 'dateformat';
import React from 'react';

const StatusDetails = ({ paymentStatusDetails }) => {

    return (
        <table className="">
            {(paymentStatusDetails?.length && (
                <tbody>
                    {paymentStatusDetails &&
                        paymentStatusDetails.map((timelinedata, index) => (
                            <tr className="p-relative" key={index}>
                                <td>
                                    <span className="status-list-heading">
                                        {timelinedata.title}
                                    </span>
                                    {timelinedata?.followup_date ? (
                                        <>
                                            <span>
                                                {timelinedata?.followup_date
                                                    ? 'Next Follow-up : ' +
                                                    DateFormate(
                                                        timelinedata.followup_date,
                                                        'dd mmm, yyyy',
                                                    )
                                                    : null}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.payment_request_date ? (
                                        <>
                                            <span>
                                                {timelinedata?.payment_request_date
                                                    ? 'Requested Date : ' +
                                                    DateFormate(
                                                        timelinedata.payment_request_date,
                                                        'dd mmm, yyyy',
                                                    )
                                                    : null}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.payment_paid_date ? (
                                        <>
                                            <br></br>
                                            <span>
                                                {timelinedata?.payment_paid_date
                                                    ? 'Paid Date : ' +
                                                    DateFormate(
                                                        timelinedata.payment_paid_date,
                                                        'dd mmm, yyyy',
                                                    )
                                                    : null}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.stage_name ? (
                                        <>
                                            <br></br>
                                            <span>
                                                {timelinedata?.stage_name
                                                    ? 'Stage : ' + timelinedata.stage_name
                                                    : null}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.remark ? (
                                        <>
                                            <br></br>
                                            <span>
                                                {timelinedata?.remark
                                                    ? 'Comments : ' + timelinedata.remark
                                                    : null}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.doc_path ? (
                                        <>
                                            <br></br>
                                            <span>
                                                <a href={timelinedata?.doc_path} target="_blank">
                                                    {'View Documents'}
                                                </a>
                                            </span>
                                        </>
                                    ) : null}
                                </td>
                                <td>{DateFormate(timelinedata.created_date, 'dd mmm, yyyy')}</td>
                            </tr>
                        ))}
                </tbody>
            )) || <span>No Record Found</span>}
        </table>
    );
};

export default StatusDetails;
