import React from 'react';
import DateFormate from 'dateformat';
import { Tab, Nav } from 'react-bootstrap';
const LeadStatusTrackHistory = ({ historyList, paymentStatusDetails, isLoading, t }) => {
    const historyToRender = [
        { label: `Application ID Timeline`, eventKey: `lead-history`, error: `Customer_History_Not_Found`, rows: historyList },
        { label: `Agent Status Timeline`, eventKey: `status-history`, error: `Status_History_Not_Found`, rows: paymentStatusDetails },
    ];
    return (
        <div className="comment-wrap">
            <Tab.Container id="left-tabs-example" defaultActiveKey={historyToRender[0]?.eventKey}>
                <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                    <div className="tab-list">
                        {historyToRender.map(({ eventKey, label }) => (
                            <Nav.Item>
                                <Nav.Link eventKey={eventKey}>{label}</Nav.Link>
                            </Nav.Item>
                        ))}
                    </div>
                </Nav>

                <div className="modal-body">
                    {historyToRender.map(({ eventKey, error, rows }) => (
                        <Tab.Content>
                            {['lead-history'].includes(eventKey) && (
                                <Tab.Pane eventKey={`lead-history`}>
                                    <table>
                                        {(rows?.length && (
                                            <tbody>
                                                {rows &&
                                                    rows.map((timelinedata, index) => (
                                                        <tr className="p-relative" key={index}>
                                                            <td>
                                                                <span className="date">
                                                                    {(timelinedata?.created_date &&
                                                                        DateFormate(timelinedata.created_date, 'dd mmm')) ||
                                                                        ''}
                                                                </span>
                                                                <span className="time">
                                                                    {(timelinedata?.created_date &&
                                                                        DateFormate(
                                                                            timelinedata.created_date,
                                                                            'h:MM TT',
                                                                        )) ||
                                                                        ''}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <div className="history-list-outer">
                                                                    <div>
                                                                        <span className="heading">
                                                                            {timelinedata?.lead_status?.name || '-'}
                                                                        </span>
                                                                        {timelinedata?.lead_sub_status?.sub_status_name ? (
                                                                            <span className="heading">
                                                                                {'(' +
                                                                                    timelinedata?.lead_sub_status
                                                                                        ?.sub_status_name +
                                                                                    ')' || '-'}
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{timelinedata.user_details?.name || '-'}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        )) || null}
                                    </table>
                                </Tab.Pane>

                            )}
                            {['status-history'].includes(eventKey) && (
                                <Tab.Pane eventKey={`status-history`}>
                                    {/* <div className="status-payment-detail-outer"> */}
                                    <table className="">
                                        {(rows?.length && (
                                            <tbody>
                                                {rows &&
                                                    rows.map((timelinedata, index) => (
                                                        <tr className="p-relative" key={index}>
                                                            <td>{DateFormate(timelinedata.created_date, 'dd mmm, yyyy')}</td>
                                                            <td className="status-grid">
                                                                <span className="status-list-heading">
                                                                    {timelinedata.title}
                                                                </span>
                                                                {timelinedata?.followup_date ? (
                                                                    <>
                                                                        <span>
                                                                            {timelinedata?.followup_date
                                                                                ? 'Next Follow-up : ' +
                                                                                DateFormate(
                                                                                    timelinedata.followup_date,
                                                                                    'dd mmm, yyyy',
                                                                                )
                                                                                : null}
                                                                        </span>
                                                                    </>
                                                                ) : null}
                                                                {timelinedata?.payment_request_date ? (
                                                                    <>
                                                                        <span>
                                                                            {timelinedata?.payment_request_date
                                                                                ? 'Requested Date : ' +
                                                                                DateFormate(
                                                                                    timelinedata.payment_request_date,
                                                                                    'dd mmm, yyyy',
                                                                                )
                                                                                : null}
                                                                        </span>
                                                                    </>
                                                                ) : null}
                                                                {timelinedata?.payment_paid_date ? (
                                                                    <>
                                                                        <br></br>
                                                                        <span>
                                                                            {timelinedata?.payment_paid_date
                                                                                ? 'Paid Date : ' +
                                                                                DateFormate(
                                                                                    timelinedata.payment_paid_date,
                                                                                    'dd mmm, yyyy',
                                                                                )
                                                                                : null}
                                                                        </span>
                                                                    </>
                                                                ) : null}
                                                                {timelinedata?.doc_path ? (
                                                                    <>
                                                                        <br></br>
                                                                        <span>
                                                                            <a href={timelinedata?.doc_path} target="_blank">
                                                                                {'View Documents'}
                                                                            </a>
                                                                        </span>
                                                                    </>
                                                                ) : null}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        )) || <span>No Record Found</span>}
                                    </table>
                                    {/* </div> */}
                                </Tab.Pane>
                            )}
                        </Tab.Content>
                    ))}
                </div>
            </Tab.Container>
        </div>
    );
};

export default LeadStatusTrackHistory;
