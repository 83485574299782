import React, { useState, useEffect } from 'react';
import Modal from '../elements/Modal';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/client';
// import RoleActions from "../../../store/action/RoleActions";
import AddRole from './AddRole';
import EditRole from './EditRole';
import { IS_USER_ACCESS_PAGE } from '../../config/constants';
// import secureStorage from '../../../config/encrypt';
import ConfirmPopUp from '../elements/ConfirmPopUp';
import Loader from '../elements/Loader';
import { GET_ROLE_LIST } from '../../services/role.gql';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { UPDATE_STATUS } from '../../services/role.gql';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';

const RoleList = () => {
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [roleDetails, setRoleDetails] = useState({});
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);
    // const { data: loginUserInfo } = secureStorage.getItem('loginUserInfo');
    const loginUserInfo = JSON.parse(localStorage.getItem('user_information'));

    useEffect(() => {
        const fetchData = async () => {
            getRoleList({});
        };
        fetchData();
    }, []);

    const getRoleList = async (params = {}) => {
        setLoading(true);
        params['apply_pagination'] = false;
        let data = [];
        await executeGraphQLQuery(GET_ROLE_LIST(), client)
            .then((response) => {
                // console.log("response", response);
                if (response && response.data && response.data.get_role_list) {
                    data = response.data.get_role_list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        if (data) {
            setRoleList(data);
        }
        setLoading(false);
    };

    const deleteStatus = async (id) => {
        setIdToDelete(id);
        setShowConfirmation(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setShowEditModal(false);
    };

    const updateList = () => {
        closeModal();
        getRoleList({});
    };

    const updateRoleData = (details, fTitle) => {
        setRoleDetails(details);
        setShowModal(true);
    };

    const updateEditModal = (data) => {
        setRoleDetails(data);
        setShowEditModal(true);
    };

    const confirmYes = async () => {
        setLoading(true);
        try {
            const result = await executeGraphQLMutation(
                UPDATE_STATUS,
                { action_type: 'role', id: idToDelete, user_id: loginUserInfo?.user_data?.user_id || 0, status: '2' },
                client,
            );
            if (result) {
                toast.success(result?.data?.update_status?.message || 'Success');
                getRoleList({});
            }
        } catch (error) {
            toast.error(error.toString().replace('ApolloError:', ''));
            console.error(error);
        }
        setLoading(false);
        confirmNo();
    };

    const confirmNo = () => {
        setShowConfirmation(false);
    };

    let isEditAccess = IS_USER_ACCESS_PAGE('role_management', 'role_management')?.is_edit_access || false;
    let isSuperAdmin = loginUserInfo && loginUserInfo.role_id.includes(1);
    isEditAccess = isSuperAdmin ? true : isEditAccess;

    return (
        <div className="tab-main-outer">
            {!isEditAccess ? (
                <button className="btn-line btn-role-manage" disabled={true}>
                    Add Role
                </button>
            ) : (
                <button
                    className="btn-line btn-role-manage"
                    onClick={() => {
                        updateRoleData({}, 'ADD_ROLE');
                    }}
                >
                    Add Role
                </button>
            )}
            <div className="tab-data">
                <div className="data-table rolemanagemnt-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Role</th>
                                <th>Description</th>
                                <th>Users Assigned</th>
                                <th>Feature Access</th>
                                <th>Status</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roleList && roleList.length > 0
                                ? roleList.map((data, index) => (
                                      <React.Fragment key={data.id}>
                                          <tr key={index}>
                                              <td>{data.name}</td>
                                              <td>{data.description}</td>
                                              <td>{data.user_assigned_count} Users</td>
                                              <td>{data.feature_access_count} Features</td>
                                              <td>
                                                  <span
                                                      className={
                                                          Number(data.status) === 1
                                                              ? 'status-active'
                                                              : 'status-inactive'
                                                      }
                                                  >
                                                      {data.role_status}
                                                  </span>
                                              </td>
                                              <td>
                                                  <button
                                                      type="button"
                                                      className="btn-edit-delete"
                                                      onClick={isEditAccess ? updateEditModal.bind(this, data) : null}
                                                  >
                                                      Edit
                                                  </button>
                                              </td>
                                              <td>
                                                  {!isSuperAdmin ? (
                                                      <button className="btn-edit-delete" disabled={true}>
                                                          Delete
                                                      </button>
                                                  ) : (
                                                      <button
                                                          className="btn-edit-delete"
                                                          onClick={
                                                              isSuperAdmin ? deleteStatus.bind(this, data.id) : null
                                                          }
                                                      >
                                                          Delete
                                                      </button>
                                                  )}
                                              </td>
                                          </tr>
                                      </React.Fragment>
                                  ))
                                : !loading && (
                                      <tr>
                                          <td colSpan="7">
                                              <h6 className="text-danger">Record Not Found</h6>
                                          </td>
                                      </tr>
                                  )}
                        </tbody>
                    </table>
                </div>
            </div>
            {showModal === false ? (
                ''
            ) : (
                <div className="mark-lost-popup">
                    <Modal show={showModal} id="Updateform" className="modal modal-xl" handleClose={closeModal}>
                        <div className="modal-header">
                            <h2>Add Role</h2>
                        </div>
                        <AddRole
                            user_id={loginUserInfo?.user_data?.user_id || 0}
                            updateList={updateList}
                            roleDetails={roleDetails}
                        />
                    </Modal>
                </div>
            )}
            {showEditModal === false ? (
                ''
            ) : (
                <EditRole
                    user_id={loginUserInfo?.user_data?.user_id || 0}
                    updateList={updateList}
                    roleDetails={roleDetails}
                    closeModal={closeModal}
                />
            )}
            {showConfirmation === false ? (
                ''
            ) : (
                <ConfirmPopUp
                    confirmNo={confirmNo}
                    confirmYes={confirmYes}
                    message="Are you sure you want to delete this ?"
                />
            )}
            {loading ? <Loader /> : null}
        </div>
    );
};

export default RoleList;
