import React from 'react';
import DateFormate from 'dateformat';
import { Tab, Nav } from 'react-bootstrap';
const LeadHistory = ({ historyList, isLoading, t }) => {
    const historyToRender = [
        { label: `Lead History`, eventKey: `lead-history`, error: `Customer_History_Not_Found`, rows: historyList },
    ];
    return (
        <div className="comment-wrap">
            <Tab.Container id="left-tabs-example" defaultActiveKey={historyToRender[0]?.eventKey}>
                <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                    <div className="tab-list">
                        {historyToRender.map(({ eventKey, label }) => (
                            <Nav.Item>
                                <Nav.Link eventKey={eventKey}>{label}</Nav.Link>
                            </Nav.Item>
                        ))}
                    </div>
                </Nav>

                <div className="modal-body">
                    {historyToRender.map(({ eventKey, error, rows }) => (
                        <Tab.Content>
                            <Tab.Pane eventKey={eventKey}>
                                <table>
                                    {(rows?.length && (
                                        <tbody>
                                            {rows &&
                                                rows.map((timelinedata, index) => (
                                                    <tr className="p-relative" key={index}>
                                                        <td>
                                                            <span className="date">
                                                                {(timelinedata?.created_date &&
                                                                    DateFormate(timelinedata.created_date, 'dd mmm')) ||
                                                                    ''}
                                                            </span>
                                                            <span className="time">
                                                                {(timelinedata?.created_date &&
                                                                    DateFormate(
                                                                        timelinedata.created_date,
                                                                        'h:MM TT',
                                                                    )) ||
                                                                    ''}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <div className="history-list-outer">
                                                                <div>
                                                                    <span className="heading">
                                                                        {timelinedata?.lead_status?.name || '-'}
                                                                    </span>
                                                                    {timelinedata?.lead_sub_status?.sub_status_name ? (
                                                                        <span className="heading">
                                                                            {'(' +
                                                                                timelinedata?.lead_sub_status
                                                                                    ?.sub_status_name +
                                                                                ')' || '-'}
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{timelinedata.user_details?.name || '-'}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    )) || (
                                        <h4>
                                            <center className="t-danger">
                                                {!isLoading && t(`loanBoxLang.${error}`)}
                                            </center>
                                        </h4>
                                    )}
                                </table>
                            </Tab.Pane>
                        </Tab.Content>
                    ))}
                </div>
            </Tab.Container>
        </div>
    );
};

export default LeadHistory;
