import React, { useEffect, useState, useMemo } from 'react';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import Formsy from 'formsy-react';
import FormsyInputField from '../elements/FormsyInputField';
import FormsyDatePicker from '../elements/FormsyDatePicker';
import FormsySelect from '../elements/FormsySelect';
import FormsyTextAreaAutosize from '../elements/FormsyTextAreaAutosize';
import UploadMoreICn from '../../webroot/images/upload_icn.svg';
import GeneralService from '../../services/generalService';
import { gql, useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getLoanDetail } from '../../store/action/allAction';
import ImportFile from '../../common/ImportFIle';
import Loader from '../elements/Loader';
import { PAYMENT_TYPE, getKeysDataIfExist } from '../../config/constants';

const AddStatusPaymentDetails = ({ lead, param, user_id, paymentStatusDetails }) => {
    const [paymentStatusData, setPaymentStatusData] = useState([]);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [imageName, setImageName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    let headerName = param === 'status-details' ? 'Add Status' : 'Add Payment Record';
    const client = useApolloClient();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    const { masterdata } = useSelector(({ masterdata }) => ({ masterdata }));

    const handleToUploadLogo = async (event) => {
        let file = event.target.files[0];
        var doc_path = '';
        if (file) {
            const formData = new FormData();
            formData.append('upload_type', 'finance_docs');
            formData.append('lead_id', lead?.leadDetail?.id);
            formData.append('images', file);

            try {
                const publicUrl = await GeneralService.uploadFilesToS3(formData, {
                    'Content-Type': 'multipart/form-data',
                });
                doc_path =
                    publicUrl &&
                    publicUrl.data &&
                    publicUrl.data.data &&
                    publicUrl.data.data.length &&
                    (publicUrl.data.data[0].file_url || '');
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
        setImagePreview(doc_path);
        setImageName(file?.name);
    };

    const handleInputChange = (event) => {
        if (event.target.id) {
            setPaymentStatusData((currentValue) => ({
                ...currentValue,
                [event.target.id]: event.target.value,
            }));
        }
    };

    const handleDateChange = (date, field_name) => {
        if (date && field_name) {
            setPaymentStatusData((currentValue) => ({
                ...currentValue,
                [field_name]: date,
            }));
        }
    };

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            setPaymentStatusData((currentValue) => ({
                ...currentValue,
                [field_name]: data.value,
            }));
        }
    };
    const handleRadioChange = event => {
        const tempPaymentStatusData = { ...paymentStatusData };
        if (event.target.name && event.target.value) {
            if ([PAYMENT_TYPE.paid].includes(event.target.value)) {
                delete tempPaymentStatusData?.payment_received_date;
            }
            else if ([PAYMENT_TYPE.received].includes(event.target.value)) {
                delete tempPaymentStatusData?.payment_request_date;
                delete tempPaymentStatusData?.payment_paid_date;
            }
            setPaymentStatusData({
                ...tempPaymentStatusData,
                [event.target.name]: event.target.value
            });
        }

    };

    let isRadioMarked = (param === 'status-details' ? false : !paymentStatusData?.payment_type);

    const onSubmit = async (e) => {
        let variables, mutation;
        e.preventDefault();
        if (!Object.keys(paymentStatusData)?.length) {
            toast.error('Please enter data');
            return;
        }
        setIsLoading(true);

        const lead_id = lead?.leadDetail?.id || 0;
        if (param === 'status-details') {
            variables = {
                statusPaymentInput: {
                    lead_id: lead?.leadDetail?.id,
                    upload_proof: imagePreview || null,
                    created_by: user_id,
                    title: paymentStatusData?.title || '',
                    status_stage_id: paymentStatusData?.status_stage_id || 0,
                    remark: paymentStatusData?.remark || "",
                    followup_date: paymentStatusData?.followup_date || null,
                    history_type: 1,
                },
            };
        } else {
            variables = {
                statusPaymentInput: {
                    lead_id: lead?.leadDetail?.id,
                    upload_proof: imagePreview || null,
                    created_by: user_id,
                    history_type: 2,
                    payment_amount: Number(paymentStatusData?.payment_amount) || '',
                    payment_type: paymentStatusData?.payment_type || '',
                    ...getKeysDataIfExist(paymentStatusData, ["payment_request_date", "payment_paid_date", "payment_received_date", "remark"])
                }
            };
        }
        variables = { ...variables, user_id: UserInfo?.id || 0 };
        mutation = gql`
            mutation saveStatusPaymentDetails($statusPaymentInput: StatusPaymentInput!, $user_id: Float!) {
                save_status_payment_details(statusPaymentInput: $statusPaymentInput, user_id: $user_id) {
                    success
                    error
                }
            }
        `;
        try {
            await executeGraphQLMutation(mutation, variables, client)
                .then((resp) => {
                    let response = resp?.data?.add_status_details || resp?.data?.payment_proof_details || null;
                    if (response && !response.message) {
                        throw new Error(response.error || 'Something went wrong');
                    }
                    toast.success('Success');
                    dispatch(getLoanDetail(lead_id, client));
                    window.location.reload();
                    // setIsLoading(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setIsLoading(false);
                });
        } catch (error) {
            toast.error('Failed');
            console.error(error);
            setIsLoading(false);
        }
    };

    const ADD_PAYMENT_RADIO_OPTIONS = [
        { "id": "payment_type", "type": "radio", "label": "Select Payment Type", "options": [{ "name": "payment_type", "value": PAYMENT_TYPE.paid, "label": "Paid" }, { "name": "payment_type", "value": PAYMENT_TYPE.received, "label": "Received" }] },
    ];
    const ADD_PAYMENT_DATE_TYPE = [
        {
            "id": "payment_request_date",
            "name": "payment_request_date",
            "readOnly": false,
            "type": "date-picker",
            "label": "Requested Date",
            "placeholder": "Requested Date",
            "required": true,
            "validations": null,
            "validationError": "Please select date",
            "ApplyNumberformat": false,
            "showAsterisk": true,
            "dateFormat": "dd MMM, yyyy",
            "dependentOn": "payment_type",
            "dependentValue": [PAYMENT_TYPE.paid]
        },
        {
            "id": "payment_paid_date",
            "name": "payment_paid_date",
            "readOnly": false,
            "type": "date-picker",
            "label": "Paid Date",
            "placeholder": "Paid Date",
            "required": true,
            "validations": null,
            "validationError": "Please select date",
            "ApplyNumberformat": false,
            "showAsterisk": true,
            "dateFormat": "dd MMM, yyyy",
            "dependentOn": "payment_type",
            "dependentValue": [PAYMENT_TYPE.paid]
        },
        {
            "id": "payment_received_date",
            "name": "payment_received_date",
            "readOnly": false,
            "type": "date-picker",
            "label": "Received Date",
            "placeholder": "Received Date",
            "required": true,
            "validations": null,
            "validationError": "Please select date",
            "ApplyNumberformat": false,
            "showAsterisk": true,
            "dateFormat": "dd MMM, yyyy",
            "dependentOn": "payment_type",
            "dependentValue": [PAYMENT_TYPE.received]
        },
    ];
    const REMARK = [
        {
            "id": "remark",
            "name": "remark",
            "readOnly": false,
            "type": "text",
            "label": "Remark",
            "required": false,
            "validations": null,
            "validationError": "",
            "ApplyNumberformat": false,
            "showAsterisk": false,
            "is_full_row": true
        }
    ];

    const handleSelectChange = (data, field_name) => {
        if (data && field_name) {
            const newFields = { [field_name]: +data.value };
            setPaymentStatusData((currentValue) => ({
                ...currentValue,
                ...newFields
            }));
        }
    };

    let ALL_OPTIONS = useMemo(() => {
        let options = masterdata ? { ...masterdata.data } : {};
        let markedStagesIDs = paymentStatusDetails?.filter(data => data.status_stage_id > 0).map(v => v.status_stage_id);
        options.status_update_stages = ([2, 5].includes(lead?.leadDetail?.service_type_id)) ? options?.status_update_stages : options?.status_update_stages?.filter(data => data.id != 2);
        if (paymentStatusDetails?.filter(data => data?.status_stage_id === 5)?.length) {
            options.status_update_stages = [];
        } else if (paymentStatusDetails?.filter(data => data?.status_stage_id === 4)?.length) {
            options.status_update_stages = options?.status_update_stages?.filter(data => [5].includes(data?.id));
        } else if (paymentStatusDetails?.filter(data => data?.status_stage_id === 3)?.length) {
            options.status_update_stages = options?.status_update_stages?.filter(data => [4].includes(data?.id));
        } else if (!paymentStatusDetails?.filter(data => !data?.status_stage_id)?.length || !paymentStatusDetails?.filter(data => data?.status_stage_id === 3)?.length) {
            options.status_update_stages = options?.status_update_stages?.filter(data => [1, 2, 3, 4].includes(data?.id));
            options.status_update_stages = options.status_update_stages.filter(data => !markedStagesIDs.includes(data?.id));
        }

        return options;
    }, [masterdata, paymentStatusDetails?.length]);

    return (
        <div>
            {isLoading ? <Loader /> : null}
            <div className="modal-header">
                <h2>{headerName}</h2>
            </div>

            <div className="modal-body">
                <form>
                    <div className="">
                        <>
                            <Formsy
                                className="lead-form-filed"
                                autoComplete="off"
                                onValid={() => setAllowSubmit(true)}
                                onInvalid={() => setAllowSubmit(false)}
                                onValidSubmit={onSubmit}
                                aria-label="lead form field basic"
                            >
                                {param === 'status-details' ? (
                                    <div className="row">
                                        <fieldset class="form-filed col-md-6 m-lg-b">
                                            <FormsyInputField
                                                id="title"
                                                name="title"
                                                type="text"
                                                value={paymentStatusData?.title || ''}
                                                placeholder=" "
                                                label="Title"
                                                onChange={handleInputChange}
                                                validations={null}
                                                validationError=""
                                                readOnly={false}
                                                required={true}
                                                showAsterisk={true}
                                            />
                                        </fieldset>

                                        <fieldset class="single-select col-md-6">
                                            <FormsySelect
                                                name="status_stage_id"
                                                id="status_stage_id"
                                                inputProps={{
                                                    options: ALL_OPTIONS && ALL_OPTIONS["status_update_stages"],
                                                    placeholder: "Stage",
                                                    className: 'react-select',
                                                    classNamePrefix: 'react-select',
                                                    value:
                                                        ALL_OPTIONS &&
                                                        ALL_OPTIONS["status_update_stages"] &&
                                                        ALL_OPTIONS["status_update_stages"].filter(
                                                            ({ value }) => value == paymentStatusData['status_stage_id']?.toString(),
                                                        ),
                                                }}
                                                required={false}
                                                showAsterisk={false}
                                                value={paymentStatusData && paymentStatusData["status_stage_id"]}
                                                onChange={(data) => handleSelectChange(data, "status_stage_id")}
                                            />
                                        </fieldset>

                                        <fieldset class="form-filed col-md-6 m-lg-b datepicker-filed">
                                            <FormsyDatePicker
                                                name="followup_date"
                                                selectedDate={
                                                    (paymentStatusData?.followup_date &&
                                                        new Date(paymentStatusData?.followup_date)) ||
                                                    ''
                                                }
                                                placeholder="Follow-up Date"
                                                minDate={new Date().setMonth(new Date().getMonth() - 3)}
                                                maxDate={new Date().setFullYear(new Date().getFullYear() + 3)}
                                                value={
                                                    (paymentStatusData?.followup_date &&
                                                        new Date(paymentStatusData?.followup_date)) ||
                                                    ''
                                                }
                                                onDateSelect={(date) => handleDateChange(date, 'followup_date')}
                                                dateFormat="dd MMM, yyyy"
                                                validations={null}
                                                validationError=""
                                                required={true}
                                                showAsterisk={true}
                                                readOnly={false}
                                            />
                                        </fieldset>


                                        {REMARK?.map(field => (
                                            <fieldset className="form-filed text-area-filed col-md-12 m-lg-b">
                                                <div className="material">
                                                    <FormsyTextAreaAutosize
                                                        id={field.id}
                                                        name={field.name}
                                                        type={field.type}
                                                        value={paymentStatusData && paymentStatusData?.[field?.name] || ""}
                                                        placeholder=" "
                                                        label={field.label}
                                                        onChange={handleInputChange}
                                                        format={field.ApplyNumberformat ? field.format : ''}
                                                        validations={field.validations}
                                                        validationError={paymentStatusData[field.name] ? field.validationError : ''}
                                                        // readOnly={isReadOnly || isSubmitDisabled}
                                                        required={field?.required}
                                                        showAsterisk={field.showAsterisk}
                                                    />
                                                </div>
                                            </fieldset>
                                        ))}




                                        <fieldset className="form-filed col-md-12 fin-logo-row">
                                            <div className="image-uploader-outer">
                                                <ImportFile name={imageName} onChooseFile={handleToUploadLogo} field_text={'Upload by dropping file here'} />
                                            </div>
                                        </fieldset>
                                    </div>
                                ) : (
                                    <div className="row">
                                        {ADD_PAYMENT_RADIO_OPTIONS?.map((field) =>
                                        (
                                            <fieldset className="form-filed col-md-12  m-lg-b radio-btn radio-type-txt">
                                                <label className="form-label">{field?.label}</label><br />
                                                {field.options.map((option) => (
                                                    <div className="custom-control custom-checkbox" key={option.value}>
                                                        <input
                                                            id={option.value}
                                                            type="radio"
                                                            className="custom-control-input"
                                                            name={option.name}
                                                            value={option.value}
                                                            onChange={handleRadioChange}
                                                            aria-label={`for ${option.name}`}
                                                            required={true}
                                                        />
                                                        <label htmlFor={option.id} className="custom-control-label">{option.label}</label>
                                                    </div>
                                                ))}
                                            </fieldset>)

                                        )}

                                        <fieldset class="form-filed col-md-6 m-lg-b add-payment-datpicker">
                                            <FormsyInputField
                                                id="payment_amount"
                                                name="payment_amount"
                                                type="number-format"
                                                value={paymentStatusData?.payment_amount || ''}
                                                placeholder=" "
                                                label="Payment Amount"
                                                onChange={(data) => handlePatternFormatChange(data, 'payment_amount')}
                                                validations={null}
                                                validationError=""
                                                readOnly={false}
                                                required={true}
                                                showAsterisk={true}
                                            />
                                        </fieldset>

                                        {ADD_PAYMENT_DATE_TYPE?.map(field => (
                                            field?.dependentValue?.includes(paymentStatusData?.[field?.dependentOn]) &&
                                            <fieldset class="form-filed col-md-6 m-lg-b datepicker-filed add-payment-datpicker">
                                                <FormsyDatePicker
                                                    name={field?.name}
                                                    selectedDate={
                                                        (paymentStatusData?.[field?.name] &&
                                                            new Date(paymentStatusData?.[field?.name])) ||
                                                        ''
                                                    }
                                                    placeholder={field?.placeholder}
                                                    minDate={new Date().setMonth(new Date().getMonth() - 3)}
                                                    maxDate={new Date().setFullYear(new Date().getFullYear() + 3)}
                                                    value={
                                                        (paymentStatusData?.[field?.name] &&
                                                            new Date(paymentStatusData?.[field?.name])) ||
                                                        ''
                                                    }
                                                    dateFormat={field?.dateFormat}
                                                    onDateSelect={(date) => handleDateChange(date, field?.name)}
                                                    validations={null}
                                                    validationError=""
                                                    required={true}
                                                    readOnly={false}
                                                />
                                            </fieldset>
                                        ))}
                                        {REMARK?.map(field => (
                                            <fieldset className="form-filed text-area-filed col-md-12 m-lg-b">
                                                <div className="material">
                                                    <FormsyTextAreaAutosize
                                                        id={field.id}
                                                        name={field.name}
                                                        type={field.type}
                                                        value={paymentStatusData && paymentStatusData?.[field?.name] || ""}
                                                        placeholder=" "
                                                        label={field.label}
                                                        onChange={handleInputChange}
                                                        format={field.ApplyNumberformat ? field.format : ''}
                                                        validations={field.validations}
                                                        validationError={paymentStatusData[field.name] ? field.validationError : ''}
                                                        // readOnly={isReadOnly || isSubmitDisabled}
                                                        required={field?.required}
                                                        showAsterisk={field.showAsterisk}
                                                    />
                                                </div>
                                            </fieldset>
                                        ))}

                                        <fieldset className="form-filed col-md-12 fin-logo-row">
                                            <div className="image-uploader-outer">
                                                <ImportFile name={imageName} onChooseFile={handleToUploadLogo} field_text={'Upload by dropping file here'} />
                                            </div>
                                        </fieldset>
                                    </div>
                                )}
                                <div className="btn-save-remarks">
                                    <button
                                        aria-label="save naxt"
                                        onClick={onSubmit}
                                        className="btn-primary"
                                        disabled={!allowSubmit || isRadioMarked}
                                    >
                                        {headerName}
                                    </button>
                                </div>
                            </Formsy>
                        </>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddStatusPaymentDetails;
