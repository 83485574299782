import DocIcon from "../leads/docs-upload/DocIcon";
import { useEffect, useState } from "react";
import { getFileExtensionFromURL } from "../../config/constants";
import { saveAs } from 'file-saver';

const DocsPreviewORCR = ({ docs }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [slides, setSlides] = useState([]);

    useEffect(() => {
        setSlides(docs);
    }, [docs]);

    const goToPrevSlide = () => {
        setActiveSlide((activeSlide - 1 + slides.length) % slides.length);
    };

    const goToNextSlide = () => {
        setActiveSlide((activeSlide + 1) % slides.length);
    };

    const download = async (image_path) => {
        saveAs(image_path, slides?.[activeSlide]?.doc_name + "_" + activeSlide);
    }

    return <>
        {
            docs?.length ?
                <>
                    <div className="carousel-slideimg pfd-img-height btn-download-tb">
                        {['pdf', 'PDF'].includes(getFileExtensionFromURL(slides?.[activeSlide]?.doc_path)) ? (
                            <a className="pdf-img" href={slides?.[activeSlide]?.doc_path} target="_blank" rel="noopener noreferrer">
                                <i className="ic-pdf-file pdf-icons-normal-gallery"></i>
                            </a>
                        ) : (
                            <img src={slides?.[activeSlide]?.doc_path}></img>
                        )}
                        <button className="btn-line" onClick={() => download(slides?.[activeSlide]?.doc_path)}>
                            Download
                        </button>
                    </div>
                    {slides && slides.length > 1 ? (
                        <div className="image-opt slider-arrow-view-doc">
                            <DocIcon goToPrevSlide={goToPrevSlide} goToNextSlide={goToNextSlide} />
                        </div>
                    ) : (
                        ''
                    )}
                </>
                : <span><b>No Docs Found</b></span>
        }
    </>;
};

export default DocsPreviewORCR;