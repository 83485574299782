import { gql } from '@apollo/client';

export const SAVE_CUSTOMER_BASIC_DETAILS = gql`
    mutation saveBasicDetails($basicDetailsInput: SaveBasicDetailsInput!, $api_called_by: API_CALL_BY!, $user_id: Float!, $role_id: Float!) {
        save_customer_basic_details(basic_details_input: $basicDetailsInput, api_called_by: $api_called_by, user_id: $user_id, role_id: $role_id) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_VEHICLE_DETAILS = gql`
    mutation saveVehicleDetails($vehicleDetailsInput: SaveVehicleDetailsInput!, $api_called_by: API_CALL_BY!, $user_id: Float!, $role_id: Float!) {
        save_customer_vehicle_details(vehicle_details_input: $vehicleDetailsInput, api_called_by: $api_called_by, user_id: $user_id, role_id: $role_id) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_EMPLOYMENT_DETAILS = gql`
    mutation saveEmploymentDetails($empDetailInput: SaveEmploymentDetails!) {
        save_customer_employment_details(employment_details_input: $empDetailInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_BANK_DETAILS = gql`
    mutation saveBankDetails($bankDetailsInput: SaveBankDetails!) {
        save_customer_bank_details(bank_details_input: $bankDetailsInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_RESIDENCE_DETAILS = gql`
    mutation saveResidentialDetails($residenceDetailsInput: SaveResidentialDetails!) {
        save_customer_residential_details(residential_details_input: $residenceDetailsInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_CHARACTER_REF_DETAILS = gql`
    mutation saveCharacterRefDetails($characterRefInput: SaveCharacterRefDetails!) {
        save_customer_character_ref_details(character_ref_details: $characterRefInput) {
            success
            error
        }
    }
`;
