/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Modal from '../elements/Modal';
import { toast } from 'react-toastify';
// import RoleActions from "../../../store/action/RoleActions";
import AddUser from './AddUser';
import EditUser from './EditUser';
import { IS_USER_ACCESS_PAGE } from '../../config/constants';
// import secureStorage from '../../../config/encrypt';
import FormFields from './userList.json';
import RoleList from './roleList.json';
import { GET_ACTIVE_DEALER_LIST, GET_ROLE_LIST } from '../../services/role.gql';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { useApolloClient } from '@apollo/client';
import ConfirmPopUp from '../elements/ConfirmPopUp';
import { useDispatch } from 'react-redux';
import { GET_USER_LIST } from '../../services/role.gql';
import { UPDATE_STATUS } from '../../services/role.gql';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import Loader from '../elements/Loader';
import { GET_DEALER_LIST } from '../../services/masterdata.gql';

const UserList = (props) => {
    const client = useApolloClient();
    let [loading, setLoading] = useState(false);

    let [tabCount, setTabCount] = useState({});
    let [userList, setUserList] = useState([]);

    let [showModal, setShowModal] = useState(false);
    let [showEditModal, setShowEditModal] = useState(false);
    let [roleList, setRoleList] = useState([]);
    // let [roleDetails, setRoleDetails] = useState({});
    let [userDetails, setUserDetails] = useState({});

    let [activeTab, setActiveTab] = useState(1);
    let [searchText, setSearchText] = useState('');
    let [vertical_list, setVerticalList] = useState([]);
    let [allUserList, setAllUserList] = useState([]);
    let [isDeletedSameUser, setIsDeletedSameUser] = useState(false);

    let [showConfirmation, setShowConfirmation] = useState(false);
    let [idToDelete, setIdToDelete] = useState(0);
    let [dealerList, setDealerList] = useState([]);
    let [activeDealerList, setActiveDealerList] = useState([]);
    // const loginUserInfo = secureStorage.getItem('loginUserInfo');
    const loginUserInfo = JSON.parse(localStorage.getItem('user_information'));

    // const { auth_token, user_loggedin, userData } = useSelector((state) => {
    // 	return {
    // 		auth_token: state.authToken,
    // 		user_loggedin: state.loggedIn,
    // 		userData: state.userData
    // 	};
    // });
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            await getUserList({});
            await getRoleList();
            await getConfigData();
            await getAllUserList();
            await getDealerList();
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (activeTab === 1) {
            setTabCount((tabCount) => ({
                ...tabCount,
                count_all_user: userList.length,
            }));
        } else if (activeTab === 2) {
            setTabCount((tabCount) => ({
                ...tabCount,
                count_active_user: userList.length,
            }));
        } else if (activeTab === 3) {
            setTabCount((tabCount) => ({
                ...tabCount,
                count_inactive_user: userList.length,
            }));
        }
    }, [searchText]);

    const getUserList = async (params) => {
        setSearchText('');
        setLoading(true);
        setUserList([]);
        let user_list = [],
            tabCount = {};
        await executeGraphQLQuery(GET_USER_LIST(), client)
            .then((response) => {
                if (response && response.data && response.data.get_user_list) {
                    user_list = params.status
                        ? response.data.get_user_list.filter((obj) => obj.status == params.status)
                        : response.data.get_user_list;
                    tabCount = {
                        count_all_user: response.data.get_user_list.length,
                        count_active_user: response.data.get_user_list.filter((obj) => obj.status == '1').length,
                        count_inactive_user: response.data.get_user_list.filter((obj) => obj.status == '0').length,
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);
        setUserList(user_list);
        setTabCount(tabCount);
    };

    const getDealerList = async () => {
        setLoading(true);
        await executeGraphQLQuery(GET_DEALER_LIST(), client)
            .then(async (response) => {
                if (response && response.data && response.data.get_dealer_list) {
                    setDealerList(response.data.get_dealer_list?.dealer_list);
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const getConfigData = async () => {
        // const requestBody = {
        // 	"config_data": ['vertical_list'],
        // 	"dealer_id": props.dealer_id || 0,
        // 	"source": "WEB"
        // };
        setVerticalList([
            {
                value: 1,
                label: 'Carmudi',
            },
        ]);
        // await GeneralService.getConfig(requestBody).then((response) => {
        // 	if (response.status === 200 && response.data.status === 200) {
        // 		let verticalList = response.data.data.vertical_list.map(e => {
        // 			return {
        // 				value: e.vertical_id,
        // 				label: e.name
        // 			}
        // 		});
        // 		setVerticalList(verticalList)
        // 	}
        // }).catch((error) => { console.log(error); });
    };

    const deleteStatus = async (id) => {
        if (loginUserInfo && loginUserInfo.user_data && loginUserInfo.user_data.user_id === id)
            isDeletedSameUser = true;
        setIdToDelete(id);
        setShowConfirmation(true);
        setIsDeletedSameUser(isDeletedSameUser);
    };

    const closeModal = () => {
        setShowModal(false);
        setShowEditModal(false);
    };

    const updateList = async (userId) => {
        if (userId) await logoutUser(userId);
        closeModal();
        if (activeTab === 1) {
            await getUserList({});
        } else if (activeTab === 2) {
            await getUserList({ status: '1' });
        } else if (activeTab === 3) {
            await getUserList({ status: '0' });
        }
    };

    // updateRoleData = (details) => {
    // 	setState({ formTitle: 'EDIT_ROLE', userDetails: details, showModal: true })
    // }
    const getActiveDealerList = async (user_id = null) => {
        let dealer_list_arr = [];
        await executeGraphQLQuery(GET_ACTIVE_DEALER_LIST(), client)
            .then((response) => {
                if (response && response.data && response.data.get_user_dealer_list) {
                    if (user_id) {
                        dealer_list_arr = response.data.get_user_dealer_list
                            ?.filter((dealer) => dealer?.user_id !== +user_id)
                            ?.map((d) => d.id);
                    } else {
                        dealer_list_arr = response.data.get_user_dealer_list?.map((d) => d.id);
                    }
                    let final_res = dealerList.filter((dealer) => !dealer_list_arr.includes(+dealer.value));
                    setActiveDealerList(final_res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const showUpdateModal = async (data) => {
        await getActiveDealerList(data?.user_id);
        setShowEditModal(true);
        setUserDetails(data);
    };

    const addUserModal = async () => {
        await getActiveDealerList(null);
        getAllUserList();
        setUserDetails({});
        setShowModal(true);
    };

    const getRoleList = async () => {
        let data = [];
        await executeGraphQLQuery(GET_ROLE_LIST(), client)
            .then((response) => {
                if (response && response.data && response.data.get_role_list) {
                    data = response.data.get_role_list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        if (data) {
            roleList = data.map((e) => {
                return {
                    value: e.id,
                    label: e.name,
                };
            });
        }
        setRoleList([...roleList]);
    };

    const getAllUserList = async () => {
        let data = [];
        await executeGraphQLQuery(GET_USER_LIST(), client)
            .then((response) => {
                if (response && response.data && response.data.get_user_list) {
                    data = response.data.get_user_list.filter((obj) => obj.status == '1');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        if (data) {
            let user_list = [];
            user_list =
                data &&
                data.length &&
                data.map((e) => {
                    return {
                        value: e.user_id,
                        label: e.name,
                        role_ids: e.role_assigned_ids ? e.role_assigned_ids.split(',') : [],
                    };
                });
            setAllUserList([...user_list]);
        }
    };

    const searchUser = (event) => {
        let value = event.target.value;
        setSearchText(value);
    };

    const confirmYes = async () => {
        setLoading(true);
        try {
            const result = await executeGraphQLMutation(
                UPDATE_STATUS,
                { action_type: 'user', id: idToDelete, user_id: loginUserInfo?.user_data?.user_id || 0, status: '3' },
                client,
            );
            if (result) {
                toast.success(result?.data?.saveusers?.message || 'Success');
                await logoutUser(idToDelete);
                getUserList({});
            }
        } catch (error) {
            toast.error(error.toString().replace('ApolloError:', ''));
            console.error(error);
        }
        setLoading(false);
        confirmNo();
    };

    const confirmNo = () => {
        setShowConfirmation(false);
        setIsDeletedSameUser(false);
    };

    const logoutUser = async (userId) => {
        if (loginUserInfo && loginUserInfo.user_data && loginUserInfo.user_data.user_id === userId) {
            localStorage.removeItem('loginUserInfo');
            localStorage.removeItem('userAccess');
            localStorage.clear();
            window.location.href = '/login';
        }
    };

    if (searchText)
        userList = userList.filter((e) => {
            return (
                e.name.toLowerCase().includes(searchText.toLowerCase()) ||
                e.mobile.includes(searchText) ||
                e.email.toLowerCase().includes(searchText.toLowerCase())
            );
        });

    let isEditAccess = IS_USER_ACCESS_PAGE('user-management', 'user-management')?.is_edit_access;
    let isSuperAdmin = loginUserInfo && loginUserInfo.role_id.includes(1);
    isEditAccess = isSuperAdmin ? true : isEditAccess;

    return (
        <div className="tab-main-outer">
            <div className="tab-data">
                <div className="user-status-tab-chips">
                    <ul>
                        <li
                            className={activeTab === 1 ? 'active' : ''}
                            onClick={() => {
                                setActiveTab(1);
                                getUserList({});
                            }}
                        >
                            All User
                            <span>{tabCount.count_all_user}</span>
                        </li>
                        <li
                            className={activeTab === 2 ? 'active' : ''}
                            onClick={() => {
                                setActiveTab(2);
                                getUserList({ status: '1' });
                            }}
                        >
                            Active User
                            <span>{tabCount.count_active_user}</span>
                        </li>
                        <li
                            className={activeTab === 3 ? 'active' : ''}
                            onClick={() => {
                                setActiveTab(3);
                                getUserList({ status: '0' });
                            }}
                        >
                            Inactive User
                            <span>{tabCount.count_inactive_user}</span>
                        </li>
                    </ul>
                    <div className="search-user-add-btn">
                        <fieldset className="search-bx">
                            <div className="material">
                                <input
                                    type=""
                                    id=""
                                    placeholder="Search User"
                                    onChange={searchUser.bind(this)}
                                    value={searchText}
                                />
                                <i className="ic-search"></i>
                            </div>
                        </fieldset>
                        {!isEditAccess ? (
                            <button className="btn-line" disabled={true}>
                                Add User
                            </button>
                        ) : (
                            <button
                                className="btn-line"
                                onClick={() => {
                                    addUserModal();
                                }}
                            >
                                Add User
                            </button>
                        )}
                    </div>
                </div>
                <div className={loading ? 'loading' : ''}>
                    <div className="data-table user-managtable">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Mobile Number</th>
                                    <th>Email ID</th>
                                    {/* <th>Reporting To Role</th>
                                    <th>Reporting To Person</th> */}
                                    {/* <th>Vertical</th> */}
                                    <th>Role Assigned</th>
                                    <th>Status</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userList.length > 0
                                    ? userList.map((data, index) => (
                                        <React.Fragment key={data.user_id}>
                                            <tr key={index}>
                                                <td title={data.name}>{data.name || ''}</td>
                                                <td title={data.mobile}>{data.mobile || ''}</td>
                                                <td title={data.email}>{data.email}</td>
                                                {/* <td title={data.reporting_user_role_assigned}>
                                                    {data.reporting_user_role_assigned || ''}
                                                </td>
                                                <td title={data.reporting_user}>{data.reporting_user}</td> */}
                                                {/* <td>{data.vertical} */}
                                                {/* {data.vertical_id && vertical_list.length
													? vertical_list
														.filter(
															(
																v
															) =>
																v.value ==
																data
																	.vertical_id
														)
														.map(
															(
																v
															) =>
																v.label
														)[0] ||
													""
													: ""} */}
                                                {/* </td> */}
                                                <td>{data.role_assigned || ''}</td>
                                                <td>
                                                    <span
                                                        className={
                                                            Number(data.status) === 1
                                                                ? 'status-active'
                                                                : 'status-inactive'
                                                        }
                                                    >
                                                        {data.user_status}
                                                    </span>
                                                </td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn-edit-delete"
                                                        onClick={
                                                            isEditAccess ? showUpdateModal.bind(this, data) : null
                                                        }
                                                    >
                                                        Edit
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className="btn-edit-delete"
                                                        onClick={
                                                            isEditAccess
                                                                ? deleteStatus.bind(this, data.user_id)
                                                                : null
                                                        }
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))
                                    : !loading && (
                                        <tr>
                                            <td colSpan="9">
                                                <h3 className="t-danger t-center">
                                                    {userList && 'Record Not Found'}
                                                </h3>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {showModal === false ? (
                ''
            ) : (
                <div className="mark-lost-popup">
                    <Modal show={showModal} id="Updateform" handleClose={closeModal}>
                        <AddUser
                            user_id={loginUserInfo?.user_data?.user_id || 0}
                            updateList={updateList}
                            role_list={roleList}
                            vertical_list={vertical_list}
                            user_list={allUserList}
                            dealer_list={activeDealerList}
                        />
                    </Modal>
                </div>
            )}
            {showEditModal === false ? (
                ''
            ) : (
                <EditUser
                    user_id={loginUserInfo?.user_data?.user_id || 0}
                    updateList={updateList}
                    userDetails={userDetails}
                    closeModal={closeModal}
                    role_list={roleList}
                    vertical_list={vertical_list}
                    user_list={allUserList}
                    dealer_list={activeDealerList}
                />
            )}
            {showConfirmation === false ? (
                ''
            ) : (
                <ConfirmPopUp
                    confirmNo={confirmNo}
                    confirmYes={confirmYes}
                    message={isDeletedSameUser ? 'Are you sure you want to delete Yourself ?' : 'Are you sure ?'}
                />
            )}
            {/* {loading ? <Loader /> : null} */}
        </div>
    );
};

export default UserList;
