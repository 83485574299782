import React, { useEffect, useState } from 'react';
import AgentListTable from './AgentLIstTable';
import { Tab, Nav } from 'react-bootstrap';
import AgentListFilter from './AgentListTopFilter';
import { useApolloClient } from '@apollo/client';
import { AGENTS_QUERY } from '../../services/agentonboading.gql';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import AgentOnboarding from '../agent-onboarding/AgentOnboarding';
import { getBankList, getMasterData } from '../../store/action/allAction';
import DocsPreview from './DocsPreview';
import { IS_USER_ACCESS_PAGE } from "../../config/constants";

const AgentList = (props) => {
    const client = useApolloClient();
    const [agents, setAgents] = useState([]);
    const [agentId, setAgentId] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    let [paginationInput, setPaginationInput] = useState({ page: 1 });
    let [getAgentsInput, setGetLeadsInput] = useState({});

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let loginData = JSON.parse(localStorage.getItem('user_information'));

    const [agentOnboard, setAgentOnboard] = useState(false);
    const [agentDocs, setAgentDocs] = useState(false);

    const dispatch = useDispatch();
    const get_master_data = () => {
        dispatch(getMasterData(client));
    };

    const get_bank_list = () => {
        dispatch(getBankList(client));
    };

    useEffect(() => {
        get_master_data();
        get_bank_list();
        getAgentList({ getAgentsInput, paginationInput });
    }, []);

    const Modal = ({ handleClose, show, children }) => {
        const showHideClassName = show ? 'modal display-block' : 'modal display-none';

        return (
            <div className={showHideClassName}>
                <section className="modal-main">
                    {children}
                    <button onClick={handleClose} className="close_icn">
                        <i className="ic-clearclose"></i>
                    </button>
                </section>
            </div>
        );
    };

    const getAgentList = ({ getAgentsInput, paginationInput }) => {
        const filterData = {
            getAgentsInput,
            paginationInput,
            api_called_by: 'web',
            user_id: UserInfo?.id || 0,
            role_id: UserInfo?.role_id?.[0] || 0,
        };
        setIsLoading(true);
        executeGraphQLQuery(AGENTS_QUERY(), client, filterData)
            .then((result) => {
                setAgents(result?.data?.get_agents?.rows);
                setTotalPage(result?.data?.get_agents?.pagination?.total_page);
                setCurrentPage(result?.data?.get_agents?.pagination?.page_no || 1);
                setIsLoading(false);
            })
            .catch((errors) => {
                toast.error('Somethig went wrong ,please try again');
            });
    };
    const onPaginate = (p) => {
        paginationInput.page = p;
        setPaginationInput({ ...paginationInput });
        getAgentList({ getAgentsInput, paginationInput });
    };

    const handleSearch = (filters) => {
        getAgentsInput = { ...filters };
        paginationInput = { page: 1 };
        getAgentList({ getAgentsInput, paginationInput });
        setGetLeadsInput({ ...getAgentsInput });
        setPaginationInput({ ...paginationInput });
        document.getElementById('more-filter-dropdown').classList.remove('show');
    };

    const showModalAgentOnboard = () => {
        setAgentOnboard(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalAgentOnboard = () => {
        setAgentOnboard(false);
        document.body.classList.remove('overflow-hidden');
    };

    const showModalAgentDocs = () => {
        setAgentDocs(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalAgentDocs = () => {
        setAgentDocs(false);
        document.body.classList.remove('overflow-hidden');
    };

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="top-heading financer-lead-heding">
                    <h1>Agent Onboarding Panel</h1>
                    {IS_USER_ACCESS_PAGE('agent_onboarding', 'agent_onboarding').is_edit_access && (
                        <button className="btn-line" onClick={() => { setAgentId(0); showModalAgentOnboard(); }}>
                            <i className="ic-add"></i>
                            <span>Add Agent</span>
                        </button>)}
                </div>
                <div className="loan-filter-bg">
                    <div className="top-filter-bg">
                        <AgentListFilter
                            handleSearch={handleSearch}
                        />
                    </div>
                </div>
                <AgentListTable
                    agents={agents}
                    onPaginate={onPaginate}
                    totalPage={totalPage}
                    currentPage={currentPage}
                    isLoading={isLoading}
                    showModalAgentOnboard={showModalAgentOnboard}
                    hideModalAgentOnboard={hideModalAgentOnboard}
                    showModalAgentDocs={showModalAgentDocs}
                    setAgentId={setAgentId}
                />

                <div className="Add-agnet-popup">
                    <Modal show={agentOnboard} handleClose={() => hideModalAgentOnboard()}>
                        <div className="modal-header">
                            <h2>Add Agent</h2>
                        </div>
                        <div className="modal-body m-md-t h-300">
                            <AgentOnboarding getAgentList={getAgentList} hideModalAgentOnboard={hideModalAgentOnboard} getAgentsInput={getAgentsInput} paginationInput={paginationInput} agentId={agentId} agents={agents} />
                        </div>
                    </Modal>
                </div>
                {agentDocs && <div className="show-agnet-popup">
                    <Modal show={agentDocs} handleClose={() => hideModalAgentDocs()}>
                        <div className="modal-header">
                            <h2>Agent Docs</h2>
                        </div>
                        <div className="modal-body m-md-t">
                            <DocsPreview agentId={agentId} agents={agents} />
                        </div>
                    </Modal>
                </div>
                }
            </div>
        </React.Fragment>
    );
};

export default AgentList;
