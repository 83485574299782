import { gql } from '@apollo/client';
export const LEADS_QUERY = () => {
    return gql`
        query GetLeads(
            $getLeadsInput: GetLeadsInput!
            $paginationInput: PaginationInput!
            $orderByInput: OrderByInput!
            $api_called_by: API_CALL_BY!
            $user_id: Float!
            $role_id: Float!
        ) {
            get_leads(
                filters: $getLeadsInput
                paginationInput: $paginationInput
                orderByInput: $orderByInput
                api_called_by: $api_called_by
                user_id: $user_id
                role_id: $role_id
            ) {
                tabs {
                    label
                    count
                    type
                    filter_params {
                        action_type
                    }
                }
                rows {
                    id
                    user_details {
                        id
                        name
                    }
                    dealer_details {
                        organization
                        gcd_code
                    }
                    lead_assigned_agent {
                        id
                        agent_name
                        agent_code
                    }
                    mmv_name
                    dealer_name
                    created_by_source_id
                    created_by_source
                    lead_created_by_source
                    so_name
                    is_delayed
                    tat
                    id
                    loan_id
                    lead_source
                    service_type_id
                    service_type {
                        service_type
                    }
                    service_coverage {
                        name
                    }
                    service_coverage_id
                    status_id
                    payment_status
                    doc_status
                    dealer_id
                    so_id
                    sm_id
                    th_id
                    nh_id
                    lead_assigned_to
                    lead_assigned_date
                    updated_by
                    status
                    created_date
                    lead_detail {
                        id
                        lead_id
                        standard_ttp
                        inbound_lt_office_id
                        outbound_lt_office_id
                        agent_fee
                        is_rush_hour
                        stensil
                        created_date
                        updated_date
                        status
                    }
                    lead_vehicle_detail {
                        id
                        lead_id
                        make_id
                        model_id
                        variant_id
                        make_name
                        model_name
                        variant_name
                        other_make_name
                        other_model_name
                        other_variant_name
                        vehicle_usage
                        vehicle_type_id
                        vehicle_type {
                            name
                        }
                        plate_number
                        make_year
                        chassis_number
                        engine_number
                        fuel_type
                        car_color
                        remarks
                        area_id
                        created_date
                        updated_date
                        status
                    }
                    customer {
                        id
                        lead_id
                        name
                        email
                        mobile
                        house_no
                        street
                        region_id
                        city_id
                        brangey_id
                        postal_code
                        status
                        created_date
                    }
                    lead_status {
                        id
                        name
                    }
                }
                pagination {
                    total_page
                    total_records
                    prev_page
                    next_page
                    page_no
                }
            }
        }
    `;
};

export const LEADS_REMARK_QUERY = (lead_id) => gql`
query{
  get_remarks(lead_id:${lead_id}){
    remark
    created_date
    section
    sub_section
  	user_details{
      added_by_name
    }
  }
}
`;

export const LEADS_HISTORY_QUERY = (lead_id) => gql`
    query {
        get_lead_history(lead_id: ${lead_id}) {
            id
            lead_id
            status_id
            user_id
            created_date
            lead_status {
                id
                name
            }
            lead_sub_status {
                id
                sub_status_name
            }
            user_details {
                id
                name
            }
        }
    }
`;
export const LEAD_DETAIL_QUERY = (lead_id) => gql`
    query {
        lead_details(lead_id: ${lead_id}, api_called_by: web) {
            tab_status  {
                application_created {
                    is_active
                    is_completed
                }
                documents_uploaded {
                    is_active
                    is_completed
                }
                customer_details {
                    is_active
                    is_completed
                }
                vehicle_details {
                    is_active
                    is_completed
                }
                send_to_agent {
                    is_active
                    is_completed
                }
                status_details {
                    is_active
                    is_completed
                }
                payment_details {
                    is_active
                    is_completed
                }
                new_orcr {
                    is_active
                    is_completed
                }
                orcr_sent_to_dealer {
                    is_active
                    is_completed
                }
            }
            total_amount_paid_till_now
            total_amount_received_till_now
            latest_request_paid_date
            latest_request_paid_amount
            user_details {
                id
                name
                managers{
                    id
                    role_id
                    name
                }
            }
            lead_assigned_agent{
                id
                agent_name
            }
            dealer_details {
                organization
                gcd_code
            }
            lead_docs {
                id
                lead_id
                doc_id
                parent_doc_id
                doc_path
                doc_source
                description
                doc_status
            }
            dealer_name
            so_name
            is_delayed
            id
            loan_id
            lead_source
            created_by_source_id
            created_by_source
            service_type_id
            service_coverage_id
            service_type {
                service_type
            }
            service_coverage {
                name
            }
            status_id
            payment_status
            payment_paid_status
            doc_status
            dealer_id
            so_id
            sm_id
            th_id
            nh_id
            lead_assigned_to
            agent_id
            lead_agent_status
            lead_assigned_date
            updated_by
            status
            created_date
            lead_source_tagging
            lead_detail {
                id
                lead_id
                standard_ttp
                inbound_lt_office_id
                outbound_lt_office_id
                agent_fee
                sent_to_agent_date
                new_orcr_generated_date
                orcr_transfer_completed_date
                stensil
                created_date
                updated_date
                status
                mode_of_payment_id
                case_type
                car_under_financing
                financing_from
                financiers
                other_financiers
                owner_name
                owner_address
                inbound_lto_office{
                    lt_office_name
                }
                outbound_lto_office{
                    lt_office_name
                }
            }
            lead_vehicle_detail {
                id
                lead_id
                make_id
                model_id
                variant_id
                make_name
                model_name
                variant_name
                vehicle_usage
                vehicle_type_id
                vehicle_type {
                    id
                    name
                }
                plate_number
                make_year
                chassis_number
                engine_number
                fuel_type
                car_color
                remarks
                area_id
                or_expiry_date
                cr_expiry_date
                created_date
                updated_date
                status
                other_make_name
                other_model_name
                other_variant_name
            }
            customer {
                id
                lead_id
                name
                email
                mobile
                house_no
                street
                region_id
                city_id
                brangey_id
                postal_code
                status
                created_date
            }
            lead_status {
                id
                name
            }
            lead_sub_status {
                id
                sub_status_name
            }
            lead_docs {
                id
                lead_id
                doc_id
                parent_doc_id
                doc_path
                doc_source
                description
                doc_status
            }
            loan_status_sub_status{
                status_info{
                    id
                    name
                }
                sub_status_info{
                    id
                    name
                }
            }
            lead_status_history {
                status_id
                sub_status_id
            }
            lead_rating {
                id
                rating
                out_of
                remark
            }
        }
    }
`;

export const DOCUMENT_LIST = (financier_id, template_for) => gql`query{
  
  financierListbyID(financier_id:"${financier_id}",template_for:"${template_for}"){
    id
    financier_id
    template_type
    financier_group_name
    financier_group_email
    template_body
    financier_group_mobile
  }
}
`;

export const GET_SUB_STATUS = (status_ids) => gql`
  query{
    sub_status_list(status_ids:"${status_ids}"){
      substatuslang{
        sub_status_name,
        sub_status_id
      }
    }
  }`;

export const MARK_AS_LOST = gql`
    mutation markAsLost(
        $action_type: String!
        $lead_id: Float!
        $sub_status_id: Float!
        $comment: String!
        $user_id: Float!
    ) {
        markAsLost(
            action_type: $action_type
            lead_id: $lead_id
            status_id: 7
            sub_status_id: $sub_status_id
            remark: $comment
            user_id: $user_id
        ) {
            message
        }
    }
`;

export const GET_DOCUMENTS_LIST = () => gql`
    query {
        getDocumentList(api_called_by: web) {
            id
            parent_id
            tab_name
            doc_section
            doc_type
            is_co_borrower
            customer_type
            is_required
            is_required_app
            show_in_app
            min_upload
            max_upload
            doclang {
                name
                doc_id
            }
            child {
                id
                parent_id
                tab_name
                doc_section
                doc_type
                is_co_borrower
                customer_type
                is_required
                is_required_app
                show_in_app
                min_upload
                max_upload
                doclang {
                    name
                    doc_id
                }
            }
        }
    }
`;

export const SEND_DOC_TO_FINANCEIR = gql`
    mutation sendtemplatenotification($sendTemplateNotificationinput: SendTemplateNotificationInput!) {
        sendtemplatenotification(sendTemplateNotificationinput: $sendTemplateNotificationinput, api_called_by: "web") {
            message
        }
    }
`;

export const SAVE_COMPUTATION_DETAILS = gql`
    mutation saveLeadComputationDetails($leadComputationDetailsInput: LeadComputationDetailInput!) {
        saveLeadComputationDetails(leadComputationDetailsInput: $leadComputationDetailsInput) {
            id
        }
    }
`;

export const STATUS_QUERY = gql`
    query {
        status_list {
            statuslang {
                status_name
                status_id
            }
        }
        financier_list {
            financierlang {
                financier_name
                financier_id
            }
        }

        sub_status_list(status_ids: "") {
            status_id
            substatuslang {
                sub_status_name
                sub_status_id
            }
        }
    }
`;

export const UPDATE_LEAD_STATUS = gql`
    mutation updateleadstatus($updateleadstatus: UpdateLeadStatus!) {
        updateleadstatus(updateleadstatus: $updateleadstatus, api_called_by: "web") {
            message
        }
    }
`;

export const SAVE_CSV_DOWNLOAD_REQ = gql`
    mutation save_csv_download_req($applied_filters: String!, $user_id: Float!, $user_email: String!) {
        save_csv_download_req(applied_filters: $applied_filters, user_id: $user_id, user_email: $user_email) {
            message
        }
    }
`;
export const COMPUTATION_MASTER_DATA = (financier_id) => {
    return gql`query{
    computationMasterDetails(financier_id: ${financier_id}) {
      financier_id
      name
      from_year
      to_year
      interest_rate
      chattel_fee
      tenure_in_month
      processing_fee
      amount_financed_min
      amount_financed_max
      vehicle_category_id
      dealer_incentive_gross
      dealer_tax
      dealer_incentive_net
      carmudi_top_up_gross
      carmudi_tax
      carmudi_top_up_net
      total_gross_di
      di_tax
      total_net_di
      vat
      di_payment_from_financier_gross
      revenue
      di_payment_from_financier_tax
      di_payment_from_financier_net
    }
  }`;
};

export const SAVE_COMPUTATION_CONFIG = gql`
    mutation saveComputationConfig($fileComputationData: [CalculatorConfigInput!]!) {
        saveComputationConfig(updates: $fileComputationData) {
            id
        }
    }
`;

export const LTO_AGENT_SERVICE_CHARGE = gql`
    query Lto_agent_service_charge($lto_agent_service: GetLtoAgentServiceChargeDto!) {
        lto_agent_service_charge(lto_agent_service: $lto_agent_service) {
            id
            lto_office_agent_id
            service_id
            agent_fee
            status
            created_date
            updated_date
            lto_office_agent {
                id
                agent_id
                lt_office_id
                status
                created_date
                updated_date
            }
            service_details {
                id
                service_type
                standard_price
            }
        }
    }
`;

export const SEND_TO_AGENT = gql`
    mutation Send_to_agent($user_id: Float!, $role_id: Float!, $send_to_agent: SendToAgentDto!) {
        send_to_agent(user_id: $user_id, role_id: $role_id, send_to_agent: $send_to_agent) {
            message
        }
    }
`;

export const UPDATE_LEAD_ORCR = gql`
    mutation Update_lead_orcr(
        $update_lead_orcr: UpdateLeadOrcrDto!
        $api_called_by: API_CALL_BY!
        $user_id: Float!
        $role_id: Float!
    ) {
        update_lead_orcr(
            update_lead_orcr: $update_lead_orcr
            api_called_by: $api_called_by
            user_id: $user_id
            role_id: $role_id
        ) {
            message
        }
    }
`;
export const STATUS_PAYMENT_DATA = (lead_id, form_type) => {
    return gql`
        query {
            get_status_payment_details(lead_id: ${lead_id}, form_type: ${form_type}) {
                id
                lead_id
                lead_doc_id
                title
                status_stage_id
                followup_date
                payment_type
                payment_request_date
                payment_paid_date
                payment_received_date
                payment_amount
                created_by
                history_type
                created_date
                updated_date
                status
                doc_path
                remark
                stage_name
            }
        }
    `;
};

export const UPDATE_LEAD_STATUS_DATA = gql`
    mutation updateLeadStatus($lead_id: Float!, $status_id: Float!, $sub_status_id: Float!, $created_by: Float!) {
        update_lead_status(
            lead_id: $lead_id
            status_id: $status_id
            sub_status_id: $sub_status_id
            created_by: $created_by
        ) {
            message
        }
    }
`;

export const GET_DOCUMENT_CATEGORY_LIST = (service_type_id) => {
    return gql`
        query {
            get_document_list(api_called_by: web, service_type_id: ${service_type_id}) {
                id
                name
                min_upload
                max_upload
                parent_id
                is_required
                show_in_app
                doc_icon_url
                bg_color_color
                sort_number
                status
                child {
                    id
                    parent_id
                    name
                    max_upload
                }
                service_type_ids
            }
        }
    `;
};

export const UPDATE_LEAD_ASSIGNED_TO = () => {
    return gql`
        mutation Update_lead_assigned_to(
            $lead_id: Float!
            $lead_assigned_to: Float!
            $api_called_by: API_CALL_BY!
            $user_id: Float!
            $role_id: Float!
        ) {
            update_lead_assigned_to(
                lead_id: $lead_id
                lead_assigned_to: $lead_assigned_to
                api_called_by: $api_called_by
                user_id: $user_id
                role_id: $role_id
            ) {
                message
            }
        }
    `;
};



export const LEADS_QUERY_ORCR = () => {
    return gql`
        mutation GetLeads(
            $getLeadsInput: GetLeadInput!
            $paginationInput: PaginationInput!
            $api_called_by: API_CALL_BY!
            $user_id: Float!
            $role_id: Float!
        ) {
            orcr_lead_list(
                filters: $getLeadsInput
                paginationInput: $paginationInput
                api_called_by: $api_called_by
                user_id: $user_id
                role_id: $role_id
            ) {
                rows{
                        id
                        if_id
                        dealer_id
                        status_id
                        dealer_name
                        gcd_code
                        lead_assigned_to
                        created_date
                        lead_detail{
                            make_name
                            model_name
                            variant_name
                            plate_number
                            make_year
                            reject_reason
                            agent_fee
                        }
                        lead_docs{
                            id
                            doc_id
                            doc_path
                            doc_name
                            doc_source
                        }
                    }
                    tabs{
                        label
                        count
                        type
                        filter_params{
                            status_id
                            lead_assigned_to
                            action_type
                        }
                    }
                    pagination{
                        total_page
                        total_records
                        prev_page
                        next_page
                        page_no
                    }
            }
        }
    `;
};


export const UPDATE_LEAD_ASSIGNED_TO_ORCR = () => {
    return gql`
        mutation Update_lead_assigned_to_ORCR(
            $lead_id: Float!
            $lead_assigned_to: Float!
            $api_called_by: API_CALL_BY!
            $user_id: Float!
            $role_id: Float!
        ) {
            update_lead_assigned_to_orcr(
                lead_id: $lead_id
                lead_assigned_to: $lead_assigned_to
                api_called_by: $api_called_by
                user_id: $user_id
                role_id: $role_id
            ) {
                message
            }
        }
    `;
};

export const UPDATE_AGENT_FEE_AND_DOC_ORCR = () => {
    return gql`
            mutation update_agent_fee_and_docs($updateagentinput: UpdateAgentFeeInput!,$api_called_by: API_CALL_BY!,$user_id: Float!) {
                update_agent_fee_and_docs(updateagentinput: $updateagentinput, api_called_by: $api_called_by,user_id:$user_id) {
                    message
                }
            }
    `;
};

export const UPDATE_VERIFICATION_STATUS_ORCR = () => {
    return gql`
            mutation update_verification_and_docs($updateVerificationDocs:   UpdateVerificationDocs!,$api_called_by: API_CALL_BY!,$user_id: Float!) {
                update_verification_and_docs(updateVerificationDocs: $updateVerificationDocs, api_called_by: $api_called_by,user_id:$user_id) {
                    message
                }
            }
    `;
};


export const LEADS_QUERY_ORCR_EXPORT = () => {
    return gql`
        mutation getExport($getLeadsInput: GetLeadInput!,$user_id: Float!){
            orcr_lead_list_export(filters: $getLeadsInput,user_id: $user_id){
                report_data
            }
        }
    `;
};


export const PH_LEADS_QUERY = () => {
    return gql`query Get_ucf_leads {
        get_ucf_leads(api_called_by: web) {
            id
        }
    }
`;
};

export const PH_LEAD_DETAILS = (lead_id) => {
    return gql`query Get_ucf_lead_detail {
        get_ucf_lead_detail(api_called_by: web, lead_id: ${lead_id}) {
            id
            so_id
            sm_id
            th_id
            nh_id
            dealer_id
            name
            mobile
            email
            house_no
            street
            brangey_id
            city_id
            region_id
            postal_code
            plate_number
            make_id
            model_id
            variant_id
            make_name
            model_name
            variant_name
            make_year
            vehicle_type_id
            other_make_name
            other_model_name
            other_variant_name
            other_vehicle_type
            car_color
        }
    }
`;
};

export const ADD_LEAD = () => {
    return gql`mutation CreateLead( $api_called_by: API_CALL_BY!, $createLeadInput: CreateLeadInput!, $user_id: Float!, $role_id: Float!) {
        create_lead(user_id: $user_id, role_id: $role_id, api_called_by: $api_called_by, createLeadInput: $createLeadInput) {
            id
            message
            is_duplicate
        }
    }`;
};


export const LEADS_QUERY_UCF_ORCR = () => {
    return gql`
        mutation GetLeads(
            $GetUCFLeadInput: GetUCFLeadInput!
            $paginationInput: PaginationInput!
            $api_called_by: API_CALL_BY!
            $user_id: Float!
            $role_id: Float!
        ) {
            ucf_orcr_lead_list(
                filters: $GetUCFLeadInput
                paginationInput: $paginationInput
                api_called_by: $api_called_by
                user_id: $user_id
                role_id: $role_id
            ) {
                rows{
                        id
                        loan_id
                        dealer_id
                        status_id
                        dealer_name
                        gcd_code
                        lead_assigned_to
                        created_date
                        lead_detail{
                            make_name
                            model_name
                            variant_name
                            plate_number
                            reject_reason
                            agent_fee
                            make_year
                            other_reject_reason
                        }
                        lead_docs{
                            id
                            doc_id
                            doc_path
                            doc_name
                            doc_source
                        }
                    }
                    tabs{
                        label
                        count
                        type
                        filter_params{
                            status_id
                            lead_assigned_to
                            action_type
                        }
                    }
                    pagination{
                        total_page
                        total_records
                        prev_page
                        next_page
                        page_no
                    }
            }
        }
    `;
};


export const UPDATE_LEAD_ASSIGNED_TO_UCF_ORCR = () => {
    return gql`
        mutation Update_lead_assigned_to_UCF_ORCR(
            $lead_id: Float!
            $lead_assigned_to: Float!
            $api_called_by: API_CALL_BY!
            $user_id: Float!
            $role_id: Float!
        ) {
            ucf_update_lead_assigned_to_orcr(
                lead_id: $lead_id
                lead_assigned_to: $lead_assigned_to
                api_called_by: $api_called_by
                user_id: $user_id
                role_id: $role_id
            ) {
                message
            }
        }
    `;
};

export const UPDATE_AGENT_FEE_AND_DOC_UCF_ORCR = () => {
    return gql`
            mutation ucf_update_agent_fee_and_docs($updateagentinput: UpdateUCFAgentFeeInput!,$api_called_by: API_CALL_BY!,$user_id: Float!) {
                ucf_update_agent_fee_and_docs(updateagentinput: $updateagentinput, api_called_by: $api_called_by,user_id:$user_id) {
                    message
                }
            }
    `;
};

export const UPDATE_VERIFICATION_STATUS_UCF_ORCR = () => {
    return gql`
            mutation ucf_update_verification_and_docs($UpdateVerificationUCFDocs:   UpdateVerificationUCFDocs!,$api_called_by: API_CALL_BY!,$user_id: Float!) {
                ucf_update_verification_and_docs(UpdateVerificationUCFDocs: $UpdateVerificationUCFDocs, api_called_by: $api_called_by,user_id:$user_id) {
                    message
                }
            }
    `;
};


export const LEADS_QUERY_ORCR_UCF_EXPORT = () => {
    return gql`
        mutation getExport($getLeadsInput: GetUCFLeadInput!,$user_id: Float!){
            ucf_orcr_lead_list_export(filters: $getLeadsInput,user_id: $user_id){
                report_data
            }
        }
    `;
};