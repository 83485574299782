/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
// import { Redirect } from "react-router-dom";
import Select from 'react-select';
import { FeatureFunction, AssignMoreFeatures } from './FeatureList';
import _ from 'lodash';
import ConfirmPopUp from '../elements/ConfirmPopUp';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../elements/Loader';
import FormFields from './userList.json';
import FeatureList from './featureList.json';
import { useApolloClient } from '@apollo/client';
import { GET_ACTIVE_DEALER_LIST, GET_FEATURE_LIST } from '../../services/role.gql';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { GET_USER_LIST } from '../../services/role.gql';
import { SAVE_EDIT_USER } from '../../services/role.gql';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { InputOption, MultiSelectValueContainer } from '../elements/MultiselectCustomComponent';

const EditUser = (props) => {
    const client = useApolloClient();
    let [errors, setErrors] = useState({});
    let [formData, setFormData] = useState({ ...(props.userDetails || {}) });
    let [user_list, setUserList] = useState(props.user_list || []);
    let [role_list, setRoleList] = useState(props.role_list || []);
    let [userDetails, setUserDetails] = useState(props.userDetails || {});

    let [vertical_list, setVerticalList] = useState(props.vertical_list || []);
    // let [redirect, setRedirect] = useState('');
    let [assigned_feature_list, setAssignedFeatureList] = useState([]);
    let [additional_feature_list, setAdditionalFeatureList] = useState([]);
    let [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const mobile_regex = /^9\d{9}$/;
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const password_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const userInfo = useSelector((state) => state.user.User_information);
    const userId = (userInfo && userInfo.user_id) || 0;
    // const { userData } = useSelector((state) => {
    // 	return {
    // 		auth_token: state.authToken,
    // 		user_loggedin: state.loggedIn,
    // 		userData: state.user.authUser
    // 	};
    // });
    // const userId = (userData && userData.user_id) || 0
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            await getUserList();
            await getFeatureList();
            if (formData && formData.role_assigned_ids) {
                let role_ids = formData.role_assigned_ids.split(',');
                if (role_ids.length) role_ids = role_ids.map((e) => Number(e));
                formData['role_ids'] = role_ids;
                setFormData({ ...formData });
            }
            if (formData && formData.dealer_ids) {
                let dealer_id_arr = formData.dealer_ids.split(',');
                if (dealer_id_arr.length) dealer_id_arr = dealer_id_arr.map((e) => Number(e));
                formData['dealer_ids'] = dealer_id_arr;
                setFormData({ ...formData });
            }
        };
        fetchData();
    }, []);

    const handleChange = async (key, event, index) => {
        let value = '';
        let target = event.target;
        if (key === 'additional_feature_id') {
            let isKeyExist = _.findKey(additional_feature_list, function (v) {
                return Number(v.value) === Number(index);
            });
            if (isKeyExist)
                additional_feature_list[+isKeyExist].isChecked = !additional_feature_list[isKeyExist].isChecked;
        }
        if (key === 'additional_feature_radio') {
            let isKeyExist = _.findKey(additional_feature_list, function (v) {
                return Number(v.value) === Number(index);
            });
            if (isKeyExist)
                additional_feature_list[Number(isKeyExist)].access_type =
                    Number(additional_feature_list[isKeyExist].access_type) === 1 ? 0 : 1;
        }
        if (key === 'status') {
            value = formData.status === '1' ? '0' : '1';
            showConfirmDialog(value);
        } else if (event instanceof Array) value = event.map((e) => e.value);
        else if (target) value = target.value;
        else if (event) value = event.value;
        if (key === 'reporting_role_id') formData.reporting_user_id = '';
        formData[key] = value;
        errors[key] = '';

        setErrors({ ...errors });
        setAdditionalFeatureList([...additional_feature_list]);
        setFormData({ ...formData });
    };

    const isFormValid = async (formData) => {
        let isValid = true;
        if (!formData.name || formData.name === '') {
            errors['name'] = 'Field Required';
            isValid = false;
        } else if (
            formData &&
            formData.name &&
            (formData.name.split('').length < 3 || formData.name.split('').length > 30)
        ) {
            errors['name'] = 'Invalid User Name';
            isValid = false;
        }
        if (!formData.mobile || formData.mobile === '') {
            errors['mobile'] = 'Field Required';
            isValid = false;
        } else if (!mobile_regex.test(formData.mobile)) {
            errors['mobile'] = 'Invalid Mobile';
            isValid = false;
        }
        if (!formData.email || formData.email === '') {
            errors['email'] = 'Field Required';
            isValid = false;
        } else if (!email_regex.test(formData.email)) {
            errors['email'] = 'Invalid Email';
            isValid = false;
        }
        if (formData.password && !password_regex.test(formData.password)) {
            errors['password'] =
                'Password should contain atleast 8 character with 1 special char , 1 Uppercase , 1 lowercase and 1 digit';
            isValid = false;
        }
        // if (!formData.reporting_user_id || formData.reporting_user_id === '') {
        //     errors['reporting_user_id'] = 'Field Required';
        //     isValid = false;
        // }
        if (!formData?.role_ids?.length) {
            errors['role_ids'] = 'Field Required';
            isValid = false;
        }
        if (formData?.role_ids?.includes(2) && !formData?.dealer_ids?.length) {
            errors['dealer_ids'] = 'Field Required';
            isValid = false;
        }
        setErrors({ ...errors });
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let postData = await getFormData();
        if (await isFormValid(postData)) {
            setLoading(true);
            const variables = {
                action_type: 'edit',
                SaveUserInput: {
                    id: postData.id || 0,
                    email: postData.email || '',
                    mobile: postData.mobile || '',
                    name: postData.name || '',
                    // reporting_user_id: postData.reporting_user_id || '',
                    status: postData.status,
                },
                created_by: props.user_id,
                role_ids: postData.role_ids,
                dealer_ids: postData.dealer_ids || [],
            };

            if (postData.password != '' || postData.password != null)
                variables.SaveUserInput = { ...variables.SaveUserInput, password: postData.password };

            try {
                const result = await executeGraphQLMutation(SAVE_EDIT_USER, variables, client);
                if (result) {
                    let updatedId = postData && postData.id ? postData.id : 0;
                    toast.success(result?.data?.saveusers?.message || 'Success');
                    setFormData({});
                    props.updateList(updatedId);
                }
            } catch (error) {
                formData.status = '1';
                setFormData(formData);
                toast.error(error.toString().replace('ApolloError:', ''));
                console.error(error);
            }
            setLoading(false);
        }
    };

    const getUserList = async () => {
        let response_data = [];
        await executeGraphQLQuery(GET_USER_LIST(), client)
            .then((response) => {
                if (response && response.data && response.data.get_user_list) {
                    response_data = response.data.get_user_list.filter((obj) => obj.status == '1');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        // let response_data = await dispatch(RoleActions.getUserList({ apply_pagination: false, status: '1' }));
        if (response_data) {
            let userList = [],
                assignedUser = [];
            if (formData.user_assigned_ids) assignedUser = formData.user_assigned_ids.split(',');
            userList =
                response_data &&
                response_data.length &&
                response_data.map((e) => {
                    let isChecked = assignedUser.length ? assignedUser.includes(String(e.value)) : false;
                    return {
                        value: e.user_id,
                        label: e.name,
                        isChecked: isChecked,
                        role_ids: e.role_assigned_ids ? e.role_assigned_ids.split(',') : [],
                    };
                });
            setUserList([...userList]);
        }
    };

    const getFeatureList = async () => {
        let response_data = [];
        await executeGraphQLQuery(GET_FEATURE_LIST(), client)
            .then((response) => {
                if (response && response.data && response.data.get_feature_list) {
                    response_data = response.data.get_feature_list;
                }
            })
            .catch((error) => {
                console.log(error);
            });

        if (response_data.length) {
            let featureList = [],
                assigned_feature = [],
                assigned_feature_list = [],
                assigned_additional_feature = [],
                additional_feature_list = [];
            if (formData.feature_assigned_ids) assigned_feature = formData.feature_assigned_ids.split(',');

            featureList =
                response_data.length &&
                response_data.map((e) => {
                    let isChecked = assigned_feature.length ? assigned_feature.includes(String(e.id)) : false;
                    if (isChecked) assigned_feature_list.push(e);
                    else {
                        let isAdditionalFeaturesExist =
                            formData.additional_feature_ids && formData.additional_feature_ids.length
                                ? _.find(formData.additional_feature_ids, { id: String(e.id) })
                                : false;
                        additional_feature_list.push({
                            value: e.id,
                            label: e.name,
                            access_type: isAdditionalFeaturesExist ? +isAdditionalFeaturesExist.access_type : 0,
                            isChecked: isAdditionalFeaturesExist ? true : false,
                        });
                    }
                    return {
                        value: e.id,
                        label: e.name,
                        isChecked: isChecked,
                    };
                });
            setAssignedFeatureList([...assigned_feature_list]);
            setAdditionalFeatureList([...additional_feature_list]);
        }
    };

    const getFormData = async () => {
        let postData = {};
        if (formData && formData.user_id) postData['id'] = formData.user_id;
        if (formData && formData.name) postData['name'] = formData.name;
        if (formData && formData.email) postData['email'] = formData.email;
        if (formData && formData.password) postData['password'] = formData.password;
        if (formData && formData.mobile) postData['mobile'] = formData.mobile;
        if (formData && formData.role_ids) postData['role_ids'] = formData.role_ids;
        if (formData && formData.status) postData['status'] = formData.status;
        if (formData && formData.reporting_user_id) postData['reporting_user_id'] = formData.reporting_user_id;
        if (formData && formData.vertical_id) postData['vertical_id'] = formData.vertical_id;
        if (formData && formData.dealer_ids) postData['dealer_ids'] = formData.dealer_ids;
        postData['dialer_id'] = formData.dialer_id || '';
        postData['additional_feature_data'] = additional_feature_list.length
            ? additional_feature_list
                .filter((f) => f.isChecked)
                .map((e) => {
                    return { id: e.value, access_type: String(e.access_type) };
                })
            : [];
        return postData;
    };

    const showConfirmDialog = (status) => {
        if (formData && status === '0' && userId === formData.user_id) setShowConfirmation(true);
    };

    const confirmYes = async () => {
        setShowConfirmation(false);
    };

    const confirmNo = () => {
        formData.status = formData.status === '1' ? '0' : '1';
        setShowConfirmation(false);
        setFormData({ ...formData });
    };

    //render() {
    // if (redirect) {
    //     return <Redirect to={redirect} />;
    // }
    if (userDetails && userDetails.user_id) user_list = user_list.filter((e) => e.value !== userDetails.user_id);
    if (formData && formData.reporting_role_id) {
        user_list = user_list.filter((e) => {
            if (e.role_ids.includes(String(formData.reporting_role_id))) return e;
        });
    }
    return (
        <div className="edit-role-outer usermanagemnt-sec-tab">
            <div className="edit-user">
                <h2>Edit User</h2>
                <div className="edit-user-form">
                    <fieldset className="form-filed">
                        <div className="material">
                            <input
                                id="name"
                                type="text"
                                placeholder=" "
                                name="name"
                                className="form-input"
                                onChange={handleChange.bind(this, 'name')}
                                value={formData.name || ''}
                            />
                            <label data-label="User Name" className="form-label"></label>
                            <span className="error-msg">{errors.name || ''}</span>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed">
                        <div onClick={handleChange.bind(this, 'status')} className="ToggleSwitch">
                            <div
                                className={
                                    formData && formData.status && Number(formData.status) === 1
                                        ? 'knob active'
                                        : 'knob'
                                }
                            />
                            <div className="toggle-status">
                                {formData && formData.status && Number(formData.status) === 1 ? 'Active' : 'Inactive'}
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed">
                        <div className="material">
                            <input
                                id="mobile"
                                type="text"
                                placeholder=" "
                                name="RoleName"
                                className="form-input"
                                onChange={handleChange.bind(this, 'mobile')}
                                value={formData.mobile || ''}
                                disabled={true}
                            />
                            <label data-label="Mobile Number" className="form-label"></label>
                            <span className="error-msg">{errors.mobile || ''}</span>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed">
                        <div className="material">
                            <input
                                id="email"
                                type="text"
                                placeholder=" "
                                name="RoleName"
                                className="form-input"
                                onChange={handleChange.bind(this, 'email')}
                                value={formData.email || ''}
                                disabled={true}
                            />
                            <label data-label="Email" className="form-label"></label>
                            <span className="error-msg">{errors.email || ''}</span>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed">
                        <div className="material">
                            <input
                                id="password"
                                type="text"
                                placeholder=" "
                                name="password"
                                className="form-input"
                                onChange={handleChange.bind(this, 'password')}
                                value={formData.password || ''}
                            />
                            <label data-label="Password" className="form-label"></label>
                            <span className="error-msg">{errors.password || ''}</span>
                        </div>
                    </fieldset>

                    {/* <fieldset className="form-filed single-select">
                        <div className="material select-list">
                            <label data-label="Reporting Role" className="form-label"></label>
                            <li className="searchitems selectWidth form-group">
                                <Select
                                    components={{ Option: InputOption, ValueContainer: MultiSelectValueContainer }}
                                    isMulti
                                    placeholderButtonLabel="Reporting Role"
                                    placeholder="Reporting Role"
                                    value={
                                        formData &&
                                        role_list.filter(({ label }) => formData.reporting_user_role_assigned.split(",").includes(label))
                                    }
                                    onChange={handleChange.bind(this, 'reporting_role_id')}
                                    options={role_list}
                                    getOptionLabel={({ label }) => label}
                                    getOptionValue={({ value }) => value}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    backspaceRemovesValue={false}
                                    isSearchable="true"
                                    isClearable={false}
                                    className="react-select react-multiselect-opt"
                                    classNamePrefix="react-select"
                                />
                            </li>
                            <span className="error-msg">{errors.role_ids || ''}</span>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed">
                        <div className="material select-list form-input">
                            <label data-label="Reporting To" className="form-label"></label>
                            <div className="material select-list">
                                <Select
                                    id="reporting_user_id"
                                    options={user_list}
                                    name="reporting_user_id"
                                    onChange={handleChange.bind(this, 'reporting_user_id')}
                                    value={
                                        formData &&
                                        user_list.filter(({ value }) => value === formData.reporting_user_id)
                                    }
                                    placeholder="Reporting Name"
                                />
                                <span className="error-msg">{errors.reporting_user_id || ''}</span>
                            </div>
                        </div>
                    </fieldset> */}
                    <fieldset className="form-filed">
                        <div className="material select-list form-input">
                            <label data-label="Vertical" className="form-label"></label>
                            <div className="material select-list">
                                <Select
                                    id="vertical_id"
                                    options={vertical_list}
                                    name="vertical_id"
                                    onChange={handleChange.bind(this, 'vertical_id')}
                                    value={{ value: 1, label: 'Carmudi' }}
                                    placeholder="Vertical Name"
                                    isDisabled={true}
                                />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="form-filed single-select">
                        <div className="material select-list">
                            <label data-label="Role Assigned" className="form-label"></label>
                            <li className="searchitems selectWidth form-group">
                                <Select
                                    components={{ Option: InputOption, ValueContainer: MultiSelectValueContainer }}
                                    isMulti
                                    placeholderButtonLabel="Add Role"
                                    placeholder="Assign Role"
                                    value={
                                        (role_list instanceof Array &&
                                            formData.role_ids &&
                                            role_list.filter((e) =>
                                                [
                                                    ...(formData.role_ids instanceof Array ? formData.role_ids : []),
                                                ].includes(e.value),
                                            )) ||
                                        []
                                    }
                                    onChange={handleChange.bind(this, 'role_ids')}
                                    options={role_list}
                                    getOptionLabel={({ label }) => label}
                                    getOptionValue={({ value }) => value}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    backspaceRemovesValue={false}
                                    isSearchable="true"
                                    isClearable={false}
                                    className="react-select react-multiselect-opt"
                                    classNamePrefix="react-select"
                                />
                            </li>
                            <span className="error-msg">{errors.role_ids || ''}</span>
                        </div>
                    </fieldset>

                    {
                        formData?.role_ids?.includes(2) ?
                            <fieldset className="form-filed single-select">
                                <div className="material select-list">
                                    <label data-label="Dealer Assigned" className="form-label"></label>
                                    <li className="searchitems selectWidth form-group">
                                        <Select
                                            components={{ Option: InputOption, ValueContainer: MultiSelectValueContainer }}
                                            isMulti
                                            placeholderButtonLabel="Add Dealer"
                                            placeholder="Assign Dealer"
                                            value={
                                                (props?.dealer_list instanceof Array &&
                                                    formData.dealer_ids &&
                                                    props?.dealer_list.filter((e) =>
                                                        [
                                                            ...(formData.dealer_ids instanceof Array
                                                                ? formData.dealer_ids
                                                                : []),
                                                        ].includes(e.value),
                                                    )) ||
                                                []
                                            }
                                            onChange={handleChange.bind(this, 'dealer_ids')}
                                            options={props?.dealer_list}
                                            getOptionLabel={({ label }) => label}
                                            getOptionValue={({ value }) => value}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            backspaceRemovesValue={false}
                                            isSearchable="true"
                                            isClearable={false}
                                            className="react-select react-multiselect-opt"
                                            classNamePrefix="react-select"
                                        />
                                    </li>
                                    <span className="error-msg">{errors.dealer_ids || ''}</span>
                                </div>
                            </fieldset> : null
                    }
                </div>
                {assigned_feature_list.length ? (
                    <div className="feature-assign">
                        <FeatureFunction assigned_feature_list={assigned_feature_list} userData={formData} />
                    </div>
                ) : null}
                {/* <div className="assign-more-feature-sec">
                        <AssignMoreFeatures additional_feature_list={additional_feature_list} handleChange={handleChange} />

                    </div> */}
                <button className="btn-primary m-md-t m-sm-r" onClick={handleSubmit}>
                    Save Changes
                </button>
                <a className="close-icn" onClick={props.closeModal}>
                    <i className="ic-clearclose"></i>
                </a>
            </div>
            {showConfirmation === false ? (
                ''
            ) : (
                // <ModalPopup id="Updateform" title={t("loanBoxLang.CONFIRM")} className="modal modal-xl" modalClose={confirmNo}>
                <ConfirmPopUp
                    confirmNo={confirmNo}
                    confirmYes={confirmYes}
                    message="Are you sure you want to Inactive Yourself"
                />
                // </ModalPopup>
            )}
            {loading ? <Loader /> : null}
        </div>
    );
};

export default EditUser;
