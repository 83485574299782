import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import Pagination from '../elements/pagination';
import Loader from '../elements/Loader';
import Modal from '../elements/Modal';
import LeadHistory from './LeadHistory';
import LeadRemarkHistory from './LeadRemarkHistory';
import { LEADS_HISTORY_QUERY, UPDATE_AGENT_FEE_AND_DOC_ORCR, UPDATE_LEAD_ASSIGNED_TO, UPDATE_LEAD_ASSIGNED_TO_ORCR, UPDATE_VERIFICATION_STATUS_ORCR } from '../../services/leads.gql';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { useApolloClient } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLeadRemarks } from '../../store/action/allAction';
import { GET_USER_LIST } from '../../services/role.gql';
import { toast } from 'react-toastify';
import crypto from '../../config/crypto';
import moment from 'moment';
import LeadVerification from './LeadVerification';
import DocsPreviewORCR from './DocsPreviewORCR';
import UploadAgentFee from './UploadAgentFee';
import { CHECK_ASSIGN_USER, TITLE_BOX_DIF_STATUS } from '../../config/constants';

const LeadListTable = ({ leads, t, isLoading, onPaginate, totalPage, currentPage, sortleadlist, getLeadList, getLeadsInput, paginationInput, statuses }) => {
    const [isviewHistory, setIsviewHistory] = useState('');
    const [isviewRemarks, setIsviewRemarks] = useState('');
    const [historyList, setHistoryList] = useState([]);
    const [remarkList] = useState([]);
    const [showhistorymodal, setShowhistorymodal] = useState(false);
    const [showORCRmodel, setShowORCRmodel] = useState(false);
    const [showAgentFeeModel, setShowAgentFeeModel] = useState(false);
    const [activeLead, setActiveLead] = useState({});
    const [orcrDocs, setOrcrDocs] = useState([]);
    const [showremarkform] = useState(false);
    const [leadList, setLeadList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [showVerificationModel, setShowVerificationModel] = useState(false);
    const client = useApolloClient();
    let { remarksHistory, opsExecutiveList } = useSelector(({ lead, masterdata }) => {
        return {
            remarksHistory: lead.remarksHistory || [],
            opsExecutiveList: masterdata.opsExecutiveList?.map(({ id, label }) => ({ value: id, label })) || [],
        };
    });
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    if (UserInfo?.role_id.length === 1 && UserInfo?.role_id.includes(2)) opsExecutiveList = opsExecutiveList.filter(data => data?.value === UserInfo?.id);
    useEffect(() => {
        const fetchData = async () => {
            await getUserList();
        };
        fetchData();
    }, []);

    useEffect(() => {
        setLeadList(leads);
    }, [leads]);
    useEffect(() => {
        if (remarksHistory.length && isviewRemarks !== '') {
            setIsviewRemarks('');
        }
    }, [remarksHistory, isviewRemarks]);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const toggleModalBox = (lead) => {
        if (Object.keys(lead).length) {
            setIsviewHistory(lead.id);
            setShowhistorymodal(true);
            executeGraphQLQuery(LEADS_HISTORY_QUERY(lead.id), client)
                .then((result) => {
                    if (result?.data?.get_lead_history) {
                        setIsviewHistory('');
                        setHistoryList(result?.data?.get_lead_history);
                    }
                })
                .catch((error) => {
                    console.error('Query error:', error);
                });
        } else {
            setShowhistorymodal(false);
        }
    };

    const toggleModalBoxremark = (lead) => {
        if (Object.keys(lead).length) {
            setIsviewRemarks(lead.id);
            setShowORCRmodel(true);
            dispatch(getLeadRemarks({ lead_id: lead.id, source: 'WEB' }, client));
            setIsviewRemarks('');
        } else {
            setIsviewRemarks('');
            setShowORCRmodel(false);
            setShowAgentFeeModel(false);
            setOrcrDocs([]);
        }
    };

    const getUserList = async () => {
        let user_list = [],
            userlist = [];
        await executeGraphQLQuery(GET_USER_LIST(5, 6), client)
            .then((response) => {
                if (response && response.data && response.data.get_user_list) {
                    user_list = response.data.get_user_list.filter((obj) => obj.status === '1');
                    user_list &&
                        user_list.map((v) => {
                            userlist.push({ value: v.user_id, label: v.name, role_id: v.role_assigned_ids });
                            return v;
                        });
                    setUserList(userlist);
                }
            })
            .catch((error) => { });
    };

    const assignToUser = (e, lead_id) => {
        if (!e.value) return;
        if (!window.confirm('Are you sure want to assign?')) return;
        let index = leadList.findIndex((data) => data.id === +lead_id);
        if (leadList?.[index]?.lead_assigned_to === e?.id) {
            toast.error('Loan ID already assigned to this User.');
            return;
        }
        let variables = {
            api_called_by: 'web',
            user_id: UserInfo?.id || 0,
            role_id: UserInfo?.role_id?.[0] || 0,
            lead_id,
            lead_assigned_to: e?.value,
        };
        executeGraphQLMutation(UPDATE_LEAD_ASSIGNED_TO_ORCR(), variables, client)
            .then((res) => {
                if (res?.data?.update_lead_assigned_to_orcr) {
                    toast.success(res?.data?.update_lead_assigned_to_orcr?.message);
                    getLeadList({
                        getLeadsInput,
                        paginationInput
                    });
                } else {
                    toast.error('Error');
                }
            })
            .catch((err) => {
                toast.error(err.toString().replace('ApolloError:', ''));
            });
    };
    const showIFDocs = (doc_id, lead) => {
        if (Object.keys(lead).length) {
            let lead_docs = lead?.lead_docs?.filter(docs => docs?.doc_id === doc_id) || [];
            setOrcrDocs(lead_docs);
            setShowORCRmodel(true);
        } else {
            setOrcrDocs([]);
            setShowORCRmodel(false);
        }
    }

    const uploadPaymentProof = (lead) => {
        if (Object.keys(lead)?.length) {
            setActiveLead(lead);
            setShowAgentFeeModel(true);
        } else {
            setShowAgentFeeModel(false);
        }
    }

    const uploadVerificationDocs = (lead) => {
        if (Object.keys(lead)?.length) {
            setActiveLead(lead);
            setShowVerificationModel(true)
        }
    }

    const hideVerificationDocsModel = () => {
        setShowVerificationModel(false);
        setActiveLead({})
    }


    return (
        <div>
            <div className="lead-list-data-table">
                <table>
                    <thead>
                        <tr>
                            <th>Application ID</th>
                            <th>Dealer Name</th>
                            <th>Received Date</th>
                            <th>Vehicle Details</th>
                            <th>Plate No</th>
                            <th>Agent Fee</th>
                            <th>ORCR</th>
                            <th>Upload Verification <br /> Docs</th>
                            <th>Status</th>
                            <th>Ops Executive</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leadList?.map((lead) => {
                            let lead_vehicle_detail = `${lead?.lead_detail?.make_name || ''} ${lead?.lead_detail?.model_name || ''
                                } ${lead?.lead_detail?.variant_name || ''} ${lead?.lead_detail?.make_year || ''}`;
                            return (
                                <tr key={lead.id}>
                                    <td>
                                        {' '}

                                        {lead.if_id}{' '}

                                    </td>
                                    <td>
                                        <ul>
                                            <li>{lead?.dealer_name || '-'}</li>
                                            <li>{lead?.gcd_code || '-'}</li>
                                        </ul>
                                    </td>
                                    <td>{moment(new Date(lead?.created_date)).add(8, 'hours').format('D MMM, Y') || '-'}</td>

                                    <td>
                                        <ul>
                                            <li>
                                                {lead_vehicle_detail || '-'}
                                            </li>

                                        </ul>
                                    </td>
                                    <td>{lead?.lead_detail?.plate_number || '-'}</td>
                                    <td>
                                        <ul>
                                            {lead?.lead_detail?.agent_fee && <li>₱ {lead?.lead_detail?.agent_fee}</li>}
                                            {lead?.lead_detail?.agent_fee ?
                                                <li>
                                                    <button onClick={() => { showIFDocs(5, lead) }} className="link-button">View Doc</button>
                                                </li> :
                                                <li>
                                                    <button disabled={CHECK_ASSIGN_USER(lead?.lead_assigned_to)} onClick={() => { uploadPaymentProof(lead) }} className="link-button">Upload Proof</button>
                                                </li>
                                            }
                                        </ul>
                                    </td>

                                    <td>
                                        <ul>
                                            <li>
                                                <button onClick={() => { showIFDocs(3, lead) }} className="link-button">View OR</button>
                                            </li>
                                            <li>
                                                <button onClick={() => { showIFDocs(4, lead) }} className="link-button">View CR</button>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                {lead?.status_id === TITLE_BOX_DIF_STATUS.RECIEVED ?
                                                    <button disabled={CHECK_ASSIGN_USER(lead?.lead_assigned_to)} onClick={() => { uploadVerificationDocs(lead) }} className="link-button">Click Here <br></br> to Upload</button> :
                                                    <button onClick={() => { uploadVerificationDocs(lead) }} className="link-button">View Doc</button>
                                                }
                                            </li>
                                        </ul>
                                    </td>

                                    <td style={{ "color": lead?.status_id === 1 ? "blue" : lead?.status_id === 3 ? "red" : "" }}>{statuses?.filter((st) => +st?.id === +lead?.status_id)?.[0]?.value || '-'}</td>

                                    <td>
                                        <fieldset className="single-select">
                                            <div
                                                className="material"
                                                title={
                                                    opsExecutiveList.filter(({ value }) => value === lead?.lead_assigned_to)?.[0]
                                                        ?.name || '-'
                                                }
                                            ></div>
                                            <Select
                                                className="react-select-table"
                                                classNamePrefix="react-select-table"
                                                placeholder={'Select Assign To'}
                                                options={opsExecutiveList || []}
                                                name={'lead_assigned_to'}
                                                optionLabel={'name'}
                                                optionValue={'id'}
                                                value={opsExecutiveList.filter(({ value }) => value === lead?.lead_assigned_to)}
                                                onChange={(e) => assignToUser(e, lead.id)}
                                                aria-label="Lead assigned to"
                                                isDisabled={lead?.status_id !== TITLE_BOX_DIF_STATUS.RECIEVED}
                                            />
                                        </fieldset>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <p className="align-center-no-record">
                    {leadList?.length < 1 && 'No record found'}
                </p>
            </div>
            {isLoading ? <Loader /> : null}
            <Pagination
                pageCount={totalPage}
                activePage={currentPage || 1}
                onPaginate={onPaginate}
            />

            {showhistorymodal ? (
                <div className="view-timeline-popup image-timeline-popup">
                    <Modal
                        show={showhistorymodal}
                        handleClose={() => {
                            toggleModalBox({});
                        }}
                    >
                        <div className="modal-header">
                            <h2>{'CUSTOMER TIMELINE'}</h2>
                        </div>
                        <LeadHistory historyList={historyList} isLoading={isLoading} t={t} />
                    </Modal>
                </div>
            ) : (
                ''
            )}

            {showORCRmodel ? (
                <div className="view-timeline-popup image-timeline-popup">
                    <Modal
                        show={showORCRmodel}
                        handleClose={() => {
                            toggleModalBoxremark({});
                        }}
                    >
                        <div className="modal-header">
                            {orcrDocs?.filter(docs => docs.doc_id === 5)?.length ?
                                <h2>Agent Fee Docs</h2> : <h2>{orcrDocs?.filter(docs => docs.doc_id === 3)?.length ? "OR " : "CR "} Docs</h2>
                            }
                        </div>
                        <div className="modal-body m-md-t">
                            <DocsPreviewORCR docs={orcrDocs} />
                        </div>
                    </Modal>
                </div >
            ) : (
                ''
            )}
            {
                showAgentFeeModel ? (
                    <div className="view-timeline-popup image-timeline-popup">
                        <Modal
                            show={showAgentFeeModel}
                            handleClose={() => {
                                toggleModalBoxremark({});
                            }}
                        >
                            <div className="modal-header">
                                <h2>Upload Agent Fee</h2>
                            </div>
                            <div className="modal-body m-md-t">
                                <UploadAgentFee lead={activeLead} toggleModalBoxremark={toggleModalBoxremark} getLeadList={getLeadList} user_id={UserInfo?.id} getLeadsInput={getLeadsInput} paginationInput={paginationInput} />
                            </div>
                        </Modal>
                    </div>
                ) : (
                    ''
                )
            }

            {
                showVerificationModel ? (
                    <div className="view-timeline-popup image-timeline-popup">
                        <Modal show={true} handleClose={() => hideVerificationDocsModel()}>
                            <div className="modal-header">
                                <h2>Upload Verification Proof</h2>
                            </div>
                            <div className="modal-body m-md-t h-300">
                                <LeadVerification lead={activeLead} hideVerificationDocsModel={hideVerificationDocsModel} getLeadList={getLeadList} user_id={UserInfo?.id} getLeadsInput={getLeadsInput} paginationInput={paginationInput} />
                            </div>
                        </Modal>
                    </div>
                ) : ''
            }


        </div >
    );
};
export default LeadListTable;
