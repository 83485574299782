import ReactPaginate from 'react-paginate';
const Pagination = ({ perPage = 10, onPaginate, pageCount, activePage }) => {
	const handlePageClick = (spage) => { onPaginate(spage.selected + 1); };
	return (
		<div className='pagination-bx'>
			{pageCount > 0 &&
				<ReactPaginate
					breakLabel="..."
					nextLabel=">"
					onPageChange={handlePageClick}
					pageRangeDisplayed={5}
					pageCount={pageCount}
					forcePage={activePage ? activePage - 1 : 0}
					previousLabel="<"
					breakClassName="page-item"
					breakLinkClassName="page-link"
					containerClassName="pagination"
					activeClassName="active"
					renderOnZeroPageCount={null}
				/>
			}
			{pageCount === 0 &&
				<p>No pages available</p>
			}
		</div>
	);
};
export default Pagination;