import { gql } from '@apollo/client';

export const GET_BANKS = gql`
    query Get_banks {
        get_banks(api_called_by: web) {
            id
            name
            status
        }
    }`;
