import React, { useState } from 'react';

function ImageWithAlt({ src, alt }) {
    const [isError, setIsError] = useState(false);

    const handleError = () => {
        setIsError(true);
    };

    return (
        <img
            src={src}
            alt={alt}
            onError={handleError}
            style={{ display: isError ? 'none' : 'block' }}
        />
    );
}

export default ImageWithAlt;
