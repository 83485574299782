import { useApolloClient } from "@apollo/client";
import MultiSelect from "../elements/MultiSelect";
import SelectLtoOffices from "./SelectLtoOffices";
import { useSelector } from "react-redux";
import { executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import { SAVE_AGENT_SERVICE } from "../../services/agentonboading.gql";
import { toast } from "react-toastify";
import { useState } from "react";
import Loader from "../elements/Loader";

const SelectRegionAndService = ({
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    agentDetails,
    ALL_OPTIONS,
    handleRegionChange,
    handleLtoChange,
    handleServicePriceChange,
    getAgentList,
    hideModalAgentOnboard,
    getAgentsInput,
    paginationInput
}) => {

    const [isLoading, setIsLoading] = useState(false);
    const client = useApolloClient();
    const { user_information } = useSelector(({ masterdata, user }) => {
        return {
            user_information: user.user_information
        };
    });
    const UserInfo = JSON.parse(user_information);
    const saveServices = () => {
        let agentDetailsReplica = agentDetails;
        const variables = {
            save_agent_service_input: {
                agent_id: agentDetailsReplica?.agent_id,
                lto_office_agent: []
            },
            api_called_by: "web",
            user_id: UserInfo?.id,
            role_id: UserInfo?.role_id?.[0] || 0
        };
        agentDetailsReplica?.region_id?.forEach(region => {
            Object.keys(agentDetailsReplica?.region_details?.[region]?.lt_office_details || {})?.forEach(lt_office_id => {
                delete agentDetailsReplica?.region_details?.[region]?.lt_office_details?.[lt_office_id]?.label;
                const lto_agent_service = Object.keys(agentDetailsReplica?.region_details?.[region]?.lt_office_details?.[lt_office_id]).reduce((sub_result, service_id) => {
                    sub_result.push({ service_id: +service_id, agent_fee: agentDetailsReplica?.region_details?.[region]?.lt_office_details?.[lt_office_id][service_id] });
                    return sub_result;
                }, []);
                variables.save_agent_service_input.lto_office_agent.push({ lt_office_id: +lt_office_id || 0, region_id: region, lto_agent_service });
            });
        });

        setIsLoading(true);
        executeGraphQLMutation(SAVE_AGENT_SERVICE, variables, client)
            .then((resp) => {
                if (resp?.data?.save_agent_service) {
                    toast.success(resp?.data?.save_agent_service?.message);
                    getAgentList({ getAgentsInput, paginationInput });
                    hideModalAgentOnboard();
                }
            })
            .catch((err) => {
                toast.error(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return <>
        <Accordion allowZeroExpanded>
            {agentDetails?.region_id?.map(region => (
                <AccordionItem uuid={agentDetails?.region_id[region]?.label}>
                    <AccordionItemHeading >
                        <AccordionItemButton><h2>
                            {agentDetails?.region_details[region]?.label}
                        </h2></AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="region-panel-bg">
                            <SelectLtoOffices ALL_OPTIONS={ALL_OPTIONS} region={region} agentDetails={agentDetails} handleLtoChange={handleLtoChange} options={ALL_OPTIONS["region_lto_office_type"][region]} ltoIds={agentDetails?.region_details?.[region]?.lt_office_id} handleServicePriceChange={handleServicePriceChange} />
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
            ))}

        </Accordion>
        <fieldset class="multiselect-dropDown">
            <div class="material">
                <MultiSelect
                    options={ALL_OPTIONS["region_lto_office"] || []}
                    placeholder={'Add Region'}
                    name={'region_id'}
                    optionLabel="label"
                    optionValue="id"
                    values={agentDetails?.region_id || []}
                    onChange={handleRegionChange}
                />
            </div>
        </fieldset>
        <div className="btn-save-remarks p-lg-t">
            {!agentDetails?.agent_id ? (
                <span className="span-disabled">Submit</span>
            ) : (
                <button aria-label="save naxt" type="submit" onClick={saveServices} className="btn-primary">
                    Submit
                </button>
            )}
        </div>
        {isLoading ? <Loader /> : null}
    </>;
};
export default SelectRegionAndService;