import { TITLE_TRANSFER_REQUEST } from '../formFields/titleTransferRequestFormFields.json';
import { useParams } from 'react-router-dom';
import { decode } from '../../../config/crypto';
import { useEffect } from 'react';
import { useState } from 'react';
import FormsyInputField from '../../elements/FormsyInputField';
import Formsy from 'formsy-react';
import { GET_AGENT_LEAD_DETAIL, UPDATE_AGENT_ACTION } from '../../../services/agent.ggl';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { AGENT_ACTION, getDocExtension } from '../../../config/constants';
import Modal from '../../elements/Modal';
import Select from 'react-select';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import logo_image from '../../../webroot/images/carmudi-logo.png';

const TitleTransferRequest = (props) => {
    const [editServicesPrice, setEditServicesPrice] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [showSuccessPage, setShowSuccessPage] = useState('');
    const [isAgentValidated, setIsAgentValidated] = useState(false);
    const [validateAgentInput, setValidateAgentInput] = useState({});
    const [titleTransferDetails, setTitleTransferDetails] = useState({});
    const [leadDocs, setLeadDocs] = useState([]);
    const [leadDocsName, setLeadDocsName] = useState([]);
    const [selectedDocId, setSelectedDocId] = useState();
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [docIndex, setDocIndex] = useState(0);
    const client = useApolloClient();
    let { lead_id, agent_id } = useParams();
    useEffect(() => {
        setValidateAgentInput((prev) => ({
            ...prev,
            lead_id: +decode(lead_id),
            agent_id: +decode(agent_id),
        }));
    }, [lead_id, agent_id]);

    useEffect(() => {
        const { leadDocDropDown } = leadDocs.reduce(
            (result, doc) => {
                if (!result?.alreadyPushed.includes(doc?.doc_detail?.id)) {
                    const label = doc?.doc_detail?.parent_id && `${doc?.doc_detail?.doc_detail?.name} (${doc?.doc_detail?.name})` || doc?.doc_detail?.name;
                    result?.leadDocDropDown?.push({ value: doc?.doc_detail?.id, label });
                    result?.alreadyPushed?.push(doc?.doc_detail?.id);
                }
                return result;
            },
            { leadDocDropDown: [], alreadyPushed: [] },
        );
        setLeadDocsName([...leadDocDropDown]);
    }, [leadDocs]);

    useEffect(() => {
        setDocIndex(0);
        const docs = leadDocs.filter((doc) => doc.doc_id == selectedDocId);
        setSelectedDocs([...docs]);
    }, [selectedDocId]);

    const setDocIndexValue = (index) => {
        const newIndex = (index + 1) % selectedDocs.length;
        setDocIndex(newIndex);
    };

    const handleValidateSubmit = () => {
        let agent_lead_detail_input = {
            lead_id: validateAgentInput?.lead_id,
            agent_id: validateAgentInput?.agent_id,
            agent_mobile: +validateAgentInput?.agent_mobile?.split(' ')[1],
        },
            variables = {
                api_called_by: 'web',
                agent_lead_detail_input,
            };
        executeGraphQLMutation(GET_AGENT_LEAD_DETAIL, variables, client)
            .then((resp) => {
                if (resp?.data?.get_agent_lead_detail) {
                    const lead = resp?.data?.get_agent_lead_detail;
                    const obj = {};
                    console.log('lead', lead);
                    if (lead?.customer?.name) {
                        obj['customer_name'] = lead?.customer?.name || '';
                    }
                    obj['dealer_name'] = 'dealer_name';

                    if (lead?.service_type?.service_type) {
                        obj['service_type'] = lead?.service_type?.service_type || '';
                    }
                    if (lead?.lead_docs) {
                        setLeadDocs(lead?.lead_docs);
                        obj['lead_docs'] = `<a>View Docs</a>`;
                    }
                    if (lead?.lead_detail?.agent_fee) {
                        obj['agent_fee'] = lead?.lead_detail?.agent_fee || 0;
                    }

                    if (lead?.lead_vehicle_detail) {
                        const {
                            make_id,
                            make_name,
                            model_id,
                            model_name,
                            other_make_name,
                            other_model_name,
                            other_variant_name,
                            variant_id,
                            variant_name,
                        } = lead?.lead_vehicle_detail;
                        obj['mmv_name'] = `${(make_id && make_name) || other_make_name} ${(model_id && model_name) || other_model_name
                            } ${(variant_id && variant_name) || other_variant_name}`;
                    }
                    setTitleTransferDetails(obj);
                    setIsAgentValidated(true);
                    toast.success('Agent Validated!');
                } else {
                    toast.error('Something went wrong');
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const handleSubmit = (action) => {
        let agent_action_input = {
            lead_id: validateAgentInput?.lead_id,
            agent_id: validateAgentInput?.agent_id,
            action: action,
        },
            variables = {
                api_called_by: 'web',
                agent_action_input,
            };
        executeGraphQLMutation(UPDATE_AGENT_ACTION, variables, client)
            .then((resp) => {
                if (resp?.data?.update_agent_action) {
                    setShowSuccessPage(action);
                    toast.success(resp?.data?.update_agent_action?.message);
                } else {
                    toast.error('Something went wrong');
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const handleValidateChange = (data, field_name) => {
        const newValues = {};
        console.log('data && field_name', data, field_name);
        if (data && field_name) {
            newValues[field_name] = data.value;
        }
        if (data?.target?.id) {
            newValues[data?.target?.id] = data?.target?.value;
        }
        setValidateAgentInput((prev) => ({
            ...prev,
            ...newValues,
        }));
    };

    const FORM_FIELDS = [
        {
            id: 'agent_mobile',
            name: 'agent_mobile',
            readOnly: false,
            type: 'pattern-format',
            label: 'Agent mobile no.',
            required: true,
            validations: 'isValidPhoneNumber',
            validationError: '',
            ApplyNumberformat: true,
            format: '+63 ############',
            showAsterisk: true,
        },
    ];

    const showModalEditServicesPrice = () => {
        setEditServicesPrice(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalEditServicesPrice = () => {
        setEditServicesPrice(false);
        document.body.classList.remove('overflow-hidden');
    };

    const LabelValueDisplay = ({ label, value }) => {
        return (
            <div className="label-value-container">
                <span className="label">{label}</span>
                <span className="value">{value}</span>
            </div>
        );
    };

    const ViewDocsDisplay = ({ label, value }) => {
        return (
            <div className="label-value-container">
                <span className="label">{label}</span>
                <a className="value" onClick={showModalEditServicesPrice}>
                    View Docs
                </a>
            </div>
        );
    };

    return (
        <div className="title-transfer-login-outer">
            <div className="form-container t-center">
                <div className='acceptance_page_logo m-sm-b'>
                    <img src={logo_image}></img>
                </div>
                <h2>Title Transfer Request</h2>
                {!isAgentValidated ? (
                    <Formsy
                        className="lead-form-filed"
                        autoComplete="off"
                        onValid={() => setAllowSubmit(true)}
                        onInvalid={() => setAllowSubmit(false)}
                        onValidSubmit={handleValidateSubmit}
                    >
                        <div className="row">
                            {FORM_FIELDS.map((field) => (
                                <fieldset
                                    class={`form-filed p-lg-t ${(field?.is_full_row && 'col-md-12') || 'col-md-12'}`}
                                >
                                    <FormsyInputField
                                        id={field.id}
                                        name={field.name}
                                        type={field.type}
                                        value={validateAgentInput && validateAgentInput[field.name]}
                                        placeholder=" "
                                        label={field.label}
                                        onChange={(data) => handleValidateChange(data, field.name)}
                                        format={(field.ApplyNumberformat && field.format) || ''}
                                        validations={field.validations}
                                        validationError={validateAgentInput[field.name] ? field.validationError : ''}
                                        required={field.required}
                                        showAsterisk={field.showAsterisk}
                                    />
                                </fieldset>
                            ))}
                        </div>
                        <div className="btn-save-remarks p-lg-t">
                            {!allowSubmit ? (
                                <span className="span-disabled">Validate</span>
                            ) : (
                                <button aria-label="save naxt" type="submit" className="btn-primary">
                                    Validate
                                </button>
                            )}
                        </div>
                    </Formsy>
                ) : showSuccessPage !== "" ? (<>
                    <div className={showSuccessPage === AGENT_ACTION.rejected && "Rejected" ? "status-inactive" : "status-active"}>
                        <span>{showSuccessPage === AGENT_ACTION.rejected && "Rejected" || showSuccessPage === AGENT_ACTION.accepted && "Accepted"}</span>
                    </div>
                </>) : (
                    <>
                        {TITLE_TRANSFER_REQUEST.map((field) => (
                            <div className="app-container p-md-t">
                                {(field?.id === 'lead_docs' && (
                                    <ViewDocsDisplay
                                        label={field?.label}
                                        value={titleTransferDetails[field?.value] || []}
                                    />
                                )) || (
                                        <LabelValueDisplay
                                            label={field?.label}
                                            value={titleTransferDetails[field?.value] || ''}
                                        />
                                    )}
                            </div>
                        ))}
                        <div className="btn-save-remarks p-lg-t btn-accept-reject">
                            <button
                                aria-label="save naxt"
                                onClick={() => handleSubmit(AGENT_ACTION.rejected)}
                                className="btn-line m-sm-r"
                            >
                                Reject
                            </button>
                            <button
                                aria-label="save naxt"
                                onClick={() => handleSubmit(AGENT_ACTION.accepted)}
                                className="btn-primary"
                            >
                                Accept
                            </button>
                        </div>
                    </>
                )}
            </div>
            <div className="Add-agnet-popup doc-view-gallery-popup">
                <Modal show={editServicesPrice} handleClose={hideModalEditServicesPrice}>
                    <div className='modal-header p-lg-b'>
                        <h2>Doc list</h2>
                    </div>
                    <div className='modal-body'>
                        <div className="tag-image-bx row" key={`${leadDocs?.length}`}>
                            <div className='single-select col-md-12 p-lg-b'>
                                <div className="material">
                                    <Select
                                        options={leadDocsName}
                                        placeholder={'Select Doc'}
                                        name={'selected_doc'}
                                        optionLabel="label"
                                        optionValue="value"
                                        value={leadDocsName.filter((data) => data.value === selectedDocId)}
                                        onChange={(e) => {
                                            setSelectedDocId(e.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="carousel image-varification-slider">
                            <div className="carousel-slideimg pfd-img-height">
                                {['pdf', 'PDF'].includes(getDocExtension(selectedDocs[docIndex]?.doc_path)) ? (
                                    <a
                                        className="pdf-img"
                                        href={selectedDocs[docIndex]?.doc_path}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="ic-pdf-file pdf-icons-normal-gallery"></i>
                                    </a>
                                ) : (
                                    <>
                                        <ReactPanZoom image={selectedDocs[docIndex]?.doc_path} />
                                        {selectedDocs.length > 1 && (
                                            <button onClick={() => setDocIndexValue(docIndex)}>Next</button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};
export default TitleTransferRequest;
