import { gql } from '@apollo/client';

export const GET_CUSTOMER_LEAD_DETAIL = gql`
    query Get_customer_lead_detail($api_called_by: API_CALL_BY!, $customer_lead_detail_input: CustomerLeadDetail!) {
        get_customer_lead_detail(customer_lead_detail_input: $customer_lead_detail_input, api_called_by: $api_called_by) {
            id
            status_id
            sub_status_id
            customer {
                name
            }
            lead_detail {
                agent_fee
            }
            lead_vehicle_detail {
                make_id
                model_id
                variant_id
                make_name
                model_name
                variant_name
                other_make_name
                other_model_name
                other_variant_name
            }
            service_type {
                service_type
                standard_price
            }
            lead_docs {
                doc_path
                doc_id
                doc_detail {
                    id
                    name
                    parent_id
                    doc_detail {
                        id
                        name
                    }
                }
            }
            lead_status {
                id
                name
            }
            lead_rating {
                id
                rating
                out_of
                remark
            }
        }
    }
`;
export const MARK_RATING = gql`
    mutation Mark_rating($rate_input: RateLeadExperience!) {
        mark_rating(
            rate_input: $rate_input
        ) {
            message
    }
    }
`;
