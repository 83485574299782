import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useOutletContext, useParams } from 'react-router-dom';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getLoanDetail } from '../../../store/action/allAction';
import {
    CHECK_ASSIGN_USER,
    DOC_IDS,
    IS_USER_ACCESS_PAGE,
    LEAD_STATUS,
    LEAD_SUB_STATUS,
    REVIEW_PAGES,
    UPDATE_LEAD_ORCR_ACTION,
} from '../../../config/constants';
import { UPDATE_LEAD_ORCR } from '../../../services/leads.gql';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import DocumentGallery from '../DocumentGallery';
import ImageWithAlt from '../../../common/ImageWithAlt';
import PreviewRating from "../PreviewRating";

const OrcrSendToDealer = (props) => {
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isFieldsPresent, setIsFieldsPresent] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [orcrSendToDealerInput, setOrcrSendToDealerInput] = useState({});
    const [showremarkform, setShowremarkform] = useState(false);

    const { lead, remarksHistory, lead_status_history, lead_docs, lead_rating } = useSelector(({ masterdata, lead }) => ({
        masterdata,
        lead,
        remarksHistory: lead.remarksHistory || [],
        lead_docs: lead?.leadDetail?.lead_docs || [],
        lead_status_history: lead?.leadDetail?.lead_status_history || [],
        lead_rating: lead?.leadDetail?.lead_rating || null
    }));

    useEffect(() => {
        const doc = lead_docs.filter((doc) => doc.doc_id === DOC_IDS.NEW_ORCR)[0] || {};
        doc.ext = doc?.doc_path?.split('?')[0].split('.').pop() || '';
        setOrcrSendToDealerInput({ ...doc });
    }, [lead_docs]);

    const client = useApolloClient();
    const dispatch = useDispatch();
    let accessCondition = !IS_USER_ACCESS_PAGE('loanDetail', 'orcr-send-to-dealer')?.is_edit_access;

    if (!accessCondition) {
        accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.lead_assigned_to);
    }

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = UserInfo?.id || 0;
    let role_id = UserInfo?.role_id?.[0] || 0;

    let isLeadAssigned = IS_USER_ACCESS_PAGE('loanDetail', 'orcr-send-to-dealer').is_super_admin
        ? false
        : !(lead?.leadDetail?.lead_assigned_to && lead?.leadDetail?.lead_assigned_to === user_id);
    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || [];

    useEffect(() => {
        lead?.leadDetail?.lead_detail?.orcr_transfer_completed_date &&
            setOrcrSendToDealerInput((prev) => ({
                ...prev,
                orcr_transfer_completed_date: lead?.leadDetail?.lead_detail?.orcr_transfer_completed_date,
            }));
    }, [lead?.leadDetail]);

    const handleChange = (data, field_name) => {
        const newValues = {};

        if (field_name === 'orcr_transfer_completed_date' && data) {
            newValues[field_name] = data;
        }
        setOrcrSendToDealerInput((currentValue) => ({
            ...currentValue,
            ...newValues,
        }));
    };

    const handleSubmit = () => {
        let update_lead_orcr = {
            lead_id: lead?.leadDetail?.id,
            action: UPDATE_LEAD_ORCR_ACTION.ORCR_SENT_TO_DEALER,
            orcr_sent_to_dealer: {
                orcr_transfer_completed_date: orcrSendToDealerInput?.orcr_transfer_completed_date,
            },
        },
            variables = { user_id, role_id, api_called_by: 'web', update_lead_orcr };

        executeGraphQLMutation(UPDATE_LEAD_ORCR, variables, client)
            .then(async (resp) => {
                if (resp?.data?.update_lead_orcr) {
                    toast.success(resp?.data?.update_lead_orcr?.message);
                } else {
                    toast.error('Something went wrong');
                }
                dispatch(getLoanDetail(lead?.leadDetail?.id, client));
                await getContext.getLeadHistory(lead?.leadDetail?.id);
                navigateToNext();
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const navigateToNext = () => { };

    const isSubmitDisabled = useMemo(() => {
        return lead_status_history?.filter(
            (v) =>
                [LEAD_STATUS.ORCR_TRANSFER_COMPLETED].includes(v.status_id) &&
                [LEAD_SUB_STATUS.ORCR_SENT_TO_DEALER].includes(v.sub_status_id),
        )?.length
            ? true
            : false;
    }, [lead_status_history]);

    const showModalViewTimeLine = () => {
        setShowremarkform(true);
        setViewTimeLine(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove('overflow-hidden');
    };

    useEffect(() => {
        const fields = (orcrSendToDealerInput?.orcr_transfer_completed_date && true) || false;
        setIsFieldsPresent(fields);
    }, [orcrSendToDealerInput]);

    const sectionInfo = { section: 'New ORCR Sent to Dealer Date', sub_section: '' };
    return (
        <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
            <div className="image-tab-panel">
                <DocumentGallery documentCategoryList={documentCategoryList} />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading">
                        <h2>New ORCR Sent to Dealer</h2>
                    </div>
                </div>
                <Formsy
                    className="lead-form-filed"
                    autoComplete="off"
                    onValid={() => setAllowSubmit(true)}
                    onInvalid={() => setAllowSubmit(false)}
                    onValidSubmit={handleSubmit}
                    aria-label="lead form field basic"
                >
                    <div className="carousel image-varification-slider">
                        <div className="carousel-slideimg pfd-img-height">
                            {(['pdf', 'PDF'].includes(orcrSendToDealerInput?.ext) ? (
                                <a
                                    className="pdf-img"
                                    href={orcrSendToDealerInput?.doc_path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="ic-pdf-file pdf-icons-normal-gallery"></i>
                                </a>
                            ) : (
                                <ImageWithAlt src={orcrSendToDealerInput?.doc_path}
                                    alt={orcrSendToDealerInput?.docname} />
                            ))
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <fieldset class="form-filed col-md-12 p-lg-t">
                            <div className="material">
                                <div className="datepicker">
                                    <FormsyDatePicker
                                        selectedDate={
                                            (orcrSendToDealerInput['orcr_transfer_completed_date'] &&
                                                new Date(orcrSendToDealerInput['orcr_transfer_completed_date'])) ||
                                            ''
                                        }
                                        onDateSelect={(data) => handleChange(data, 'orcr_transfer_completed_date')}
                                        placeholder={'Sent to Dealer Date'}
                                        name={'orcr_transfer_completed_date'}
                                        value={
                                            orcrSendToDealerInput &&
                                            new Date(orcrSendToDealerInput['orcr_transfer_completed_date'])
                                        }
                                        minDate={new Date().setMonth(new Date().getMonth() - 2)}
                                        maxDate={new Date().setMonth(new Date().getMonth() + 2)}
                                        dateFormat="dd MMM, yyyy"
                                        validations={null}
                                        validationError={'Please select date'}
                                        required={true}
                                        clearDate={true}
                                        readOnly={isReadOnly || isSubmitDisabled}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div className="btn-save-remarks">
                        {isSubmitDisabled ||
                            !allowSubmit ||
                            getContext.markAsFreezed ||
                            accessCondition ||
                            isLeadAssigned ||
                            !isFieldsPresent ? (
                            <span className="span-disabled">Mark as Completed</span>
                        ) : (
                            <button aria-label="save naxt" type="submit" className="btn-primary">
                                Mark as Completed
                            </button>
                        )}
                        <button
                            aria-label="remarks history"
                            type="button"
                            className="btn-line"
                            onClick={showModalViewTimeLine}
                        >
                            Remarks
                            <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                        </button>
                    </div>
                </Formsy>
                {
                    lead_rating &&
                    <PreviewRating lead_rating={lead_rating} page={REVIEW_PAGES.ORCR_SEND_TO_DEALER} /> || null
                }
            </div>
            <div className="view-timeline-popup">
                <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                    <div className="modal-header">
                        <h2>Remark History</h2>
                    </div>
                    <LeadRemarkHistory
                        showremarkform={showremarkform}
                        markAsFreezed={getContext.markAsFreezed}
                        accessCondition={accessCondition || isLeadAssigned}
                        sectionInfo={sectionInfo}
                    />
                </Modal>
            </div>
        </div>
    );
};
export default OrcrSendToDealer;
