import { gql } from '@apollo/client';

export const GET_SERVICES_PRICE = gql`
    query Get_services_price {
        get_services_price {
            id
            label
            value
            standard_price
        }
    }
`;

export const UPDATE_SERVICES_PRICE = gql`
    mutation Update_services_price(
        $api_called_by: API_CALL_BY!
        $services_price_input: [UpdateServicesPriceDto!]!
        $user_id: Float!
        $role_id: Float!
    ) {
        update_services_price(
            services_price_input: $services_price_input
            api_called_by: $api_called_by
            role_id: $role_id
            user_id: $user_id
        ) {
            message
        }
    }
`;
