import React, { useEffect, useState } from 'react';
import LeadListTable from './LeadLIstTable';
import { Tab, Nav, NavLink } from 'react-bootstrap';
import LeadListFilter from './LeadListTopFilter';
import { useApolloClient } from '@apollo/client';
import { LEADS_QUERY } from '../../services/leads.gql';
import { executeGraphQLQuery, executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import crypto from '../../config/crypto';
import { getAgentList, getDealerList, getMasterData, getStatusList, opsExecutiveList } from '../../store/action/allAction';
import { SAVE_CSV_DOWNLOAD_REQ } from '../../services/leads.gql';
import { GET_DEALER_LIST, GET_ROLES_WITH_USERS } from '../../services/config.gql';
import { useNavigate, useParams } from 'react-router-dom';
import { ACTION_TYPE_TAB_MAPPING, LEADS_ACTION_TYPE, ORDERBY_LEAD_FIELDS, getKeysDataIfExist } from '../../config/constants';
import Modal from '../elements/Modal';
import EditServicesPrice from './EditServicesPrice';
import * as moment from 'moment';
import { IS_USER_ACCESS_PAGE } from '../../../src/config/constants';

const LeadList = (props) => {
    const { status, searchtype, searchtext } = useParams();
    let paramsObject = {};
    if (!status && !searchtype && !searchtext) {
        paramsObject = { action_type: LEADS_ACTION_TYPE.all_cases };
    }
    else if (status == 'search_text') {
        paramsObject = { search_text: `${searchtext}`, search_type: searchtype };
    }
    else {
        let params = crypto.decode(status);
        paramsObject = JSON.parse(params) || {};
    }

    const { t } = useTranslation('language');
    const client = useApolloClient();
    const [editServicesPrice, setEditServicesPrice] = useState(false);
    const [leads, setLeads] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [financers, setFinancers] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [subStatusList, setSubStatusList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(ACTION_TYPE_TAB_MAPPING[paramsObject?.action_type] || 'tb_all_cases_');
    let [paginationInput, setPaginationInput] = useState({ page: 1 });
    let [getLeadsInput, setGetLeadsInput] = useState({ action_type: "all_cases", ...paramsObject, });
    let [orderByInput, setOrderByInput] = useState({ key: 'id', sort_order: 'DESC' });
    const [filtersArr, setFiltersArr] = useState({});
    const [dealershipName, setDealershipName] = useState([]);
    const [rolesWithUsers, setRolesWithUsers] = useState([]);
    const [refreshFilterChips, setRefreshFilterChips] = useState(false);

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    const [orderByStr, setOrderByStr] = useState(ORDERBY_LEAD_FIELDS?.[1]?.value);
    let loginData = JSON.parse(localStorage.getItem('user_information'));
    let isSuperAdmin = loginData && (loginData.role_id.includes(1) || loginData.role_id.includes(2));
    const navigate = useNavigate();
    let accessCondition = IS_USER_ACCESS_PAGE('lead_export_data', 'lead_export_data')?.is_edit_access;

    useEffect(() => {
        get_master_data();
        get_status_list();
        get_agent_list();
        ops_executive_list();
        getRolesWithUsers();
        get_dealer_list();
        getLeadList({ getLeadsInput, paginationInput, orderByInput });
    }, []);

    const getRolesWithUsers = () => {
        executeGraphQLQuery(GET_ROLES_WITH_USERS, client)
            .then((result) => {
                const rolesWithUsers = result?.data?.get_roles_with_users.map((roles) => {
                    roles.users = roles.users.map((users) => {
                        return { value: users.id, label: users.name };
                    });
                    return { value: roles.role_id, label: roles.title, users: roles.users };
                });
                setRolesWithUsers(rolesWithUsers);
            })
            .catch((errors) => {
                toast.error('Somethig went wrong ,please try again');
            });
    };

    const dispatch = useDispatch();
    const get_dealer_list = () => {
        dispatch(getDealerList(client));
    };
    const get_master_data = () => {
        dispatch(getMasterData(client));
    };
    const get_status_list = () => {
        dispatch(getStatusList(client));
    };
    const get_agent_list = () => {
        dispatch(getAgentList(client));
    };

    const ops_executive_list = () => {
        dispatch(opsExecutiveList(client));
    };

    const getLeadList = ({ getLeadsInput, paginationInput, orderByInput }) => {
        setRefreshFilterChips(!refreshFilterChips);
        const filterData = {
            getLeadsInput,
            paginationInput,
            orderByInput,
            api_called_by: 'web',
            user_id: UserInfo?.id || 0,
            role_id: UserInfo?.role_id?.[0] || 0,
        };
        setIsLoading(true);
        executeGraphQLQuery(LEADS_QUERY(), client, filterData)
            .then((result) => {
                setLeads(result?.data?.get_leads?.rows);
                setTabs(result?.data?.get_leads?.tabs);
                setTotalPage(result?.data?.get_leads?.pagination?.total_page || 0);
                setCurrentPage(result?.data?.get_leads?.pagination?.page_no || 1);
                setIsLoading(false);
            })
            .catch((errors) => {
                toast.error('Somethig went wrong ,please try again');
            });
    };
    const onPaginate = (p) => {
        paginationInput = { page: p };
        setPaginationInput({ ...paginationInput });
        getLeadList({ getLeadsInput, paginationInput, orderByInput });
    };

    const onTabChange = (tab, event) => {
        /** Adding sorting along with tab change */
        let tabFilters = tabs.filter((el) => el?.type == tab)?.[0]?.filter_params || {};
        delete tabFilters?.__typename;
        if (event.type === 'click') {
            setActiveTab(tab);
            getLeadsInput = {
                ...getLeadsInput, ...getKeysDataIfExist(tabFilters, ["action_type"])
            };
            setGetLeadsInput({ ...getLeadsInput });
            paginationInput = { page: 1 };
            setPaginationInput({ ...paginationInput });
            getLeadList({ getLeadsInput, paginationInput, orderByInput });
        }
    };

    const handleSearch = (filters) => {
        getLeadsInput = { ...filters };
        setGetLeadsInput({ ...getLeadsInput });
        paginationInput = { page: 1 };
        setPaginationInput({ ...paginationInput });
        getLeadList({ getLeadsInput, paginationInput, orderByInput });
        document.getElementById('more-filter-dropdown').classList.remove('show');
    };

    const orderByHandle = (odr) => {
        setOrderByStr(odr.value);
        let orderArr = odr.value.split('__');
        orderByInput = { key: orderArr[0], sort_order: orderArr[1] };
        setOrderByInput({ ...orderByInput });
        getLeadList({ getLeadsInput, paginationInput, orderByInput });
    };

    const showModalEditServicesPrice = () => {
        setEditServicesPrice(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalEditServicesPrice = () => {
        setEditServicesPrice(false);
        document.body.classList.remove('overflow-hidden');
    };

    const saveDownloadCsvReq = () => {
        if (!getLeadsInput.date_type || !getLeadsInput.from_date || !getLeadsInput.to_date) {
            toast.error('Please select date range');
            return;
        }
        const numberofdays = moment(getLeadsInput.to_date).diff(moment(getLeadsInput.from_date), 'days');
        if (numberofdays > 90) {
            toast.error('You can export the data for 90 days only');
            return;
        }
        const filterData = {
            applied_filters: JSON.stringify({ getLeadsInput, orderByInput }),
            user_id: UserInfo?.id,
            user_email: UserInfo?.email,
        };
        executeGraphQLMutation(SAVE_CSV_DOWNLOAD_REQ, filterData, client)
            .then((res) => {
                if (res?.data?.save_csv_download_req?.message) {
                    toast.success(res?.data?.save_csv_download_req?.message);
                } else {
                    toast.error('Something went wrong!');
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    };

    const redirectAddApplication = () => {
        navigate(`/add-application`);
    };

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="top-heading financer-lead-heding">
                    <h1>Title Transfer</h1>
                    <div className="export-edit-btn">
                        <button className="btn-line m-sm-r" onClick={redirectAddApplication}>
                            <i className='ic-add'></i>
                            Add Title Transfer Application
                        </button>

                        <button disabled={!accessCondition} className="btn-line m-sm-r" onClick={saveDownloadCsvReq}>
                            <i className='ic-excel-file'></i>
                            Export Data
                        </button>

                        <button className="btn-line" onClick={showModalEditServicesPrice}>
                            <i className='ic-edit'></i>
                            Edit Services Price
                        </button>
                    </div>
                </div>
                <div className='loan-filter-bg'>
                    <div className="loan-filter-bg">
                        <div className="top-filter-bg">
                            <LeadListFilter
                                paramsObject={paramsObject}
                                financers={financers}
                                statuses={statuses}
                                subStatusList={subStatusList}
                                handleSearch={handleSearch}
                                getLeadsInput={getLeadsInput}
                                setGetLeadsInput={setGetLeadsInput}
                                dealershipName={dealershipName}
                                rolesWithUsers={rolesWithUsers}
                                filtersArr={filtersArr}
                                setFiltersArr={setFiltersArr}
                                refreshFilterChips={refreshFilterChips}
                            />
                        </div>
                    </div>
                    <div className="lead-list-tabs tab-list-bg">
                        <Tab.Container
                            id="left-tabs-example"
                            activeKey={activeTab}
                            onSelect={(tab, e) => onTabChange(tab, e)}
                        >
                            <Nav variant="pills" className="flex-column nav nav-pills tab-line">
                                <div className="tab-list">
                                    {(tabs?.length &&
                                        tabs.map((tab, index) => (
                                            <Nav.Item>
                                                <Nav.Link
                                                    aria-label={tab?.label}
                                                    disabled={tab?.count == 0}
                                                    eventKey={tab?.type}
                                                >
                                                    {tab?.label} ( {tab?.count} )
                                                </Nav.Link>
                                            </Nav.Item>
                                        ))) ||
                                        ''}
                                </div>
                            </Nav>
                        </Tab.Container>
                        <div id="lead-count-cls">
                            <div className="sorting-filter-cls">
                                <fieldset className="single-select">
                                    <div className="material">
                                        <Select
                                            options={ORDERBY_LEAD_FIELDS}
                                            onChange={orderByHandle}
                                            name="orderby"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            placeholder={'Order By'}
                                            value={ORDERBY_LEAD_FIELDS.filter(({ value }) => value === orderByStr) || ''}
                                            optionLabel="label"
                                            optionValue="value"
                                            aria-label="order by"
                                        />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>

                <LeadListTable
                    leads={leads}
                    t={t}
                    onPaginate={onPaginate}
                    totalPage={totalPage}
                    currentPage={currentPage}
                    isLoading={isLoading}
                    getLeadList={getLeadList}
                    getLeadsInput={getLeadsInput}
                    paginationInput={paginationInput}
                    orderByInput={orderByInput}
                />
            </div>
            <div className="Add-agnet-popup ">
                <Modal show={editServicesPrice} handleClose={hideModalEditServicesPrice}>
                    <div className="modal-header">
                        <h2>Edit Title Transfer Service Price</h2>
                    </div>
                    <EditServicesPrice hideModalEditServicesPrice={hideModalEditServicesPrice} />
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default LeadList;
